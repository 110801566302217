import { Theme, useTheme } from "@mui/material";
import { SecuritySlider } from "modules/security-slider";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { Asset } from "pages/assets/types";
import { ProgressiveSliderLabel } from "pages/ports/components/progressive-enforcement-slider/ProgressiveSliderLabel";
import {
	ProgressiveEnforcementLevel,
	ProgressiveEnforcementLevelLabels,
	ProgressiveEnforcementStatus,
	ProgressiveEnforcementStatusMap,
	ProgressiveInboundStatusSliderMarks,
	ProgressiveOutboundPortEnforcementLevel,
	ProgressiveOutboundPortEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatusMap,
	ProgressiveOutboundStatusSliderMarks,
} from "pages/ports/types";
import { useCallback } from "react";

export interface ProgressiveEnforcementSliderProps {
	asset?: Asset;
	currentStatus?:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel;
	selectedStatus?:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel;
	setSelectedStatus?:
		| ((value: ProgressiveEnforcementLevel) => void)
		| ((value: ProgressiveOutboundPortEnforcementLevel) => void);
	disabled?: boolean;
	minValue?:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel;
	direction: Direction;
}

export const ProgressiveEnforcementSlider = ({
	asset,
	currentStatus,
	selectedStatus,
	setSelectedStatus,
	disabled = false,
	direction,
}: ProgressiveEnforcementSliderProps) => {
	const theme = useTheme();

	let progressivePortsStatus:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel =
		direction === Direction.Inbound
			? ProgressiveEnforcementLevel.Any
			: ProgressiveOutboundPortEnforcementLevel.Any;

	if (currentStatus !== undefined) {
		progressivePortsStatus = currentStatus;
	} else if (direction === Direction.Inbound) {
		progressivePortsStatus =
			ProgressiveEnforcementStatusMap[
				asset?.lowestProgressiveInboundPolicyStatus as ProgressiveEnforcementStatus
			];
	} else if (direction === Direction.Outbound) {
		progressivePortsStatus =
			ProgressiveOutboundPortEnforcementStatusMap[
				asset?.lowestProgressiveOutboundPolicyStatus as ProgressiveOutboundPortEnforcementStatus
			];
	}

	const onChange = useCallback(
		(_: Event, v: number | number[]) => {
			if (direction === Direction.Inbound) {
				const value = v as ProgressiveEnforcementLevel;
				(setSelectedStatus as (value: ProgressiveEnforcementLevel) => void)?.(
					value
				);
			} else {
				const value = v as ProgressiveOutboundPortEnforcementLevel;
				(
					setSelectedStatus as (
						value: ProgressiveOutboundPortEnforcementLevel
					) => void
				)?.(value);
			}
		},
		[setSelectedStatus, direction]
	);

	const getMarks = useCallback(() => {
		return (
			direction === Direction.Inbound
				? ProgressiveInboundStatusSliderMarks
				: ProgressiveOutboundStatusSliderMarks
		).map(mark => {
			return {
				value: mark.value,
				label: (
					<ProgressiveSliderLabel
						progressivePortsStatus={progressivePortsStatus}
						currentStatus={mark.key}
						label={ProgressiveEnforcementLevelLabels[mark.key]}
					/>
				),
			};
		});
	}, [progressivePortsStatus, direction]);

	return (
		<SecuritySlider
			selectedStatus={selectedStatus}
			disabled={disabled}
			labelFormatter={v =>
				ProgressiveEnforcementLevelLabels[v as ProgressiveEnforcementLevel]
			}
			onChange={onChange}
			getMarks={getMarks}
			sliderSx={securitySliderStyles({ theme, minValue: undefined })}
		/>
	);
};

const securitySliderStyles = ({
	theme,
	minValue,
}: {
	theme: Theme;
	minValue?: ProgressiveEnforcementLevel;
}) => {
	const DisabledPointerColor = theme.palette.custom.sliderBg;
	let disabledPointer;

	if (minValue === ProgressiveEnforcementLevel.AllowAll) {
		disabledPointer = {
			"&[data-index='0']": {
				background: `${DisabledPointerColor}`,
				border: `5px solid ${DisabledPointerColor}`,
			},
		};
	} else if (minValue === ProgressiveEnforcementLevel.AllowWithBandwidth) {
		disabledPointer = {
			"&[data-index='0']": {
				background: `${DisabledPointerColor}`,
				border: `5px solid ${DisabledPointerColor}`,
			},
			"&[data-index='1']": {
				background: `${DisabledPointerColor}`,
				border: `5px solid ${DisabledPointerColor}`,
			},
		};
	}

	return {
		".MuiSlider-mark": {
			zIndex: "2 !important",
			...disabledPointer,
		},
	};
};
