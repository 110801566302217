import { Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AnalyticsAPIReq, AnalyticsResponse } from "common/types/types";
import deepmerge from "deepmerge";
import { AssetStatusMap } from "pages/asset/components/asset-detail/constants";
import { convertCriteriaToUrlParam } from "pages/asset/components/asset-detail/helpers/criteriaBuilder";
import { AssetStatus } from "pages/assets/types";
import { PathDirection, PathStatus } from "pages/paths/types";
import {
	PortStatus,
	ProgressiveEnforcementStatus,
	ProgressiveEnforcementStatusMap,
	ProgressiveOutboundPortEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatusMap,
} from "pages/ports/types";
import {
	DetailedCount,
	PolicyChangeType,
	Traffic,
	UnreviewedTraffic,
} from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";
import { combineCriteria } from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CTJoinedText, TextORElement } from "../ct-joined-text/CTJoinedText";
import { TrafficCountLink } from "./TrafficCountLink";
import { getAssetBreakdownLinks } from "./helper";
import { useGuardrailsStore } from "./store";
import {
	ChangeType,
	DetailedTrafficStats,
	OutputData,
	SimplifiedTrafficStats,
} from "./types";
import {
	aggregateStats,
	combineTrafficStats,
	getAssetCriteria,
	getPathCriteria,
	getPortCriteria,
	useOverlapStatuses,
} from "./utils";

interface CTGuardrailTrafficProps {
	id?: PolicyChangeType;
	baseCriteria?: Traffic;
	showAssetCount?: boolean;
	showWarning?: boolean;
	selectedStatus?:
		| AssetStatus
		| ProgressiveEnforcementStatus
		| ProgressiveOutboundPortEnforcementStatus;
	updateTraffic?: (
		policyChangeId: PolicyChangeType,
		traffic: UnreviewedTraffic
	) => void;
	secondaryAction?: Element;
	updateAggregate?: (id: PolicyChangeType, traffic?: OutputData) => void;
}

function useAssetAggregateAPI() {
	return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>([
		`asset-aggregate`,
		"aggregate",
	]);
}

export function CTGuardrailTraffic({
	id = PolicyChangeType.AttackSurfaceEnforcement,
	showWarning,
	baseCriteria,
	showAssetCount = true,
	updateTraffic,
	secondaryAction,
	selectedStatus,
	updateAggregate,
}: CTGuardrailTrafficProps) {
	const mutation = useAssetAggregateAPI();
	const guardrailTraffic = useGuardrailsStore(state => state.guardrailTraffic);
	const setGuardrailTraffic = useGuardrailsStore(
		state => state.setGuardrailTraffic
	);

	const changeType =
		id === PolicyChangeType.AttackSurfaceEnforcement ||
		id === PolicyChangeType.BlastRadiusEnforcement
			? ChangeType.ENFORCEMENT
			: ChangeType.PROGRESSIVE;
	const direction =
		id === PolicyChangeType.AttackSurfaceEnforcement ||
		id === PolicyChangeType.AttackSurfaceProgressive
			? PathDirection.Inbound
			: PathDirection.Outbound;

	const [traffic, setTraffic] = useState<OutputData>();

	const assetStatusField =
		changeType === ChangeType.PROGRESSIVE
			? `lowestassetprogressive${direction}status`
			: `asset${direction}status`;

	const onUpdateTraffic = useCallback(
		(traffic: UnreviewedTraffic) => {
			updateTraffic?.(id, traffic);
		},
		[id, updateTraffic]
	);

	const cumulativeTraffic = useMemo(() => {
		if (!traffic) return null;
		return combineTrafficStats(traffic);
	}, [traffic]);

	useEffect(() => {
		if (cumulativeTraffic) {
			onUpdateTraffic?.(cumulativeTraffic);
		}
	}, [cumulativeTraffic, onUpdateTraffic]);

	const getAssetAggregate = useCallback(
		(body: AnalyticsAPIReq) => {
			return mutation.mutateAsync(body);
		},
		[mutation]
	);

	const getTraffic = useCallback(async () => {
		if (guardrailTraffic[id]) {
			setTraffic(guardrailTraffic[id]);
			if (updateAggregate) {
				updateAggregate(id, guardrailTraffic[id]);
			}
			let updatedTraffic = { ...guardrailTraffic };
			delete updatedTraffic[id];
			setGuardrailTraffic(updatedTraffic);
			return;
		}
		const promises = [];
		let portsTraffic: AnalyticsResponse | undefined,
			pathsTraffic: AnalyticsResponse | undefined,
			pathsViolationTraffic: AnalyticsResponse | undefined,
			assetsTraffic: AnalyticsResponse | undefined;

		//get ports traffic
		if (direction === PathDirection.Inbound) {
			const portCriteria = getPortCriteria(changeType);
			portCriteria.criteria = combineCriteria(
				portCriteria.criteria,
				baseCriteria?.ports
			);
			promises.push(getAssetAggregate(portCriteria));
		}

		//get paths traffic
		if (
			!(
				direction === PathDirection.Inbound &&
				changeType === ChangeType.PROGRESSIVE
			)
		) {
			const pathCriteria = getPathCriteria(changeType, direction);
			pathCriteria.criteria = combineCriteria(
				pathCriteria.criteria,
				baseCriteria?.paths
			);
			promises.push(getAssetAggregate(pathCriteria));

			if (changeType === ChangeType.ENFORCEMENT) {
				const pathViolationCriteria = getPathCriteria(
					changeType,
					direction,
					true
				);
				pathViolationCriteria.criteria = combineCriteria(
					pathViolationCriteria.criteria,
					baseCriteria?.paths
				);
				promises.push(getAssetAggregate(pathViolationCriteria));
			}
		}

		//get assets with overlapping segments
		if (baseCriteria?.assets) {
			const assetCriteria = getAssetCriteria(
				assetStatusField,
				baseCriteria.assets
			);
			promises.push(getAssetAggregate(assetCriteria));
		}

		const results = await Promise.all(promises);

		// Safely assign results based on the original conditions
		let resultIndex = 0;
		if (direction === PathDirection.Inbound) {
			portsTraffic = results[resultIndex++];
		}
		if (
			!(
				direction === PathDirection.Inbound &&
				changeType === ChangeType.PROGRESSIVE
			)
		) {
			pathsTraffic = results[resultIndex++];
			if (changeType === ChangeType.ENFORCEMENT) {
				pathsViolationTraffic = results[resultIndex++];
			}
		}
		if (baseCriteria?.assets) {
			assetsTraffic = results[resultIndex];
		}

		if (assetsTraffic) {
			const filteredItems: { [key: string]: any } = {};
			for (const item of Object.keys(assetsTraffic.items)) {
				const assetStatus = Object.keys(
					assetsTraffic.items[item][assetStatusField]
				)[0];
				let shouldInclude = false;

				if (changeType === ChangeType.PROGRESSIVE) {
					if (direction === PathDirection.Inbound) {
						shouldInclude =
							ProgressiveEnforcementStatusMap[
								assetStatus as ProgressiveEnforcementStatus
							] >
							ProgressiveEnforcementStatusMap[
								selectedStatus as ProgressiveEnforcementStatus
							];
					} else if (direction === PathDirection.Outbound) {
						shouldInclude =
							ProgressiveOutboundPortEnforcementStatusMap[
								assetStatus as ProgressiveOutboundPortEnforcementStatus
							] >
							ProgressiveOutboundPortEnforcementStatusMap[
								selectedStatus as ProgressiveOutboundPortEnforcementStatus
							];
					}
				} else if (changeType === ChangeType.ENFORCEMENT) {
					shouldInclude =
						AssetStatusMap[assetStatus as AssetStatus] >
						AssetStatusMap[selectedStatus as AssetStatus];
				}

				if (shouldInclude) {
					filteredItems[item] = assetsTraffic.items[item];
				}
			}
			assetsTraffic.items = filteredItems;
		}

		let traffic: AnalyticsResponse = {
			items: {},
		};
		traffic.items = deepmerge(
			portsTraffic?.items || {},
			pathsTraffic?.items?.assetname || {}
		);

		if (assetsTraffic?.items) {
			traffic.items = deepmerge(traffic.items, assetsTraffic.items);
		}

		let aggregatedTraffic: OutputData = {};
		if (changeType === ChangeType.ENFORCEMENT) {
			traffic.items = deepmerge(
				traffic.items || {},
				pathsViolationTraffic?.items?.assetname || {}
			);
		}
		aggregatedTraffic = aggregateStats(traffic.items, assetStatusField);
		if (updateAggregate) {
			updateAggregate(id, aggregatedTraffic);
		}
		setTraffic(aggregatedTraffic);
	}, [baseCriteria, changeType, direction, id]); // eslint-disable-line
	//TODO: @Navneet Add a dependency on traffic

	useEffect(() => {
		if (baseCriteria) {
			getTraffic();
		}
	}, [baseCriteria, id, getTraffic]);

	const renderTrafficDistribution = () => {
		return (
			<Stack spacing={1}>
				{cumulativeTraffic?.overlaps ? renderOverlaps() : null}
				{renderPortsAndPaths()}
			</Stack>
		);
	};

	const overlapStatuses = useOverlapStatuses(traffic);

	const renderOverlaps = () => {
		const overlapCriteria = `${assetStatusField} in (${overlapStatuses.join(",")})`;
		const assetCriteria = combineCriteria(
			baseCriteria?.assets ?? "",
			overlapCriteria
		);
		const content: TextORElement[] = [
			<TrafficCountLink
				key="overlaps-link"
				count={cumulativeTraffic?.overlaps}
				link={`/assets?filters=${encodeURIComponent(
					convertCriteriaToUrlParam(assetCriteria)
				)}`}
			/>,
			window.getCTTranslatedText(
				"assets might not be affected due to overlapping"
			),
			window.getCTTranslatedText("segments."),
		];
		return <CTJoinedText items={content} />;
	};

	const renderPortsAndPaths = () => {
		if (changeType === ChangeType.PROGRESSIVE) {
			const traffic = cumulativeTraffic as SimplifiedTrafficStats;
			const portsTraffic = traffic?.progressivePorts ?? 0;
			const pathsTraffic = traffic?.progressivePaths ?? 0;

			const hasPorts = typeof portsTraffic === "number" && portsTraffic > 0;
			const hasPaths = typeof pathsTraffic === "number" && pathsTraffic > 0;
			if (hasPorts || hasPaths) {
				const content: TextORElement[] = [
					window.getCTTranslatedText("Traffic to"),
					...(hasPorts
						? [
								<TrafficCountLink
									key="ports-link"
									count={portsTraffic}
									link={`/ports?filters=${encodeURIComponent(
										convertCriteriaToUrlParam(
											combineCriteria(baseCriteria?.ports, ""),
											"ports"
										)
									)}`}
								/>,
								window.getCTTranslatedText("ports"),
							]
						: []),
					...(hasPorts && hasPaths ? [window.getCTTranslatedText("and")] : []),
					...(hasPaths
						? [
								<TrafficCountLink
									key="paths-link"
									count={pathsTraffic}
									link={`/paths?filters=${encodeURIComponent(
										convertCriteriaToUrlParam(
											combineCriteria(baseCriteria?.paths, ""),
											"paths"
										)
									)}`}
								/>,
								window.getCTTranslatedText("paths"),
							]
						: []),
					window.getCTTranslatedText("will be impacted."),
				];
				return <CTJoinedText items={content} />;
			}
		}
		const traffic = cumulativeTraffic as DetailedTrafficStats;
		return (
			<Stack spacing={1}>
				<DetailedTrafficInfo
					type="ports"
					details={traffic?.ports as DetailedCount}
					baseCriteria={baseCriteria?.ports}
				/>
				<DetailedTrafficInfo
					type="paths"
					details={traffic?.paths as DetailedCount}
					baseCriteria={baseCriteria?.paths}
				/>
			</Stack>
		);
	};

	function DetailedTrafficInfo({
		type,
		details,
		baseCriteria = "",
	}: {
		type: "ports" | "paths";
		details: DetailedCount;
		baseCriteria?: string;
	}) {
		const counts = [];
		const statusTypes = type === "ports" ? PortStatus : PathStatus;

		// Helper to create criteria with replaced status
		if (details?.unreviewed) {
			counts.push({
				count: details.unreviewed,
				text: window.getCTTranslatedText("unreviewed"),
				criteria: getAssetBreakdownLinks({
					type,
					status: statusTypes.Unreviewed,
					baseCriteria,
				}),
			});
		}
		if (details?.denied || details?.deniedByTemplate) {
			counts.push({
				count: (details?.denied || 0) + (details?.deniedByTemplate || 0),
				text: window.getCTTranslatedText("denied"),
				criteria: getAssetBreakdownLinks({
					type,
					status: statusTypes.Deny,
					baseCriteria,
				}),
			});
		}

		if (type === "paths" && details?.violations) {
			counts.push({
				count: details?.violations,
				text: window.getCTTranslatedText("test violation"),
				criteria: getAssetBreakdownLinks({
					type,
					status: PathStatus.AllowTestDeniedViolation,
					baseCriteria,
				}),
			});
		}

		if (counts.length === 0) return null;
		return (
			<CTJoinedText
				items={[
					window.getCTTranslatedText("We have identified"),
					...counts.flatMap((item, index) => [
						...(index > 0
							? [
									index === counts.length - 1
										? window.getCTTranslatedText("and")
										: ",",
								]
							: []),
						<TrafficCountLink
							key={item.text}
							count={item.count}
							link={item.criteria}
						/>,
						item.text,
					]),
					`${window.getCTTranslatedText(type)}.`,
				]}
			/>
		);
	}

	if (!showWarning || !cumulativeTraffic?.assets) return null;

	return renderTrafficDistribution();
}
