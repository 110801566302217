import { Box, Grid, Stack } from "@mui/material";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { AssetNetworks } from "modules/asset-networks";
import { AssetTemplates } from "modules/asset-templates";
import {
	AssetMetaDataEditViewProps,
	AssetType,
	OSType,
} from "pages/assets/types";
import { AssetAgentStatus } from "../asset-agent-status";
import { AssetFirewallStatus } from "../asset-firewall-status";
import { AssetMetaData } from "../asset-metadata";
import { AssetMetaDataUserGroup } from "../asset-metadata-user-group";
import { AssetRiskChart } from "../asset-risk-chart";
import { AssetTbp } from "../asset-tbp";
import { AssetTrafficConfig } from "../asset-traffic-config";
import { AssetUser } from "../asset-user";
import { CommentsStatus } from "./components/comments-status";

const ItemStyle = {
	p: 3,
	width: "100%",
	justifyContent: "center",
	borderRadius: 1,
};

const ItemWrapperStyle = {
	display: "flex",
};

export function MetadataReadonly({
	asset,
	handleAllowEdit,
	updateAssetMetadata,
	handleViewMore,
	allowEdit,
}: AssetMetaDataEditViewProps) {
	const { isFeatureEnabled } = useFeatureFlagControl(FEATURES.TRAFFIC_CONFIG);
	const isTrafficConfigEnabled = isFeatureEnabled;
	if (!asset?.assetId) {
		return <Box style={{ width: "100%" }} />;
	}

	return (
		<>
			<Stack>
				<Stack direction="row" sx={{ p: 3, pr: 0, width: "100%" }}>
					<Grid container spacing={3} sx={{ width: "100%" }}>
						<Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
							<AssetMetaData
								asset={asset}
								handleAllowEdit={handleAllowEdit}
								allowEdit={allowEdit}
								updateAssetMetadata={updateAssetMetadata}
								handleViewMore={handleViewMore}
							/>
						</Grid>
						<Grid
							sx={ItemWrapperStyle}
							item
							xl={8}
							lg={8}
							md={12}
							sm={12}
							xs={12}
						>
							<AssetRiskChart criteria={`assetId='${asset.assetId}'`} />
						</Grid>
					</Grid>
				</Stack>
				<Stack
					direction="row"
					spacing={3}
					sx={{ width: "100%", p: 3, pr: 0, pt: 0 }}
				>
					<Grid container spacing={3} sx={{ width: "100%" }}>
						<Grid
							sx={{
								...ItemWrapperStyle,
							}}
							item
							xl={4}
							lg={6}
							md={6}
							sm={12}
							xs={12}
						>
							<Stack
								sx={{
									...ItemStyle,
									border: theme =>
										theme.palette.mode === "dark"
											? "1px solid rgba(255, 255, 255, 0.12)"
											: "1px solid #d9d9d9",
								}}
							>
								<AssetTemplates
									criteria={`assetId = '${asset?.assetId}'`}
									showNonWindows={asset.osName !== OSType.Windows}
								/>
							</Stack>
						</Grid>
						<Grid
							sx={{
								...ItemWrapperStyle,
							}}
							item
							xl={4}
							lg={6}
							md={6}
							sm={12}
							xs={12}
						>
							<Stack
								sx={{
									...ItemStyle,
									border: theme =>
										theme.palette.mode === "dark"
											? "1px solid rgba(255, 255, 255, 0.12)"
											: "1px solid #d9d9d9",
								}}
							>
								<AssetNetworks criteria={`assetId = '${asset?.assetId}'`} />
							</Stack>
						</Grid>

						<Grid
							sx={{
								...ItemWrapperStyle,
							}}
							item
							xl={4}
							lg={6}
							md={6}
							sm={12}
							xs={12}
						>
							<Stack
								sx={{
									...ItemStyle,
									border: theme =>
										theme.palette.mode === "dark"
											? "1px solid rgba(255, 255, 255, 0.12)"
											: "1px solid #d9d9d9",
								}}
							>
								<AssetTbp
									criteria={`assetId = '${asset?.assetId}'`}
									count={asset.policiesAssigned}
								/>
							</Stack>
						</Grid>
						{asset.type !== AssetType.UserGroup && (
							<Grid
								sx={{
									...ItemWrapperStyle,
								}}
								item
								xl={4}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<Stack
									sx={{
										...ItemStyle,
										border: theme =>
											theme.palette.mode === "dark"
												? "1px solid rgba(255, 255, 255, 0.12)"
												: "1px solid #d9d9d9",
									}}
								>
									<AssetAgentStatus asset={asset} />
								</Stack>
							</Grid>
						)}
						{asset.type === AssetType.Endpoint && (
							<Grid
								sx={{
									...ItemWrapperStyle,
								}}
								item
								xl={4}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<Stack
									sx={{
										...ItemStyle,
										border: theme =>
											theme.palette.mode === "dark"
												? "1px solid rgba(255, 255, 255, 0.12)"
												: "1px solid #d9d9d9",
									}}
								>
									<AssetUser criteria={`assetid in ('${asset.assetId}')`} />
								</Stack>
							</Grid>
						)}
						{asset.type === AssetType.Endpoint && (
							<Grid
								sx={{
									...ItemWrapperStyle,
								}}
								item
								xl={4}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<Stack
									sx={{
										...ItemStyle,
										border: theme =>
											theme.palette.mode === "dark"
												? "1px solid rgba(255, 255, 255, 0.12)"
												: "1px solid #d9d9d9",
									}}
								>
									<AssetMetaDataUserGroup
										criteria={`assetid in ('${asset.assetId}') and 'usergroupstatus' in ('active')`}
									/>
								</Stack>
							</Grid>
						)}
						{(asset.type === AssetType.Endpoint ||
							asset.type === AssetType.Server) &&
							isTrafficConfigEnabled && (
								<Grid
									sx={{
										...ItemWrapperStyle,
									}}
									item
									xl={4}
									lg={6}
									md={6}
									sm={12}
									xs={12}
								>
									<Stack
										sx={{
											...ItemStyle,
											border: theme =>
												theme.palette.mode === "dark"
													? "1px solid rgba(255, 255, 255, 0.12)"
													: "1px solid #d9d9d9",
										}}
									>
										<AssetTrafficConfig asset={asset} />
									</Stack>
								</Grid>
							)}
						<Grid
							sx={{
								...ItemWrapperStyle,
							}}
							item
							xl={4}
							lg={6}
							md={6}
							sm={12}
							xs={12}
						>
							<Stack
								sx={{
									...ItemStyle,
									border: theme =>
										theme.palette.mode === "dark"
											? "1px solid rgba(255, 255, 255, 0.12)"
											: "1px solid #d9d9d9",
								}}
							>
								<AssetFirewallStatus asset={asset} />
							</Stack>
						</Grid>
						{asset.type !== AssetType.UserGroup && isTrafficConfigEnabled && (
							<Grid
								sx={{
									...ItemWrapperStyle,
								}}
								item
								xl={4}
								lg={6}
								md={6}
								sm={12}
								xs={12}
							>
								<Stack
									sx={{
										...ItemStyle,
										border: theme =>
											theme.palette.mode === "dark"
												? "1px solid rgba(255, 255, 255, 0.12)"
												: "1px solid #d9d9d9",
									}}
								>
									<CommentsStatus asset={asset} />
								</Stack>
							</Grid>
						)}
					</Grid>
				</Stack>
			</Stack>
		</>
	);
}
