import {
	Alert,
	DialogActions,
	DialogContent,
	DialogTitle,
	Drawer,
	Link,
	Stack,
	Typography,
} from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { Toolbar } from "common/atoms/toolbar";
import { InstructionsRenderer } from "common/molecules/instructions-renderer";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useDepKey } from "pages/agents/components/agent-download-page/hooks/useDepKey";
import { QUERY_ACTIONS } from "pages/appliances/types";
import { RegisterInstructions } from "../constants";

interface RegisterDrawerProps {
	drawerOpen: boolean;
	onClose: () => void;
}

const activationLink = `/appliances?action=${QUERY_ACTIONS.ACTIVATE}`;
const applianceListLink = `/appliances`;

export function RegistrationInstructionContent() {
	const { depKey } = useDepKey();

	return (
		<Stack spacing={2} mt={2}>
			<Typography variant="subtitle1">
				{window.getCTTranslatedText("registerWithActivationCode")}
			</Typography>
			<Typography variant="body2">
				1. {window.getCTTranslatedText("copyTheActivationCodePrintedOnTheCLI")}
			</Typography>
			<Typography variant="body2">
				2. {window.getCTTranslatedText("goTo")}{" "}
				<Link href={activationLink}>
					{window.getCTTranslatedText("activateGatekeeper")}
				</Link>
				.
			</Typography>
			<Typography variant="body2">
				3.{" "}
				{window.getCTTranslatedText(
					"pasteTheActivationCodeToCompleteRegisteration"
				)}
			</Typography>
			<Typography variant="subtitle1" pt={2}>
				{window.getCTTranslatedText("registerWithDeploymentKey")}
			</Typography>
			<Alert
				severity="info"
				action={<CopyButton text={depKey} textButton={true} />}
			>
				{window.getCTTranslatedText("deploymentKey")}: {depKey}
			</Alert>
			<Typography variant="body2">
				1. {window.getCTTranslatedText("copyTheDeploymentKey")}
			</Typography>
			<Typography variant="body2">
				2. {window.getCTTranslatedText("initiateActivationAndWait")}
			</Typography>
		</Stack>
	);
}

export function VerifyInstructionContent() {
	return (
		<Typography variant="body2">
			{window.getCTTranslatedText("verifyGatekeeperSubtitle")}{" "}
			<Link href={applianceListLink}>
				{window.getCTTranslatedText("gatekeeperList")}
			</Link>
			{". "}
			{window.getCTTranslatedText("configureGatekeeperSubtitle")}
		</Typography>
	);
}

export function NetworkInstructionContent() {
	return (
		<Stack spacing={2} mt={2}>
			<Typography variant="subtitle1">
				{window.getCTTranslatedText("networkSettingsTerminalCommand")}
			</Typography>
			<Typography variant="body2">
				{window.getCTTranslatedText("networkAssignInterfacesSubtitle")}
			</Typography>
			<Typography variant="subtitle1">
				{window.getCTTranslatedText("networkSettingsTerminalCommand1")}
			</Typography>
			<Typography variant="body2">
				{window.getCTTranslatedText("networkWANSettingsSubtitle")}
			</Typography>
			<Typography variant="subtitle1">
				{window.getCTTranslatedText("networkSettingsTerminalCommand2")}
			</Typography>
			<Typography variant="body2">
				{window.getCTTranslatedText("networkProxySettingsSubtitle")}
			</Typography>
		</Stack>
	);
}

export function DisplayInstructionContent() {
	return (
		<Stack spacing={2}>
			<Typography variant="body2">
				{window.getCTTranslatedText("displayInstructionSecondarySubtitle")}
			</Typography>
		</Stack>
	);
}

export function RegisterInstructionsDrawer({
	drawerOpen,
	onClose,
}: RegisterDrawerProps) {
	return (
		<Drawer
			anchor={"right"}
			open={drawerOpen}
			onClose={onClose}
			PaperProps={{
				elevation: 1,
				sx: {
					width: "85%",
					minWidth: "1000px",
				},
			}}
		>
			<Toolbar />
			<DialogTitle textAlign={"left"}>
				{window.getCTTranslatedText("registerInstructions")}
			</DialogTitle>
			<DialogContent>
				<InstructionsRenderer instructions={RegisterInstructions} />
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<ToolbarAction
					hideSecondaryBtn={true}
					actionBtnText={"Done"}
					save={onClose}
				/>
			</DialogActions>
		</Drawer>
	);
}
