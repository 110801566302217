import {
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Stack,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { DisplayTextMap } from "common/constants/displayTextMap";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import React from "react";
import { DHCPModes, DHCPOptions, DeviceIdentifierOptions } from "../types";
import { IP_PLACEHOLDER } from "../utils";
import { isDHCPEnabled } from "./ProtectedNetworkInterface";

export interface DHCPProps {
	DHCPMode: string;
	onDHCPModeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	DHCPOption: string;
	onDHCPOptionChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => void;
	deviceIdentifier: string;
	setDeviceIdentifier: (value: string) => void;
	leaseDuration: string;
	setLeaseDuration: (value: string) => void;
	serverIp: string;
	setServerIp: (value: string) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
}

export function DHCP({
	DHCPMode,
	onDHCPModeChange,
	DHCPOption,
	onDHCPOptionChange,
	deviceIdentifier,
	setDeviceIdentifier,
	leaseDuration,
	setLeaseDuration,
	serverIp,
	setServerIp,
	keyListener,
	isFormDisabled,
}: DHCPProps) {
	const renderDHCPMode = () => {
		return (
			<Stack justifyContent="center" minHeight={52}>
				<RadioGroup
					row
					aria-labelledby="select-source-destination-label"
					name="path-direction-radio-buttons-group"
					defaultValue={DHCPModes.Server}
					value={DHCPMode}
					onChange={onDHCPModeChange}
				>
					<FormControlLabel
						value={DHCPModes.Server}
						control={<Radio size="small" />}
						label={window.getCTTranslatedText(DisplayTextMap[DHCPModes.Server])}
						disabled={isFormDisabled}
					/>
					<FormControlLabel
						value={DHCPModes.Relay}
						control={<Radio size="small" />}
						label={window.getCTTranslatedText(DisplayTextMap[DHCPModes.Relay])}
						disabled={isFormDisabled}
					/>
				</RadioGroup>
			</Stack>
		);
	};

	const renderDeviceIdentifier = () => {
		return (
			<Grid
				container
				columnGap={4}
				rowGap={2}
				sx={{ pl: 0 }}
				display="grid"
				gridTemplateColumns="1fr 1fr"
			>
				<CTSelectDropDown
					field={"deviceIdentifier"}
					displayName={window.getCTTranslatedText("deviceIdentifier")}
					selectedValue={deviceIdentifier}
					handleUpdate={(event: TextFieldUpdate) =>
						setDeviceIdentifier(event.value)
					}
					data={DeviceIdentifierOptions}
					textTransform="none"
					showLabel={true}
				/>
			</Grid>
		);
	};

	return (
		<>
			<Stack justifyContent="center" height={42}>
				<RadioGroup
					row
					aria-labelledby="select-source-destination-label"
					name="path-direction-radio-buttons-group"
					defaultValue={DHCPOptions.DHCP}
					value={DHCPOption}
					onChange={onDHCPOptionChange}
				>
					<FormControlLabel
						value={DHCPOptions.Static}
						control={<Radio size="small" />}
						label={window.getCTTranslatedText(
							DisplayTextMap[DHCPOptions.Static]
						)}
						disabled={isFormDisabled}
					/>
					<FormControlLabel
						value={DHCPOptions.DHCP}
						control={<Radio size="small" />}
						label={window.getCTTranslatedText(DisplayTextMap[DHCPOptions.DHCP])}
						disabled={isFormDisabled}
					/>
					<FormControlLabel
						value={DHCPOptions.Mixed}
						control={<Radio size="small" />}
						label={window.getCTTranslatedText(
							DisplayTextMap[DHCPOptions.Mixed]
						)}
						disabled={isFormDisabled}
					/>
				</RadioGroup>
			</Stack>

			{isDHCPEnabled(DHCPOption) ? renderDHCPMode() : renderDeviceIdentifier()}
			{isDHCPEnabled(DHCPOption) ? (
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					{DHCPMode === DHCPModes.Server ? (
						<>
							<Stack>
								<CTInputField
									field="leaseDuration"
									displayName={"Lease Duration (in min)"}
									type="number"
									value={leaseDuration}
									handleUpdate={(event: TextFieldUpdate) =>
										setLeaseDuration(event?.value)
									}
									onKeyUp={keyListener}
									disabled={isFormDisabled}
								/>
							</Stack>
						</>
					) : null}

					{DHCPMode === DHCPModes.Relay ? (
						<>
							<Stack>
								<CTIPInput
									field="serverIp"
									displayName={"Server IP"}
									placeholder={IP_PLACEHOLDER}
									value={serverIp}
									handleUpdate={(event: TextFieldUpdate) =>
										setServerIp(event?.value)
									}
									onKeyUp={keyListener}
									disabled={isFormDisabled}
								/>
							</Stack>
						</>
					) : null}
				</Grid>
			) : null}
		</>
	);
}
