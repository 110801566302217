import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useUpdateAssetAnnotations } from "pages/asset/components/asset-detail/hooks/use-update-asset-annotations";
import { NO_VALUE } from "pages/asset/constants";
import { useAssetStore } from "pages/assets/store";
import { Asset } from "pages/assets/types";
import pluralize from "pluralize";
import { useMemo } from "react";
import MetadataEdit from "./MetadataEdit";
import { AssetEditProps } from "./types";

function AssetEdit({
	assetIds = [],
	open,
	handleAllowEdit,
	updateAssetMetadata,
	tags,
	name,
}: AssetEditProps) {
	const isBulkEdit = assetIds.length > 1;
	const { mutate, isLoading: isEditSubmitLoading } =
		useUpdateAssetAnnotations(assetIds);
	const requestAssetAPIRefresh = useAssetStore(
		state => state.requestAPIRefresh
	);
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const getPluralText = (text: string) => {
		return window.getCTTranslatedText(text, {
			mode: window.getCTTranslatedText(pluralize("Asset", isBulkEdit ? 2 : 1)),
		});
	};

	const title = getPluralText("AssetEditDetailsTitle");
	const infoText = getPluralText("AssetEditDetailsInfo");

	const data = useMemo(() => {
		const nameArr = name ? [{ ...name, columns: 12 }] : [];

		const tagsArr = tags
			.filter(tag => tag.isCoreTagEditable)
			.map(tag => ({
				...tag,
				columns: 6,
			}));

		return [...nameArr, ...tagsArr];
	}, [name, tags]);

	const onEditSubmit = (data: Partial<Asset>) => {
		const handleNoValueString = (body: Partial<Asset>) => {
			const obj = { ...body };
			for (const key in obj?.coreTags) {
				if (obj.coreTags[key] === NO_VALUE) {
					obj.coreTags[key] = "";
				} else if (obj.coreTags[key] === "") {
					delete obj.coreTags[key];
				}
			}

			if (!obj.businessValue) {
				delete obj.businessValue;
			}

			return obj;
		};

		const body = isBulkEdit
			? {
					criteria: `assetId in (${(assetIds ?? [])
						.map(id => `'${id}'`)
						.join(",")})`,
					...handleNoValueString(data),
				}
			: handleNoValueString(data);

		mutate(body, {
			onSuccess: () => {
				updateAssetMetadata({
					body: body,
					id: "assetName",
				});
				requestAssetAPIRefresh();

				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "AssetDetailsSubmittedSuccessfully",
				});
				setTimeout(() => {
					handleAllowEdit(false);
				}, 500);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	return (
		<MetadataEdit
			title={title}
			infoText={infoText}
			data={data}
			open={open}
			onClose={() => handleAllowEdit(false)}
			isSubmitLoading={isEditSubmitLoading}
			onSubmit={onEditSubmit}
		/>
	);
}

export default AssetEdit;
