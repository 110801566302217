import RefreshIcon from "@mui/icons-material/Refresh";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { getRelativeLastObserved } from "common/utils";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { Direction } from "pages/asset/components/asset-detail/constants";
import { Asset } from "pages/assets/types";
import {
	ProgressiveEnforcementSlider,
	ProgressiveEnforcementSliderProps,
} from "pages/ports/components/progressive-enforcement-slider/ProgressiveEnforcementSlider";
import {
	ProgressiveEnforcementLevel,
	ProgressiveOutboundPortEnforcementLevel,
} from "pages/ports/types";

export function useRefreshProgressiveTagPolicyAPI(
	savedPolicyId: string | undefined
) {
	return useMutation<any, Error, {}>([
		"refresh-progressive",
		`tagbasedpolicies/${savedPolicyId}/refresh-progressive`,
		"PUT",
	]);
}

interface ProgressiveStatusProps extends ProgressiveEnforcementSliderProps {
	criteria?: string;
	asset?: Asset;
	policyId?: string;
	policyProgressiveLastRefreshed?: string;
	direction: Direction;
	initialStatus?:
		| ProgressiveEnforcementLevel
		| ProgressiveOutboundPortEnforcementLevel;
}

export const ProgressiveStatus = ({
	criteria,
	policyId,
	initialStatus,
	policyProgressiveLastRefreshed,
	direction,
	...props
}: ProgressiveStatusProps) => {
	const theme = useTheme();
	const notify = useEventSubscriptionStore(state => state.notify);

	const refreshProgressiveMutation =
		useRefreshProgressiveTagPolicyAPI(policyId);

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const onRefreshProgressive = () => {
		refreshProgressiveMutation.mutateAsync(
			{},
			{
				onSuccess: () => {
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "refreshProgressiveSuccess",
					});
				},
				onError: error => {
					setSnackbar(
						true,
						SnackBarSeverity.Error,
						"refreshProgressiveFailure"
					);
				},
			}
		);
	};

	if (props.selectedStatus === undefined || !criteria || criteria === "*") {
		return null;
	}

	const shouldShowRefreshButton =
		policyProgressiveLastRefreshed &&
		(initialStatus === ProgressiveEnforcementLevel.AllowAll ||
			initialStatus === ProgressiveEnforcementLevel.AllowWithBandwidth);

	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				mt={2}
				height={30}
			>
				<Stack>
					<Typography
						variant="body2"
						sx={{ color: theme => theme.palette.text.secondary }}
					>
						{window.getCTTranslatedText("progressive").toUpperCase()}
					</Typography>
					{policyProgressiveLastRefreshed && (
						<Typography
							variant="caption"
							sx={{ color: theme => theme.palette.text.secondary }}
						>
							{window.getCTTranslatedText("lastSynced")}:{" "}
							{getRelativeLastObserved(policyProgressiveLastRefreshed ?? "")}
						</Typography>
					)}
				</Stack>
				{shouldShowRefreshButton && (
					<Stack spacing={2} direction="row" alignItems="center">
						<Tooltip
							title={window.getCTTranslatedText(
								"refreshProgressiveEnforcement"
							)}
						>
							<IconButton size="small" onClick={onRefreshProgressive}>
								<RefreshIcon
									fontSize="small"
									sx={{ color: theme.palette.primary.main }}
								/>
							</IconButton>
						</Tooltip>
					</Stack>
				)}
			</Stack>
			<Box
				sx={{
					mt: 15,
					width: "100%",
					px: 4,
				}}
			>
				<ProgressiveEnforcementSlider direction={direction} {...props} />
			</Box>

			<Divider sx={{ mb: 3 }} />
		</>
	);
};
