import { FacetStore } from "modules/facets/types";
import { Scope } from "modules/scope-metadata/types";
import { StoreApi, UseBoundStore } from "zustand";

export interface AnalyticsAPIReq {
	criteria: string;
	sourceCriteria?: string | undefined;
	destinationCriteria?: string | undefined;
	sourceGroupBy?: Array<string> | undefined;
	destinationGroupBy?: Array<string> | undefined;
	scope: Scope;
	groupBy?: Array<string>;
	statistics: Array<string>;
	timeStart?: string;
	timeEnd?: string;
}

export interface StatContainer {
	statistics: {
		[key: string]: number;
	};
}

export interface StatParent {
	[key: string]: {
		[key: string]: StatContainer;
	};
}
export interface TypeStat {
	[key: string]: StatParent;
}

export interface AnalyticsResponse {
	items: {
		[key: string]: {
			[key: string]: TypeStat;
		};
	};
}

export type CustomCommonStoreType = UseBoundStore<StoreApi<FacetStore>>;

export enum IconDictionary {
	CORE_TAG = "coreTag",
	MISC = "misc",
	OS = "osName",
	UNMANAGED_DEVICE = "unmanagedDevice",
}
