import { Button, Stack, Tooltip } from "@mui/material";
import { CTConfirmation } from "common/atoms/ct-confirmation";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useUnmanagedDevicesStore } from "pages/appliance-detail/store";
import { useState } from "react";
import { MAX_MANAGE_COUNT } from "../../appliance-unmanaged-devices/constants";
import { UnmanagedDevice } from "../../appliance-unmanaged-devices/types";
import { useDeleteUnmanagedDevicesAPI, useManageDevicesAPI } from "../hooks";

export interface DevicesToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<UnmanagedDevice>;
	disableActions: boolean;
}

export const UnmanagedDeviceToolbar = (props: DevicesToolbarProps) => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const [showManageConfirmation, setShowManageConfirmation] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const refreshRequest = useUnmanagedDevicesStore(
		state => state.requestAPIRefresh
	);

	const isSelectionValid = props.selectedData?.length <= MAX_MANAGE_COUNT;
	const manageDevicesMutation = useManageDevicesAPI();
	const deleteDevicesMutation = useDeleteUnmanagedDevicesAPI();

	const manageDevices = async () => {
		let selectedDevices = props.selectedData?.map(device => {
			return { deviceId: device?.deviceId, deviceName: device?.deviceName };
		});

		await manageDevicesMutation.mutateAsync(
			{ devices: selectedDevices },
			{
				onSuccess: () => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						window.getCTTranslatedText("manageDevicesSuccess")
					);
					setShowManageConfirmation(false);
					refreshRequest();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
					setShowManageConfirmation(false);
				},
			}
		);
	};

	const deleteDevices = async () => {
		let selectedDevices = props.selectedData?.map(device => {
			return device?.deviceId;
		});
		await deleteDevicesMutation.mutateAsync(
			{ deviceIDList: selectedDevices },
			{
				onSuccess: () => {
					setSnackbar(
						true,
						SnackBarSeverity.Success,
						window.getCTTranslatedText("deleteDevicesSuccess")
					);
					setShowDeleteConfirmation(false);
					refreshRequest();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			}
		);
	};

	const getTooltipText = () => {
		if (props?.disableActions) {
			return window.getCTTranslatedText("disableManageButtonText");
		} else if (!isSelectionValid) {
			return window.getCTTranslatedText("devicesLimitExceedMessage", {
				count: MAX_MANAGE_COUNT,
			});
		} else {
			return "";
		}
	};

	const renderManageButton = () => {
		return (
			<Tooltip title={getTooltipText()}>
				<Stack>
					<Button
						variant="outlined"
						disabled={!isSelectionValid || props?.disableActions}
						onClick={() => setShowManageConfirmation(true)}
					>
						{window.getCTTranslatedText("manageDevices")}
					</Button>
				</Stack>
			</Tooltip>
		);
	};

	const renderDeleteButton = () => {
		return (
			<Tooltip
				title={
					isSelectionValid
						? ""
						: window.getCTTranslatedText("devicesLimitExceedMessage", {
								count: MAX_MANAGE_COUNT,
							})
				}
			>
				<Stack>
					<Button
						variant="outlined"
						disabled={!isSelectionValid}
						onClick={() => setShowDeleteConfirmation(true)}
					>
						{window.getCTTranslatedText("Delete")}
					</Button>
				</Stack>
			</Tooltip>
		);
	};

	return (
		<GridToolbar {...props} hiddenColumns={props.hiddenColumns}>
			{props.selectedData && props.selectedData.length > 0 && (
				<Stack
					direction="row"
					spacing={2}
					sx={{ width: "100%" }}
					justifyItems="flex-end"
					alignItems={"center"}
					justifyContent="flex-end"
				>
					<Stack
						direction="row"
						spacing={2}
						alignItems={"center"}
						sx={{ height: "100%" }}
					>
						{userPermissions.has("UPDATE_UNMANAGED_TO_MANAGED") &&
							renderManageButton()}
						{userPermissions.has("DELETE_UNMANAGED_DEVICE") &&
							renderDeleteButton()}
					</Stack>
				</Stack>
			)}
			<CTConfirmation
				open={showManageConfirmation}
				onClose={() => setShowManageConfirmation(false)}
				title={window.getCTTranslatedText("manageDevices")}
				primaryText={window.getCTTranslatedText("manageDevicesConfirmation")}
				primaryButtonText={window.getCTTranslatedText("manageButton")}
				isLoading={manageDevicesMutation.isLoading}
				onSuccess={manageDevices}
			/>
			<CTConfirmation
				open={showDeleteConfirmation}
				onClose={() => setShowDeleteConfirmation(false)}
				title={window.getCTTranslatedText("deleteDevices")}
				primaryText={window.getCTTranslatedText(
					"unmanagedDevicesDeleteConfirmation"
				)}
				primaryButtonText={window.getCTTranslatedText("Delete")}
				isLoading={deleteDevicesMutation.isLoading}
				onSuccess={deleteDevices}
			/>
		</GridToolbar>
	);
};
