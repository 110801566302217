import { Button, Stack, Tab } from "@mui/material";
import { CtTabs, TabPanel } from "common/atoms/ct-tabs";
import { useUserPreferencesStore } from "common/store/useUserPreferenceStore";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import numeral from "numeral";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCreationTime } from "./AuditLogUtils";
import { AlertsTab } from "./components/alerts-tab";
import { AuditLogTab } from "./components/audit-log-tab";

export enum MonitoringTabs {
	Alerts = 0,
	Audit_logs = 1,
}

interface CreationTimeFilterType {
	startTime: string;
	endTime: string;
}

export const Monitoring = () => {
	const navigate = useNavigate();
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const tabParam = urlParams.get("tab");
	const [selectedTab, setSelectedTab] = useState(
		tabParam === "audit_logs"
			? MonitoringTabs.Audit_logs
			: MonitoringTabs.Alerts
	);

	const defaultCreationFilterType: CreationTimeFilterType = {
		startTime: "",
		endTime: "",
	};
	const [creationFilter, setCreationFilter] = useState<CreationTimeFilterType>(
		defaultCreationFilterType
	);
	const [configuredRules, setConfiguredRules] = useState<number>(0);

	const globalSelectedTime = useUserPreferencesStore(state => state.timeFilter);

	const setTime = useCallback(() => {
		const formatedTime = formatCreationTime(globalSelectedTime);
		setCreationFilter({
			startTime: formatedTime?.startTime,
			endTime: formatedTime?.endTime,
		});
	}, [globalSelectedTime]);

	useEffect(() => {
		setTime();
	}, [setTime]);

	const handleTabChange = (newValue: number) => {
		setSelectedTab(newValue);
		const formatedTime = formatCreationTime(globalSelectedTime);
		setCreationFilter({
			startTime: formatedTime?.startTime,
			endTime: formatedTime?.endTime,
		});
		let tabName = "";
		switch (newValue) {
			case MonitoringTabs.Alerts:
				tabName = "alerts";
				break;
			case MonitoringTabs.Audit_logs:
				tabName = "audit_logs";
				break;
		}

		navigate(`/monitoring?tab=${tabName}`);
	};

	const shouldShowLogs = userPermissions.size
		? userPermissions.has("LIST_EVENT")
		: true;

	return (
		<Stack
			direction={"column"}
			spacing={2}
			sx={{ height: "100%", width: "100%" }}
		>
			<Stack sx={{ width: "100%" }}>
				<Stack direction="row" sx={{ width: "100%" }}>
					<Stack flex={1}>
						<CtTabs
							value={selectedTab}
							onChange={(_, value) => handleTabChange(value)}
						>
							<Tab label={window.getCTTranslatedText("Alerts")} />
							{shouldShowLogs && (
								<Tab label={window.getCTTranslatedText("Logs")} />
							)}
						</CtTabs>
					</Stack>
					<Stack
						alignItems="flex-end"
						justifyItems={"flex-end"}
						sx={{ mt: 0.5 }}
					>
						<Stack
							alignItems="center"
							direction={"row"}
							justifyContent={"center"}
						>
							{selectedTab === MonitoringTabs.Alerts &&
								userPermissions.has("UPDATE_ALERTRULES") && (
									<Button
										color="primary"
										variant="outlined"
										onClick={() => navigate(`/settings?tab=alertconfig`)}
										sx={{ mt: 1, mr: 3 }}
									>
										{Number(configuredRules) > 0
											? window.getCTTranslatedText("Alert Config") +
												` (${numeral(Number(configuredRules)).format("0a")})`
											: window.getCTTranslatedText("Alert Config")}
									</Button>
								)}
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				sx={{ width: "100%", flex: 1, overflow: "hidden" }}
				alignItems="flex-start"
			>
				<TabPanel value={selectedTab} index={0} style={{ height: "100%" }}>
					<AlertsTab
						creationFilter={creationFilter}
						setConfiguredRules={setConfiguredRules}
						setTime={setTime}
					/>
				</TabPanel>
				{shouldShowLogs ? (
					<TabPanel value={selectedTab} index={1} style={{ height: "100%" }}>
						<AuditLogTab creationFilter={creationFilter} setTime={setTime} />
					</TabPanel>
				) : (
					<RedirectToAlerts
						selectedTab={selectedTab}
						handleTabChange={handleTabChange}
					/>
				)}
			</Stack>
		</Stack>
	);
};

interface RedirectToAlertsProps {
	selectedTab: MonitoringTabs;
	handleTabChange: (newValue: number) => void;
}

const RedirectToAlerts = ({
	selectedTab,
	handleTabChange,
}: RedirectToAlertsProps) => {
	useEffect(() => {
		if (selectedTab !== MonitoringTabs.Alerts) {
			handleTabChange(MonitoringTabs.Alerts);
		}
	});

	return null;
};
