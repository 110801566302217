import { useQuery } from "@tanstack/react-query";
import { AssetStatus } from "pages/assets/types";
import {
	ProgressiveEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatus,
} from "pages/ports/types";

export interface TagBasedPolicyAutoPushBody {
	lowestInboundPolicyStatus: AssetStatus;
	lowestOutboundPolicyStatus: AssetStatus;
	lowestProgressiveInboundPolicyStatus?: ProgressiveEnforcementStatus;
	lowestProgressiveOutboundPolicyStatus?: ProgressiveOutboundPortEnforcementStatus;
	autoSynchronizeEnabled: boolean;
	policyProgressiveLastRefreshed?: string;
}

export function useTagBasedPolicyAutoPushAPI(id: string) {
	return useQuery<TagBasedPolicyAutoPushBody, Error>([
		"auto-push",
		`tagbasedpolicies/${id}`,
	]);
}
