import {
	Box,
	CircularProgress,
	Divider,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import { CopyButton } from "common/atoms/copy-button";
import { dayjsWithPlugins } from "common/utils";
import { useCallback } from "react";
import { RenderContentProps } from "../agent-drawers/AgentUpgradeDrawer";
import { useAgentOtpAPI } from "./hooks";

export const AgentOfflinePassword = () => {
	const { data: agentOtpData, isLoading: isAgentOtpDataLoading } =
		useAgentOtpAPI({
			enabled: true,
		});

	const renderContent = useCallback(
		({ agentOtpData, isLoading = false }: RenderContentProps) => {
			const expiresOnDisplay = dayjsWithPlugins(
				agentOtpData?.expirationTime ?? 0
			).format("MMM DD, YYYY h:mm A");

			if (isLoading) {
				return (
					<Stack
						sx={{
							width: "100%",
							height: "100%",
						}}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<CircularProgress size={24} />
					</Stack>
				);
			}

			return (
				<>
					<Stack px={3} py={2}>
						<Typography variant="subtitle1">
							{window.getCTTranslatedText(`AgentFirewallResetPassword`)}
						</Typography>
					</Stack>
					<Divider />
					<Stack sx={{ p: 3, width: "100%" }} spacing={1} gap={3}>
						<Typography variant="body2">
							{window.getCTTranslatedText("ReconnectAgentPasswordInstructions")}
						</Typography>

						<Box>
							<Typography variant="subtitle2" fontWeight="500">
								{window.getCTTranslatedText("Password")}
							</Typography>

							<Stack
								component={Paper}
								direction={"row"}
								justifyContent={"space-between"}
								alignItems={"center"}
								p={2}
							>
								<Typography variant="body2">{agentOtpData?.otp}</Typography>
								<CopyButton text={agentOtpData?.otp} />
							</Stack>
						</Box>

						<Box>
							<Typography variant="subtitle2" fontWeight="500">
								{window.getCTTranslatedText("Validity")}
							</Typography>
							<Typography variant="body2">
								{`${window.getCTTranslatedText("ExpiresOn")} ${expiresOnDisplay}`}
							</Typography>
						</Box>
					</Stack>
				</>
			);
		},
		[]
	);

	return (
		<Paper
			elevation={1}
			sx={{
				width: "100%",
				height: "auto",
				borderRadius: 1,
			}}
		>
			{renderContent({ agentOtpData, isLoading: isAgentOtpDataLoading })}
		</Paper>
	);
};
