import { Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TrafficCountLink } from "common/atoms/ct-guardrail";
import { getAssetBreakdownLinks } from "common/atoms/ct-guardrail/helper";
import { PathStatus } from "pages/paths/types";
import { PortStatus } from "pages/ports/types";
import { Guardrail } from "./types";

const removeDirection = (criteria: string | undefined) => {
	return criteria?.replace(/'direction' in \('inbound'|'outbound'\)/g, "");
};

export const ASSET_GUARDRAILS_PROGRESSIVE_BREAKDOWN_COLUMNS = (
	baseCriteria?: Guardrail
): GridColDef[] => {
	const criteria = baseCriteria?.baseCriteria;
	return [
		{
			field: "assetName",
			headerName: "Asset Name",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			sortable: false,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				return (
					<Typography variant="body2" noWrap title={assetName}>
						{assetName}
					</Typography>
				);
			},
		},
		{
			field: "progressivePorts",
			headerName: "Progressive Ports",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					type: "ports",
					baseCriteria: criteria?.ports,
					assetName,
				});

				return (
					<TrafficCountLink
						count={params.row[assetName].progressivePorts}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.progressivePorts - row2[row2Asset]?.progressivePorts
				);
			},
		},
		{
			field: "progressivePaths",
			headerName: "Progressive Paths",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					type: "paths",
					baseCriteria: removeDirection(criteria?.paths),
					assetName,
				});

				return (
					<TrafficCountLink
						count={params.row[assetName].progressivePaths}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.progressivePaths - row2[row2Asset]?.progressivePaths
				);
			},
		},
	];
};

export const ASSET_GUARDRAILS_ENFORCEMENT_BREAKDOWN_COLUMNS = (
	baseCriteria?: Guardrail
): GridColDef[] => {
	const criteria = baseCriteria?.baseCriteria;
	return [
		{
			field: "unreviewedPorts",
			headerName: "Unreviewed Ports",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					status: PortStatus.Unreviewed,
					type: "ports",
					baseCriteria: criteria?.ports,
					assetName,
				});

				return (
					<TrafficCountLink
						count={params.row[assetName]?.ports?.unreviewed}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.ports?.unreviewed -
					row2[row2Asset]?.ports?.unreviewed
				);
			},
		},
		{
			field: "deniedPorts",
			headerName: "Denied Ports",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					status: PortStatus.Deny,
					type: "ports",
					baseCriteria: criteria?.ports,
					assetName,
				});

				return (
					<TrafficCountLink
						count={
							params.row[assetName]?.ports?.denied +
							params.row[assetName]?.ports?.deniedByTemplate
						}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.ports?.denied +
					row1[row1Asset]?.ports?.deniedByTemplate -
					(row2[row2Asset]?.ports?.denied +
						row2[row2Asset]?.ports?.deniedByTemplate)
				);
			},
		},
		{
			field: "unreviewedPaths",
			headerName: "Unreviewed Paths",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					status: PathStatus.Unreviewed,
					type: "paths",
					baseCriteria: removeDirection(criteria?.paths),
					assetName,
				});

				return (
					<TrafficCountLink
						count={params.row[assetName]?.paths?.unreviewed}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.paths?.unreviewed -
					row2[row2Asset]?.paths?.unreviewed
				);
			},
		},
		{
			field: "deniedPaths",
			headerName: "Denied Paths",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					status: PathStatus.Deny,
					type: "paths",
					baseCriteria: removeDirection(criteria?.paths),
					assetName,
				});

				return (
					<TrafficCountLink
						count={
							params.row[assetName]?.paths?.denied +
							params.row[assetName]?.paths?.deniedByTemplate
						}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.paths?.denied +
					row1[row1Asset]?.paths?.deniedByTemplate -
					(row2[row2Asset]?.paths?.denied +
						row2[row2Asset]?.paths?.deniedByTemplate)
				);
			},
		},
		{
			field: "testViolationPaths",
			headerName: "Test violation paths",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				const link = getAssetBreakdownLinks({
					status: PathStatus.AllowTestDeniedViolation,
					type: "paths",
					baseCriteria: removeDirection(criteria?.paths),
					assetName,
				});

				return (
					<TrafficCountLink
						count={params.row[assetName]?.paths?.violations}
						link={link}
					/>
				);
			},
			sortComparator: (_, __, p1, p2) => {
				const row1 = p1.api.getRow(p1.id);
				const row2 = p2.api.getRow(p2.id);

				const row1Asset = Object.keys(row1)[0];
				const row2Asset = Object.keys(row2)[0];

				return (
					row1[row1Asset]?.paths?.violations -
					row2[row2Asset]?.paths?.violations
				);
			},
		},
		{
			field: "segmentOverlap",
			headerName: "Segment Overlap",
			minWidth: 130,
			width: 130,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const assetName = Object.keys(params.row)[0];
				return params.row[assetName]?.status ? (
					<Typography variant="body2" noWrap title={assetName}>
						{window.getCTTranslatedText("True")}
					</Typography>
				) : null;
			},
		},
	];
};
const ColumnVisibilityModel: { [key: string]: boolean } = {
	// Hide columns from the display
};

const PinnedColumns = {
	left: ["assetName"],
	right: [],
};

export const AssetGuardRailColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};
