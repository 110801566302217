import CloseIcon from "@mui/icons-material/Close";
import {
	Alert,
	DialogActions,
	DialogContent,
	Drawer,
	IconButton,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { UseMutateAsyncFunction } from "@tanstack/react-query/build/lib/types";
import { Toolbar } from "common/atoms/toolbar";
import { parseErrorMessage } from "common/utils";
import { getSegmentsNameFromCriteria } from "common/utils/getSegmentsNameFromCriteria";
import isEqual from "lodash/isEqual";
import { useAssignTemplateToTags } from "modules/add-to-templates/components/AddToTemplateDialog/helpers/use-assign-template";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { FacetUtils } from "modules/facets";
import { FacetState } from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useSaveTagPolicyAPI } from "pages/create-tag-policy/components/PolicyForm";
import { PathDirection, PathStatus } from "pages/paths/types";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { TagPolicy } from "pages/tags/components/tag-policy-list/types";
import {
	useAddRulesAPI,
	useCreateTemplatesAPI,
} from "pages/templates/components/template-form-drawer/components/template-form/hooks";
import {
	PathRuleInt,
	PortFormInt,
} from "pages/templates/components/template-form-drawer/components/template-form/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { PathTagPolicyRulesGenerator } from "./components/PathTagPolicyRulesGenerator";
import {
	CoreTags,
	TagBasedPolicyCreationInput,
} from "./components/source-and-destination-form/SourceAndDestinationForm";

interface CreateTagBasedPolicyDrawerProps {
	isOpen: boolean;
	onClose: VoidFunction;
	// direction: PathDirection;
	defaultSourceTagBasedPolicyId?: string;
	defaultDestinationTagBasedPolicyId?: string;
	templateRules?: PortFormInt[];
	defaultSourceFacets?: FacetState;
	defaultDestinationFacets?: FacetState;
	defaultSourceCoreTags?: CoreTags;
	defaultDestinationCoreTags?: CoreTags;
	isRingFence?: boolean;
}

export const MAX_ACCESS_POLICY_ASSETS = 100;

export const CreateTagBasedPolicyDrawer = ({
	isOpen,
	onClose,
	// direction,
	defaultSourceFacets,
	defaultDestinationFacets,
	defaultSourceCoreTags,
	defaultDestinationCoreTags,
	templateRules,
	defaultSourceTagBasedPolicyId,
	defaultDestinationTagBasedPolicyId,
	isRingFence,
}: CreateTagBasedPolicyDrawerProps) => {
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const [rules, setRules] = useState(templateRules ?? []);
	const refreshRequest = useTagPolicyStore(state => state.requestAPIRefresh);

	const {
		onSubmit,
		isRunning,
		onUpdateDestinationPolicy,
		onUpdateSourcePolicy,
		sourceDetails,
		destDetails,
	} = useTagBasedPolicyGenerator({
		rules,
		onClose: () => {
			setSnackbar(true, SnackBarSeverity.Success, "PolicyCreated");
			refreshRequest();
			onClose();
		},
		onError: error => {
			setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
		},
	});

	const onChangeRules = useCallback((rules: any) => {
		setRules(rules);
	}, []);

	const { data: metadata } = useScopeMetadata({ scope: Scope.Asset });

	const showWarning =
		(sourceDetails?.totalAssets ?? 0) > MAX_ACCESS_POLICY_ASSETS ||
		(destDetails?.totalAssets ?? 0) > MAX_ACCESS_POLICY_ASSETS;

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={onClose}
			PaperProps={{
				sx: {
					padding: "0px",
					width: "80%",
					minWidth: "1000px",
					height: "100%",
				},
				elevation: 1,
			}}
		>
			<Toolbar />
			<Stack
				alignItems="flex-start"
				sx={{ position: "relative", width: "100%" }}
			>
				<Tooltip title={window.getCTTranslatedText("Close Drawer")}>
					<IconButton
						size="medium"
						aria-label="close drawer"
						onClick={onClose}
						sx={{ position: "absolute", right: "16px", top: "24px", zIndex: 2 }}
					>
						<CloseIcon fontSize="medium" />
					</IconButton>
				</Tooltip>

				<Stack direction="row" spacing={2} sx={{ mt: 5, mb: 3, mx: 4 }}>
					<Typography variant="h5">
						<b>
							{isRingFence
								? window.getCTTranslatedText("Create Ring Fence")
								: window.getCTTranslatedText("Create Access Policy")}
						</b>
					</Typography>
				</Stack>
			</Stack>

			<DialogContent
				sx={{
					pt: 0,
					display: "flex",
					flexDirection: "column",
					flex: 1,
				}}
			>
				{showWarning && (
					<Alert sx={{ mb: 1 }} severity="warning">
						{window.getCTTranslatedText("accessPolicyWarning", {
							count: MAX_ACCESS_POLICY_ASSETS,
						})}
					</Alert>
				)}

				{metadata && (
					<PathTagPolicyRulesGenerator
						defaultRules={rules}
						onChangeRules={onChangeRules}
						defaultDestinationCoreTags={defaultDestinationCoreTags}
						defaultSourceCoreTags={defaultSourceCoreTags}
						defaultSourceFacets={defaultSourceFacets}
						defaultDestinationFacets={defaultDestinationFacets}
						defaultSourceTagBasedPolicyId={defaultSourceTagBasedPolicyId}
						defaultDestinationTagBasedPolicyId={
							defaultDestinationTagBasedPolicyId
						}
						onChangeDest={onUpdateDestinationPolicy}
						onChangeSource={onUpdateSourcePolicy}
						sourceDetails={sourceDetails}
						destDetails={destDetails}
						metadata={metadata}
						isRingFence={isRingFence}
					/>
				)}
			</DialogContent>

			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<ToolbarAction
					loading={isRunning || !onSubmit}
					isValid={
						Boolean(onSubmit) &&
						Boolean(rules?.length) &&
						Boolean(sourceDetails?.criteria && destDetails?.criteria) &&
						Boolean(
							sourceDetails?.template?.templateName &&
								destDetails?.template?.templateName
						)
					}
					actionBtnText={"Confirm"}
					save={() => {
						if (!onSubmit) {
							return;
						}
						onSubmit();
					}}
					cancel={onClose}
					hasPermission={true}
				/>
			</DialogActions>
		</Drawer>
	);
};

function useTagBasedPolicyGenerator({
	rules,
	onClose,
	onError,
}: {
	rules: PortFormInt[];
	onClose: VoidFunction;
	onError: (err: any) => void;
}) {
	const { data: metadata } = useScopeMetadata({ scope: Scope.TagPolicy });
	const [isRunning, setRunning] = useState(false);
	const [sourceDetails, setSourceDetails] = useState<
		TagBasedPolicyCreationInput | undefined
	>();
	const [destDetails, setDestDetails] = useState<
		TagBasedPolicyCreationInput | undefined
	>();

	const onUpdateSourcePolicy = useCallback(
		(details: TagBasedPolicyCreationInput) => {
			setSourceDetails(details);
		},
		[]
	);

	const onUpdateDestinationPolicy = useCallback(
		(details: TagBasedPolicyCreationInput) => {
			setDestDetails(details);
		},
		[]
	);

	const createTagPolicyAPI = useSaveTagPolicyAPI();
	const { mutateAsync: createTemplate } = useCreateTemplatesAPI();

	const { mutateAsync: appendSourceTemplate } = useAddRulesAPI(
		sourceDetails?.template?.templateId
	);
	const { mutateAsync: appendDestTemplate } = useAddRulesAPI(
		destDetails?.template?.templateId
	);

	const { mutateAsync: editSourceTagPolicy } = useAssignTemplateToTags({
		policyId: sourceDetails?.tagBasedPolicyId,
	});
	const { mutateAsync: editDestTagPolicy } = useAssignTemplateToTags({
		policyId: destDetails?.tagBasedPolicyId,
	});

	const [execute, setExecute] = useState(false);

	const onCloseRef = useRef(onClose);
	const onErrorRef = useRef(onError);

	const runningRef = useRef(false);

	useEffect(() => {
		if (!execute || !rules?.length) {
			return;
		}

		async function runImpl() {
			if (runningRef.current) {
				return;
			}
			if (!rules?.length) {
				setRunning(false);
				return;
			}
			if (!sourceDetails?.tagBasedPolicyId) {
				setRunning(false);
				throw new Error(
					window.getCTTranslatedText("Core tags missing in source")
				);
			}

			if (!destDetails?.tagBasedPolicyId) {
				setRunning(false);
				throw new Error(
					window.getCTTranslatedText("Core tags missing on dest")
				);
			}

			runningRef.current = true;

			const sourceChangeRules: PathRuleInt[] = [];
			const destinationChangeRules: PathRuleInt[] = [];

			let isSameTemplate =
				sourceDetails?.template?.templateName &&
				sourceDetails?.template?.templateName ===
					destDetails?.template?.templateName;

			rules.forEach(rule => {
				sourceChangeRules.push({
					direction: PathDirection.Outbound,
					port: rule.listenPort,
					protocol: rule.listenPortProtocol,
					reviewed: PathStatus.Allow,
					destinationTagBasedPolicy: {
						tagBasedPolicyId: destDetails?.tagBasedPolicyId,
					},
				});

				const destinationRule = {
					direction: PathDirection.Inbound,
					port: rule.listenPort,
					protocol: rule.listenPortProtocol,
					reviewed: PathStatus.Allow,
					sourceTagBasedPolicy: {
						tagBasedPolicyId: sourceDetails?.tagBasedPolicyId,
					},
				};
				if (isSameTemplate) {
					sourceChangeRules.push(destinationRule);
				} else {
					destinationChangeRules.push(destinationRule);
				}
			});

			if (!sourceDetails.template?.templateId) {
				let createSrcTempResp = await createTemplate({
					templatePaths: sourceChangeRules,
					templateName: sourceDetails?.template?.templateName,
					templateDescription: sourceDetails?.template?.templateDescription,
				});
				if (!sourceDetails.template) {
					sourceDetails.template = createSrcTempResp;
				}
				sourceDetails.template.templateId = createSrcTempResp.templateId;
			} else {
				await appendSourceTemplate({
					templatePaths: sourceChangeRules,
				});
			}

			if (isSameTemplate) {
				destDetails.template = sourceDetails.template;
			} else if (!destDetails.template?.templateId) {
				let createDstTempResp = await createTemplate({
					templatePaths: destinationChangeRules,
					templateName: destDetails?.template?.templateName,
					templateDescription: destDetails?.template?.templateDescription,
				});
				if (!destDetails.template) {
					destDetails.template = createDstTempResp;
				}
				destDetails.template.templateId = createDstTempResp.templateId;
			} else {
				if (isSameTemplate) {
					await appendSourceTemplate({
						templatePaths: destinationChangeRules,
					});
				} else {
					await appendDestTemplate({
						templatePaths: destinationChangeRules,
					});
				}
			}

			if (sourceDetails?.template?.templateId) {
				if (!editSourceTagPolicy) {
					throw new Error(
						window.getCTTranslatedText("Missing sourced tag  edit api")
					);
				}
				await editSourceTagPolicy?.({
					templates: [sourceDetails?.template?.templateId],
				});
			}

			if (
				isSameTemplate &&
				sourceDetails?.tagBasedPolicyId === destDetails.tagBasedPolicyId
			) {
				// do nothing
			} else if (destDetails?.template?.templateId) {
				if (!editDestTagPolicy) {
					throw new Error(
						window.getCTTranslatedText("Missing dest tag edit api")
					);
				}
				await editDestTagPolicy?.({
					templates: [destDetails?.template?.templateId],
				});
			}
		}

		async function run() {
			if (runningRef.current) {
				return;
			}
			try {
				setRunning(true);
				await runImpl();
				onCloseRef.current();
				setRunning(false);
			} catch (e) {
				setRunning(false);
				console.error(e);
				onErrorRef.current(e);
			}
			runningRef.current = false;
		}

		run();
	}, [
		execute,
		createTemplate,
		destDetails,
		destDetails?.tagBasedPolicyId,
		destDetails?.template,
		editDestTagPolicy,
		editSourceTagPolicy,
		sourceDetails,
		sourceDetails?.tagBasedPolicyId,
		sourceDetails?.template,
		rules,
		appendSourceTemplate,
		appendDestTemplate,
	]);

	const onSubmit = async () => {
		if (!sourceDetails?.criteria?.size) {
			throw new Error(window.getCTTranslatedText("Source criteria missing"));
		}

		if (!destDetails?.criteria?.size) {
			throw new Error(
				window.getCTTranslatedText("Destination criteria missing")
			);
		}

		if (!metadata) {
			throw new Error(window.getCTTranslatedText("Metadata missing"));
		}

		setRunning(true);

		let isSamePolicy = isEqual(sourceDetails?.criteria, destDetails?.criteria);
		const sourceTagSetId = await getOrCreateTagSetId({
			data: sourceDetails,
			mutation: createTagPolicyAPI.mutateAsync,
			metadata,
		});

		let destTagSetId: string | undefined;
		if (isSamePolicy) {
			destTagSetId = sourceTagSetId;
		} else {
			destTagSetId = await getOrCreateTagSetId({
				data: destDetails,
				mutation: createTagPolicyAPI.mutateAsync,
				metadata,
			});
		}

		setSourceDetails(old => {
			let newDetails = { ...old };
			newDetails.tagBasedPolicyId = sourceTagSetId;
			return newDetails;
		});

		setDestDetails(old => {
			let newDetails = { ...old };
			newDetails.tagBasedPolicyId = destTagSetId;
			return newDetails;
		});

		setExecute(true);
	};

	return {
		onSubmit: metadata ? onSubmit : undefined,
		isRunning,
		onUpdateSourcePolicy,
		onUpdateDestinationPolicy,
		sourceDetails,
		destDetails,
	};
}

async function getOrCreateTagSetId({
	data,
	mutation,
	metadata,
}: {
	data: TagBasedPolicyCreationInput;
	mutation: UseMutateAsyncFunction<
		TagPolicy,
		Error,
		Omit<TagPolicy, "tagBasedPolicyId">,
		any
	>;
	metadata: ScopeMetadata;
}) {
	const name = getSegmentsNameFromCriteria(data.criteria, metadata);
	const execute = async () => {
		let resp = await mutation({
			criteria: FacetUtils.getServerQueryLanguageFromFacets(
				data.criteria,
				metadata
			),
			criteriaAsParams: `filters=${FacetUtils.getURLQueryFromFacets(
				data.criteria
			)}`,
			tagBasedPolicyName: name,
		});
		return resp.tagBasedPolicyId;
	};

	if (data?.tagBasedPolicyId) {
		return data?.tagBasedPolicyId;
	}
	return await execute();
}
