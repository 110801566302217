import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-pro";
import { useDemoController } from "hooks/useDemoController";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { useUnmanagedDevicesStore } from "pages/appliance-detail/store";
import { BooleanFlags } from "pages/appliances/components/appliance-form-drawer/components/appliance-config-form/types";
import { useApplianceConfigGetAPI } from "pages/appliances/components/appliance-form-drawer/hooks";
import { useCallback, useMemo, useState } from "react";
import { UnmanagedDevice } from "../appliance-unmanaged-devices/types";
import { UnmanagedDeviceToolbar } from "./components/UnmanagedDeviceToolbar";
import { DEVICE_COLUMNS } from "./constants";

export const UnmanagedDevicesDataGrid = (
	props: DataGridProps<UnmanagedDevice>
) => {
	const [selection, setSelection] = useState<Array<GridRowId>>([]);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const deviceColumns: GridColDef[] = DEVICE_COLUMNS(props.viewOnly ?? false);
	const requestRefresh = useUnmanagedDevicesStore(
		state => state.requestAPIRefresh
	);

	const { isDemo, handleDemoClick } = useDemoController();

	const selectedData: Array<UnmanagedDevice> | undefined = useMemo(() => {
		return props.rows?.filter((row: UnmanagedDevice) => {
			return selection.indexOf(row.deviceId) !== -1;
		});
	}, [selection, props.rows]);

	const selectedRawData: Array<UnmanagedDevice> | undefined = useMemo(() => {
		return (props?.rawData ?? [])?.filter((row: UnmanagedDevice) => {
			return selection.indexOf(row.deviceId) !== -1;
		});
	}, [selection, props?.rawData]);

	let hasVulnerabilities = useMemo(() => {
		return props.rows?.some((row: UnmanagedDevice) => {
			return Boolean(
				row.deviceVulnerabilities !== null &&
					row.deviceVulnerabilities !== undefined
			);
		});
	}, [props.rows]);
	let hasVulnerabilitySeverity = useMemo(() => {
		return props.rows?.some((row: UnmanagedDevice) => {
			return Boolean(
				row.deviceVulnerabilitySeverity !== null &&
					row.deviceVulnerabilitySeverity !== undefined &&
					row.deviceVulnerabilitySeverity !== "no value"
			);
		});
	}, [props.rows]);
	let hasLateralVulnerabilities = useMemo(() => {
		return props.rows?.some((row: UnmanagedDevice) => {
			return Boolean(
				row.deviceLateralMovementVulnerability !== null &&
					row.deviceLateralMovementVulnerability !== undefined &&
					row.deviceLateralMovementVulnerability !== "no value"
			);
		});
	}, [props.rows]);
	let hasKnownExploits = useMemo(() => {
		return props.rows?.some((row: UnmanagedDevice) => {
			return Boolean(
				row.deviceKnownExploitVulnerability !== null &&
					row.deviceKnownExploitVulnerability !== undefined &&
					row.deviceKnownExploitVulnerability !== "no value"
			);
		});
	}, [props.rows]);

	const columns = useMemo(
		() =>
			deviceColumns.filter(column => {
				if (column.field === "deviceVulnerabilities") {
					return hasVulnerabilities;
				}
				if (column.field === "deviceVulnerabilitySeverity") {
					return hasVulnerabilitySeverity;
				}
				if (column.field === "deviceLateralMovementVulnerability") {
					return hasLateralVulnerabilities;
				}
				if (column.field === "deviceKnownExploitVulnerability") {
					return hasKnownExploits;
				}

				return true;
			}),
		[
			hasVulnerabilitySeverity,
			hasVulnerabilities,
			hasLateralVulnerabilities,
			hasKnownExploits,
			deviceColumns,
		]
	);

	const { data: config } = useApplianceConfigGetAPI(props.applianceId);

	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<UnmanagedDeviceToolbar
				selectedData={selectedData ?? []}
				show={true}
				requestAPIRefresh={requestRefresh}
				disableActions={
					config?.gatekeeperConfig?.dhcp?.optInSupport === BooleanFlags.False
				}
				hiddenColumns={children}
			/>
		),
		[config?.gatekeeperConfig?.dhcp?.optInSupport, requestRefresh, selectedData]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					slots={{
						noRowsOverlay: () =>
							DevicesNoRowsOverlayComp({ isDemo, handleDemoClick, props }),
					}}
					checkboxSelection={userPermissions.has("UPDATE_UNMANAGED_TO_MANAGED")}
					rowSelectionModel={selection}
					onRowSelectionModelChange={selectionModel => {
						setSelection(selectionModel);
					}}
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ deviceId }: UnmanagedDevice) => deviceId}
					paginationMode="server"
					sortingMode="server"
					{...props}
					selectedRawData={selectedRawData}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};

interface DevicesNoRowsOverlayCompProps {
	isDemo?: boolean;
	handleDemoClick: () => Promise<void>;
	props: DataGridProps<UnmanagedDevice>;
}

const DevicesNoRowsOverlayComp = ({
	isDemo,
	handleDemoClick,
	props,
}: DevicesNoRowsOverlayCompProps) => {
	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				zIndex: 100,
				width: "100%",
				height: "100%",
				position: "relative",
			}}
		>
			{isDemo && <CircularProgress sx={{ mb: 2 }} size={36} />}
			<Typography variant="body2">
				{window.getCTTranslatedText(
					isDemo ? "Preparing demo data" : "No results"
				)}
			</Typography>
			{!isDemo && props.hasNoData && (
				<Button onClick={handleDemoClick}>
					{window.getCTTranslatedText("Try demo data")}
				</Button>
			)}
		</Stack>
	);
};
