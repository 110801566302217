import { Box, Stack } from "@mui/material";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { DataGridProps } from "modules/data-grid/components/data-grid/types";
import { UserToolBar } from "pages/users-segmentation/components/user-data-grid-toolbar";
import { User } from "pages/users-segmentation/components/users/types";
import { useCallback } from "react";
import { BASE_USERS_COLUMNS } from "./constants";

export const UserDataGrid = (props: DataGridProps<User>) => {
	const renderToolbar = useCallback(
		(children?: React.ReactNode) => (
			<UserToolBar show={true} hiddenColumns={children} />
		),
		[]
	);

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={BASE_USERS_COLUMNS}
					pagination={true}
					getRowId={(user: User) => `${user.userID}`}
					paginationMode="server"
					sortingMode="server"
					{...props}
					renderToolbar={renderToolbar}
				/>
			</Box>
		</Stack>
	);
};
