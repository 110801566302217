import { GridRowId } from "@mui/x-data-grid-pro";
import { Asset } from "pages/assets/types";

export interface AgentColumnRenderProps {
	agent: Agent;
	asset?: Asset;
	viewOnly?: boolean;
}

export enum AgentCommands {
	RESTART = "RESTART",
	UPGRADE = "UPGRADE",
	COLLECT_DIAGNOSTICS = "COLLECT_DIAGNOSTICS",
	POLICY_CHANGE = "POLICY_CHANGE",
	DECOMMISSION = "DECOMMISSION",
	ENABLE_DEBUG_LOG = "ENABLE_DEBUG_LOG",
	REBOOT = "REBOOT",
	RESTART_DHCP = "RESTART_DHCP",
	RESTART_HASYNC = "RESTART_HASYNC",
	POLICY_TAMPER_MONITOR = "POLICY_TAMPER_MONITOR",
	COEXISTENCE_CONFIG = "COEXISTENCE_CONFIG",
}

export enum AgentStatusType {
	Active = "active",
	Absent = "absent",
	Unknown = "unknown",
}

export enum NsTrafficStatusType {
	Enabled = "enabled",
	Disabled = "disabled",
}

export enum AgentType {
	CT_AGENT = "CT_AGENT",
	CT_LEGACY_AGENT = "CT_LEGACY_AGENT",
	CT_CONTAINER_AGENT = "CT_CONTAINER_AGENT",
}

export interface PendingCommands {
	action: AgentCommands;
}

export interface Agent {
	agentId: string;
	agentType: string;
	agentStatus: string;
	currentVersion: string;
	platform: string;
	architecture: string;
	lastCheckIn: string;
	asset: Asset;
	isRowSelectable?: boolean;
	autoUpgradeEnabled?: boolean;
	debugLogState: string;
	currentTrafficConfiguration: string;
	northSouthTrafficConfiguration: string;
	agentUpgradeAvailable?: boolean;
	agentUpgradeStatus?: string;
	agentRestartStatus?: string;
	agentDiagnosticsStatus?: string;
	agentDecommissionStatus?: string;
	agentDebugLogStatus?: string;
	cpuUtilization?: number;
	memoryUtilization?: number;
	cpuUtilizationStr?: string;
	memoryUtilizationStr?: string;
	agentCreated?: string;
	rowId?: GridRowId;
	isUpgradeEnabled?: boolean;
	isRestartEnabled?: boolean;
	isDecommissionEnabled?: boolean;
	isDecommissionSupported?: boolean;
	isDiagnosticsRunning?: boolean;
	isDiagnosticsSupported?: boolean;
	isDebugLogEnabled?: boolean;
	isDebugLogRunning?: boolean;
	isDebugLogSupported?: boolean;
	isTrafficConfigurationSupported?: boolean;
	isNorthSouthTrafficConfigurationSupported?: boolean;
	pendingCommands?: Array<PendingCommands>;
	assetType?: string;
	currentPolicyTamperMonitoringConfiguration?: string;
	isPolicyTamperingEnabled?: boolean;
	isPolicyTamperingRunning?: boolean;
	isPolicyTamperingSupported?: boolean;

	fwCoexistenceConfiguration?: string;
	isFirewallCoexistenceEnabled?: boolean;
	isFirewallCoexistenceRunning?: boolean;
	isFirewallCoexistenceSupported?: boolean;
}

interface Log {
	name: string;
	lastModified: string;
	sizeInBytes: number;
}

export interface Logs {
	items: Log[];
}

interface Diagnostic {
	name: string;
	lastModified: string;
	sizeInBytes: number;
}

export interface Diagnostics {
	items: Diagnostic[];
}

export interface DiagnosticOption {
	value: string;
	label: string;
	id: string;
	fileSize: string;
}

export type DiagnosticOptions = DiagnosticOption[] | undefined;
