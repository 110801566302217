import { Grid, Stack, Typography } from "@mui/material";
import { CircularCenteredLoader } from "common/atoms/loader";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { useCore } from "modules/core";
import { Scope } from "modules/scope-metadata/types";
import { useEffect, useMemo, useState } from "react";
import Listing from "./Listing";
import DisabledTooltipButton from "./components/DisabledTooltipButton";
import {
	FIREWALL_HOST_GROUPS_FACETS,
	FIREWALL_HOST_GROUPS_PERMISSION,
} from "./constants";
import useGetFirewallHostGroups from "./hooks/useGetFirewallHostGroups";

import {
	useFirewallHostGroupFacetStore,
	useFirewallHostGroupStore,
} from "./store";
import { FirewallHostGroupListItem } from "./types";

const Empty = () => {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const isCreateDisabled = !userPermissions.has(
		FIREWALL_HOST_GROUPS_PERMISSION.CREATE
	);

	return (
		<Grid item xs={12}>
			<Stack
				direction="column"
				alignItems="center"
				justifyContent="center"
				gap={4}
				height={"100%"}
				sx={{ pb: 8 }}
			>
				<Typography variant="h6" textAlign="center">
					{window.getCTTranslatedText("No Firewall Host Groups created")}
					<br />
					{window.getCTTranslatedText("CreateFirewallHostGroupPrompt")}
				</Typography>
				<DisabledTooltipButton
					variant="contained"
					link={"./create-firewall-host-group"}
					text="Create Firewall Host Group"
					disabled={isCreateDisabled}
				/>
			</Stack>
		</Grid>
	);
};

export function FirewallHostGroups() {
	const [hasListingRendered, setHasListingRendered] = useState(false);
	const coreResponse = useCore<FirewallHostGroupListItem>({
		useStore: useFirewallHostGroupStore,
		facetGroupInfo: FIREWALL_HOST_GROUPS_FACETS,
		scope: Scope.CrowdStrikeFirewallHostGroup,
		defaultSortOrder: [{ field: "precedence", order: "asc" }],
		useApi: useGetFirewallHostGroups,
		useFacetStore: useFirewallHostGroupFacetStore,
		pageSize: 100,
	});

	const isLoading = useMemo(
		() => coreResponse.mutation.isLoading || coreResponse.rows === undefined,
		[coreResponse.rows, coreResponse.mutation]
	);

	const isEmpty = useMemo(
		() => Array.isArray(coreResponse.rows) && !coreResponse.rows.length,
		[coreResponse.rows]
	);

	useEffect(() => {
		if (!hasListingRendered && !isLoading && !isEmpty) {
			setHasListingRendered(true);
		}
	}, [isLoading, isEmpty, hasListingRendered]);

	if (isLoading && !hasListingRendered) {
		return <CircularCenteredLoader />;
	}
	if (isEmpty && !hasListingRendered) {
		return <Empty />;
	}
	return <Listing coreResponse={coreResponse} />;
}
