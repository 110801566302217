import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	Paper,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { AnalyticsAPIReq, AnalyticsResponse } from "common/types/types";

import { getSegmentsNameFromCriteria } from "common/utils/getSegmentsNameFromCriteria";
import debounce from "lodash/debounce";
import { useCore } from "modules/core";
import { useCriteriaBuilder } from "modules/core/Core";
import {
	useFindTagBasedPolicyId,
	useTBPCriteriaBuilder,
} from "modules/create-tag-based-policy-drawer/hooks";
import {
	FacetOptionState,
	FacetState,
	FacetStore,
	Operator,
} from "modules/facets/types";
import { useScopeMetadata } from "modules/scope-metadata";
import { Scope, ScopeMetadata } from "modules/scope-metadata/types";
import { useCoreTagsFacetOrder } from "pages/create-tag-policy/CreateTagPolicy";
import { PolicySelectionForm } from "pages/create-tag-policy/components/PolicySelectionForm";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { useAssignedTemplatesTags } from "pages/tags/components/tag-policy-list/components/tag-templates/components/assigned-templates-tags/hooks";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { PortFormInt } from "pages/templates/components/template-form-drawer/components/template-form/types";
import { Template } from "pages/templates/types";
import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { StoreApi, UseBoundStore } from "zustand";
import { CreateTemplateDrawer } from "../create-template-drawer";

export enum PathAssetType {
	Source = 1,
	Destination = 2,
}

export interface TagBasedPolicyCreationInput {
	tagBasedPolicyId?: string;
	criteria?: FacetState;
	template?: Partial<Template>;
	totalAssets?: number;
}

export type CoreTags = { [key: string]: string };
type StoreType = UseBoundStore<StoreApi<FacetStore>>;
interface SourceAndDestinationFormProps {
	assetType?: PathAssetType;
	onChange: (policyCreation: TagBasedPolicyCreationInput) => void;
	store: StoreType;
	oppositeStore: StoreType;
	defaultTagBasedPolicyId?: string;
	rules?: PortFormInt[];
	isRingFence?: boolean;
}

const useTemplatesAPI = () => {
	return useMutation<any, Error, any>([
		"policy",
		"templates/actions/search?computeTotal=true",
	]);
};

export const SourceAndDestinationForm = ({
	assetType,
	onChange,
	store,
	oppositeStore,
	defaultTagBasedPolicyId,
	rules,
	isRingFence,
}: SourceAndDestinationFormProps) => {
	const [tagBasedPolicyId, setTagBasedPolicyId] = useState<string | undefined>(
		defaultTagBasedPolicyId
	);
	const [totalAssets, setTotalAssets] = useState<number>(0);
	const [template, setSelectedTemplate] = useState<Template | undefined>();

	const facets = store(state => state.facets);
	const oppositeFacets = oppositeStore(state => state.facets);

	useEffect(() => {
		if (!facets?.size) {
			setTagBasedPolicyId(undefined);
			setSelectedTemplate(undefined);
		}
	}, [facets]);

	useEffect(() => {
		if (isRingFence) {
			return;
		}
		if (!oppositeFacets?.size) {
			setShowAdvanced(false);
		} else {
			setShowAdvanced(true);
		}
	}, [oppositeFacets, isRingFence]);

	useEffect(() => {
		if (!facets?.size) {
			return onChange({ totalAssets: 0 });
		}
		onChange({
			tagBasedPolicyId,
			criteria: facets,
			template: template,
			totalAssets,
		});
	}, [facets, tagBasedPolicyId, totalAssets, template, onChange]);

	const metadata = useScopeMetadata({ scope: Scope.TagPolicy });
	const { exhaustiveCriteria: exhaustiveTBPCriteria } = useTBPCriteriaBuilder(
		facets,
		metadata.data
	);
	const aggregateCriteria = useCriteriaBuilder("", facets, metadata.data);

	const [showAdvanced, setShowAdvanced] = useState(true);

	const typeText = () => {
		if (assetType === PathAssetType.Source) {
			return "Source";
		}
		if (assetType === PathAssetType.Destination) {
			return "Destination";
		}
		return "";
	};
	const autoSuggestSegmentName = getSegmentsNameFromCriteria(
		facets,
		metadata.data
	);

	return (
		<Stack
			width="100%"
			spacing={2}
			component={Paper}
			variant="outlined"
			sx={{ p: 4 }}
		>
			<TagSelection
				viewOnly={Boolean(defaultTagBasedPolicyId)}
				title={typeText()}
				criteria={exhaustiveTBPCriteria}
				aggregateCriteria={aggregateCriteria}
				onFindTagBasedPolicyId={setTagBasedPolicyId}
				store={store}
				onUpdateTotal={setTotalAssets}
			/>

			{Boolean(facets?.size) ? (
				<>
					<TemplateSelection
						onChangeSelectedTemplate={setSelectedTemplate}
						showSelection={showAdvanced}
						tagBasedPolicyId={tagBasedPolicyId}
						autoSuggestSegmentName={autoSuggestSegmentName}
					/>
				</>
			) : (
				<EmptyPlaceholder />
			)}
		</Stack>
	);
};

function EmptyPlaceholder() {
	return (
		<Stack alignItems={"center"} justifyContent={"center"} flex={1}>
			<Typography variant="body2">
				{window.getCTTranslatedText("Select criteria to start creating", {
					type: window.getCTTranslatedText("segments"),
				})}
			</Typography>
		</Stack>
	);
}

export function tagsToFacetState(
	coreTags: CoreTags,
	metadata: ScopeMetadata
): FacetState {
	let map: FacetState = new Map();
	if (!coreTags || !metadata) {
		return map;
	}
	Object.entries(coreTags).forEach(current => {
		let key =
			metadata.namesToColumn[current[0]]?.internalName ??
			metadata.columns[current[0]]?.internalName ??
			current[0];
		let value = current[1];

		let options: FacetOptionState = new Map();
		options.set(value, {
			isSelected: true,
			operator: Operator.EQUAL,
		});
		map?.set(key, options);
	});
	return map;
}

function Header({ title }: { title: string }) {
	return (
		<Stack direction="row" width={"100%"}>
			<Typography variant="caption">
				{window.getCTTranslatedText(title)}
			</Typography>
		</Stack>
	);
}

export function useAssetAggregateAPI() {
	return useMutation<AnalyticsResponse, Error, AnalyticsAPIReq>([
		"asset-aggregate",
		"aggregate",
	]);
}

function TagSelection({
	title,
	criteria,
	onFindTagBasedPolicyId,
	store,
	viewOnly,
	aggregateCriteria,
	onUpdateTotal,
}: {
	title: string;
	viewOnly: boolean;
	criteria: string | undefined;
	aggregateCriteria: string | undefined;
	onFindTagBasedPolicyId: (id: string) => void;
	store: StoreType;
	onUpdateTotal: (total: number) => void;
}) {
	useFindTagBasedPolicyId({
		criteria,
		onFindTagBasedPolicyId,
	});

	const facetOrder = useCoreTagsFacetOrder();

	const useTagPolicyFacetStore = store;
	const coreResponse = useCore<Scope.TagPolicy>({
		useStore: useTagPolicyStore,
		facetGroupInfo: facetOrder,
		scope: Scope.TagPolicy,
		useFacetStore: useTagPolicyFacetStore,
		skipUseFacetQueryConnector: true,
		useApi: useTagPolicyListAPI,
		pageSize: 0,
	});

	return (
		<Box sx={{ position: "relative" }}>
			<PolicySelectionForm
				header={
					<Stack>
						<Header title={title} />
						<MatchedAssetsCount
							criteria={aggregateCriteria}
							onUpdateTotal={onUpdateTotal}
						/>
					</Stack>
				}
				coreResponse={coreResponse}
				useFacetStore={useTagPolicyFacetStore}
				sortDisplayByName={true}
				viewOnly={viewOnly}
				hideSavedQuery={true}
			/>
		</Box>
	);
}

function MatchedAssetsCount({
	criteria,
	onUpdateTotal,
}: {
	criteria: string | undefined;
	onUpdateTotal: (total: number) => void;
}) {
	const mutation = useAssetAggregateAPI();
	const mutate = mutation.mutate;
	const { data, isLoading, reset } = mutation;

	useEffect(() => {
		if (!criteria || criteria === "*") {
			reset();
			return;
		}
		mutate({
			criteria,
			scope: Scope.Asset,
			statistics: ["count(assetid)"],
		});
	}, [mutate, reset, criteria]);

	const count: number = useMemo<number>(() => {
		return Number(data?.items?.statistics?.assetidcount ?? 0);
	}, [data]);

	useEffect(() => {
		onUpdateTotal(count);
	}, [onUpdateTotal, count]);

	if (!criteria || criteria === "*") {
		return null;
	}

	return (
		<>
			<Stack
				alignItems={"center"}
				justifyContent={"center"}
				sx={{ minHeight: 32 }}
			>
				{isLoading ? (
					<CircularProgress size={24} />
				) : (
					<Typography variant="h6">
						{window.getCTTranslatedText("matchingEntity", {
							count: Number(count ?? 0),
							entity: pluralize(
								window.getCTTranslatedText("asset"),
								Number(count ?? 0)
							),
						})}
					</Typography>
				)}
			</Stack>
		</>
	);
}

function TemplateSelection({
	onChangeSelectedTemplate,
	showSelection,
	tagBasedPolicyId,
	autoSuggestSegmentName,
}: {
	onChangeSelectedTemplate: (template: Template | undefined) => void;
	showSelection: boolean;
	tagBasedPolicyId?: string;
	autoSuggestSegmentName?: string;
}) {
	const [input, setInput] = useState("");
	const [error, setError] = useState(false);
	const emptyTemplatesAPI = useTemplatesAPI();
	const [createdSegments, setCreatedSegments] = useState<Template[]>([]);
	const { templateList: assignedTemplatesList, isLoading } =
		useAssignedTemplatesTags({
			policyId: tagBasedPolicyId ?? "",
		});
	const isAssignedTemplateLoading = tagBasedPolicyId ? isLoading : false;
	const [selectedTemplate, setSelectedTemplate] = useState<
		Template | undefined
	>();
	const [autoSuggestTemplate, setAutoSuggestTemplate] = useState(false);
	const [isEmptyTemplateLoading, setIsEmptyTemplateLoading] = useState(true);
	useEffect(() => {
		onChangeSelectedTemplate(selectedTemplate);
	}, [selectedTemplate, onChangeSelectedTemplate]);

	const mutate = useMemo(
		() => debounce(emptyTemplatesAPI.mutate, 300),
		[emptyTemplatesAPI.mutate]
	);

	useEffect(() => {
		if (input === autoSuggestSegmentName) {
			return;
		}
		setIsEmptyTemplateLoading(true);
		const additionalCriteria =
			"templateassignments=0 AND oobtemplate=false and templatepolicyassignments=0";
		mutate(
			{
				criteria: !input
					? additionalCriteria
					: `'${input}' AND ${additionalCriteria}`,
			},
			{ onSuccess: () => setIsEmptyTemplateLoading(false) }
		);
	}, [mutate, input, autoSuggestTemplate, autoSuggestSegmentName]);

	const emptyTemplatesList = emptyTemplatesAPI.data?.items as Template[];

	const { templateList: options, selectedTemplateFromList } = useMemo(() => {
		let newAssignedTemplates = assignedTemplatesList?.map(t => {
			return { ...t, existing: true };
		});
		let newEmptyTemplates = emptyTemplatesList?.map(t => {
			return { ...t, existing: false };
		});
		const templateList = [
			...(newAssignedTemplates ?? []),
			...(newEmptyTemplates ?? []),
			...(createdSegments ?? []),
		];

		const selectedTemplateFromList = templateList.find(t => {
			return t.templateName === selectedTemplate?.templateName;
		});
		if (selectedTemplate?.templateName && !selectedTemplateFromList) {
			templateList.push({ ...selectedTemplate, existing: true });
		}
		return { templateList, selectedTemplateFromList };
	}, [
		assignedTemplatesList,
		emptyTemplatesList,
		selectedTemplate,
		createdSegments,
	]);

	useEffect(() => {
		if (
			isEmptyTemplateLoading ||
			isAssignedTemplateLoading ||
			selectedTemplate?.templateName
		) {
			return;
		}

		if (assignedTemplatesList?.length || emptyTemplatesList?.length) {
			setAutoSuggestTemplate(false);
		} else {
			setAutoSuggestTemplate(true);
		}
	}, [
		assignedTemplatesList,
		emptyTemplatesList,
		isAssignedTemplateLoading,
		isEmptyTemplateLoading,
		selectedTemplate?.templateName,
	]);

	const addCreatedSegment = (val: Template) => {
		setCreatedSegments((prev: Template[]): Template[] => {
			return [...prev, val];
		});
	};

	useEffect(() => {
		if (selectedTemplateFromList && !selectedTemplate?.templateId) {
			setSelectedTemplate(selectedTemplateFromList);
		}
	}, [selectedTemplateFromList, selectedTemplate]);

	const [open, setOpen] = useState(false);

	if (!showSelection) {
		return null;
	}

	const onChange = (event: any, value: any) => {
		if (event?.target?.textContent || value.templateName) {
			const newValue = value.templateName ?? event?.target?.textContent;
			setSelectedTemplate(options.find(t => t.templateName === newValue));
			setError(false);
		} else {
			setSelectedTemplate(undefined);
			setError(true);
		}
	};

	const clearError = () => {
		setError(false);
	};

	return (
		<>
			<FormControl
				sx={{ m: 0, width: "100%", textTransform: "capitalize" }}
				size="small"
			>
				<Autocomplete
					freeSolo
					disableClearable
					openOnFocus
					autoHighlight
					options={options.map(v => {
						return {
							...v,
							id: selectedTemplate?.templateName,
							label: v.templateName,
						};
					})}
					groupBy={option =>
						window.getCTTranslatedText(
							option.existing ? "Tagset templates" : "Unused templates"
						)
					}
					value={selectedTemplate?.templateName ?? ""}
					onChange={onChange}
					open={open}
					onOpen={() => {
						setOpen(true);
					}}
					onClose={() => {
						setOpen(false);
					}}
					onInputChange={(e, value) => {
						setInput(value);
					}}
					renderInput={params => (
						<TextField
							variant="outlined"
							{...params}
							label={window.getCTTranslatedText(`Pick a template`)}
							placeholder={window.getCTTranslatedText(`Pick a template`)}
							required
							helperText={
								error ? window.getCTTranslatedText("requiredField") : ""
							}
						/>
					)}
				/>
			</FormControl>

			<CreateTemplateDrawer
				clearError={clearError}
				setSelectedTemplate={setSelectedTemplate}
				autoSuggestTemplate={autoSuggestTemplate}
				autoSuggestSegmentName={autoSuggestSegmentName}
				addCreatedSegment={addCreatedSegment}
			/>
		</>
	);
}
