import { Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { MoreOptionsMenu } from "common/molecules/more-options-menu";
import {
	MenuItemOption,
	MenuOption,
} from "common/molecules/more-options-menu/types";
import { useFeatureFlagControl } from "hooks/useFeatureFlagControl";
import { FEATURES } from "hooks/useFeatureFlagControl/useFeatureFlagControl";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { useAgentStore } from "pages/agents/store";
import { Agent } from "pages/agents/types";
import { AgentBaseDrawer } from "pages/appliances/components/agent-base-drawer";
import { AGENT_BASE_DRAWER_ACTION_LABEL } from "pages/appliances/components/agent-base-drawer/constants";
import { DRAWER_TYPE } from "pages/appliances/components/agent-base-drawer/types";
import { AssetType } from "pages/assets/types";
import { useMemo, useState } from "react";
import { ROUTES } from "routes";
import * as semver from "semver";
import {
	AgentDebugLogSupportedMinVersion,
	AgentDecommissionSupportedMinVersion,
	AgentDiagnosticSupportedMinVersion,
	AgentPolicyTamperMonitoringSuppportedMinVersion,
	AgentPolicyTamperMonitoringSuppportedMinVersionDarwin,
	FirewallCoexistenceSupportedMinVersion,
	ServerAgentTrafficConfigSupportedMinVersion,
	UserAgentTrafficConfigSupportedMinVersion,
} from "../agent-data-grid/constants";
import { isDiagnosticsAllowed } from "../agent-data-grid/helpers/columnHelpers";
import { AgentDownload } from "../agent-downlaod/AgentDownload";
import { AgentUpgradeDrawer } from "../agent-drawers";
import {
	AGENT_UPGRADE_COLUMNS,
	DebugLogStatus,
	FirewallCoexistenceStatus,
	NsTrafficConfigStatus,
	PolicyTamperingStatus,
	TrafficConfigStatus,
	TrafficConfigStatusLabels,
} from "../agent-drawers/helpers/constants";
import {
	AutoUpgradeActions,
	RestartActions,
	UpgradeActions,
} from "./ToolbarActions";

function isString(value: any): value is string {
	return typeof value === "string";
}

export interface AgentToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<Agent> | undefined;
	selection: Array<GridRowId>;
	hideToolbar: Function;
	showPortStatusOption?: boolean;
	recommendationId?: string;
}
export enum AgentActionType {
	RESTART = "restart",
	UPGRADE = "upgrade",
	AUTOUPGRADE = "autoupgrade",
	DIAGNOSTIC = "diagnostic",
	DECOMMISSION = "decommission",
	DEBUGLOG = "debuglog",
	TRAFFIC_CONFIG = "trafficConfig",
	NS_TRAFFIC_CONFIG = "nsTrafficConfig",
	POLICY_TAMPERING = "policyTampering",
	FIREWALL_COEXISTENCE = "firewallCoexistence",
}
export default function AgentToolbar(props: AgentToolbarProps) {
	const route = ROUTES.INSTALL_AGENTS.pathname;
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);

	const { isFeatureEnabled: isTrafficConfigEnabled } = useFeatureFlagControl(
		FEATURES.TRAFFIC_CONFIG
	);
	const { isFeatureEnabled: isNsTrafficConfigEnabled } = useFeatureFlagControl(
		FEATURES.NS_TRAFFIC_CONFIG
	);
	const requestAPIRefresh = useAgentStore(store => store.requestAPIRefresh);

	const upgradeAgentPerm = userPermissions.has("UPGRADE_AGENT");
	const createDepKeyPerm = userPermissions.has("CREATE_DEPLOYMENT_KEY");

	const [openAgentUpgradeMenu, setAgentUpgradeMenuOpen] = useState(false);
	const [openAgentRestartMenu, setAgentRestartMenuOpen] = useState(false);
	const [openAutoUpgradeMenu, setAutoUpgradeMenuOpen] = useState(false);
	const [openAgentDiagnosticMenu, setAgentDiagnosticMenuOpen] = useState(false);
	const [openAgentDecommissionMenu, setAgentDecommissionMenuOpen] =
		useState(false);
	const [openAgentDebugLogMenu, setOpenAgentDebugLogMenu] = useState(false);
	const [openTrafficConfigMenu, setOpenTrafficConfigMenu] = useState(false);
	const [openNsTrafficConfigMenu, setOpenNsTrafficConfigMenu] = useState(false);
	const [autoUpgradeAction, setAutoUpgradeAction] = useState("");
	const [debugLogAction, setDebugLogAction] = useState("");
	const [policyTamperingAction, setPolicyTamperingAction] =
		useState<PolicyTamperingStatus>();
	const [firewallCoexistenceAction, setFirewallCoexistenceAction] =
		useState<FirewallCoexistenceStatus>();
	const [trafficConfigAction, setTrafficConfigAction] =
		useState<TrafficConfigStatus>();
	const [nsTrafficConfigAction, setNsTrafficConfigAction] =
		useState<NsTrafficConfigStatus>();
	const [openPolicyTamperingMenu, setOpenPolicyTamperingMenu] = useState(false);
	const [openFirewallCoexistenceMenu, setOpenFirewallCoexistenceMenu] =
		useState(false);

	const AgentNsTrafficConfigSupportedMinVersion = "24.6.0";

	const isUpgradeButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(obj => obj.isUpgradeEnabled);
	}, [props?.selectedData]);

	const isRestartButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(obj => obj.isRestartEnabled);
	}, [props?.selectedData]);

	const isDiagnosticsButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(
			obj => !obj.isDiagnosticsRunning && isDiagnosticsAllowed(obj)
		);
	}, [props?.selectedData]);

	const isDecommissionButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(
			obj => obj.isDecommissionEnabled && obj.isDecommissionSupported
		);
	}, [props?.selectedData]);

	const isDebugLogButtonDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(
			obj => !obj.isDebugLogRunning && obj.isDebugLogSupported
		);
	}, [props?.selectedData]);

	const isPolicyTamperingDisabled: boolean = useMemo(() => {
		return !props?.selectedData?.some(
			obj => !obj.isPolicyTamperingRunning && obj.isPolicyTamperingSupported
		);
	}, [props?.selectedData]);

	const isFirewallCoexistenceDisabled = useMemo(() => {
		return !props?.selectedData?.some(
			obj =>
				!obj.isFirewallCoexistenceRunning && obj.isFirewallCoexistenceSupported
		);
	}, [props?.selectedData]);

	const isTrafficConfigButtonDisabled = useMemo(() => {
		return !props?.selectedData?.some(
			obj => obj.isTrafficConfigurationSupported
		);
	}, [props?.selectedData]);

	const isNsTrafficConfigButtonDisabled = useMemo(() => {
		const isNsTrafficConfigSupported = (obj: Agent) => {
			return semver.gt(
				obj.currentVersion,
				AgentNsTrafficConfigSupportedMinVersion
			);
		};
		return !props?.selectedData?.some(
			obj =>
				obj.assetType === AssetType.Server && isNsTrafficConfigSupported(obj)
		);
	}, [props?.selectedData]);

	const onClose = () => {
		setAgentUpgradeMenuOpen(false);
		setAgentRestartMenuOpen(false);
		setAutoUpgradeMenuOpen(false);
		setAgentDiagnosticMenuOpen(false);
		setAgentDecommissionMenuOpen(false);
		setOpenAgentDebugLogMenu(false);
		setOpenTrafficConfigMenu(false);
		setOpenNsTrafficConfigMenu(false);
		setOpenPolicyTamperingMenu(false);
		setOpenFirewallCoexistenceMenu(false);
		props.hideToolbar();
		requestAPIRefresh();
	};

	const handleButtonChange = (btnAction: string, btnActionType: string) => {
		switch (btnAction) {
			case AgentActionType.UPGRADE:
				setAgentUpgradeMenuOpen(prevOpen => !prevOpen);
				break;
			case AgentActionType.RESTART:
				setAgentRestartMenuOpen(prevOpen => !prevOpen);
				break;
			case AgentActionType.DIAGNOSTIC:
				setAgentDiagnosticMenuOpen(prevOpen => !prevOpen);
				break;
			case AgentActionType.DECOMMISSION:
				setAgentDecommissionMenuOpen(prevOpen => !prevOpen);
				break;
			case AgentActionType.DEBUGLOG:
				if (isString(btnActionType)) {
					setOpenAgentDebugLogMenu(prevOpen => !prevOpen);
					setDebugLogAction(btnActionType);
				}
				break;
			case AgentActionType.TRAFFIC_CONFIG:
				setOpenTrafficConfigMenu(prevOpen => !prevOpen);
				setTrafficConfigAction(btnActionType as TrafficConfigStatus);
				break;

			case AgentActionType.NS_TRAFFIC_CONFIG:
				setOpenNsTrafficConfigMenu(prevOpen => !prevOpen);
				setNsTrafficConfigAction(btnActionType as NsTrafficConfigStatus);
				break;
			case AgentActionType.POLICY_TAMPERING:
				setOpenPolicyTamperingMenu(prev => !prev);
				setPolicyTamperingAction(btnActionType as PolicyTamperingStatus);
				break;
			case AgentActionType.FIREWALL_COEXISTENCE:
				setOpenFirewallCoexistenceMenu(prev => !prev);
				setFirewallCoexistenceAction(
					btnActionType as FirewallCoexistenceStatus
				);
				break;
			default:
				if (isString(btnActionType)) {
					setAutoUpgradeMenuOpen(prevOpen => !prevOpen);
					setAutoUpgradeAction(btnActionType);
				}
				break;
		}
	};

	const getDiagnosticsTooltipMessage = () => {
		return isDiagnosticsButtonDisabled
			? window.getCTTranslatedText(
					"agentDiagnosticSupportedMinVersionTooltipText",
					{
						AgentDiagnosticSupportedMinVersion,
					}
				)
			: "";
	};

	const getDebugLogTooltipMessage = () => {
		return isDebugLogButtonDisabled
			? window.getCTTranslatedText("agentDebugLogSupportedMinVersion", {
					AgentDebugLogSupportedMinVersion,
				})
			: "";
	};

	const getPolicyTamperingTooltipMessage = () => {
		return isPolicyTamperingDisabled
			? window.getCTTranslatedText("agentPolicyTampermonitoringTooltip", {
					AgentPolicyTamperMonitoringSuppportedMinVersion,
					AgentPolicyTamperMonitoringSuppportedMinVersionDarwin,
				})
			: "";
	};

	const getFirewallCoexistenceTooltipMessage = () => {
		const minVersion = FirewallCoexistenceSupportedMinVersion;
		return isFirewallCoexistenceDisabled
			? window.getCTTranslatedText("firewallCoexistenceTooltip", {
					minVersion: minVersion,
				})
			: "";
	};

	const getTrafficConfigTooltipMessage = () => {
		return isTrafficConfigButtonDisabled
			? window.getCTTranslatedText("trafficConfigTooltip", {
					UserAgentTrafficConfigSupportedMinVersion,
					ServerAgentTrafficConfigSupportedMinVersion,
				})
			: "";
	};

	const getNsTrafficConfigTooltipMessage = () => {
		return isNsTrafficConfigButtonDisabled
			? window.getCTTranslatedText("nsTrafficConfigTooltip", {
					AgentNsTrafficConfigSupportedMinVersion,
				})
			: "";
	};

	const getDecommissionTooltipMessage = () => {
		return isDecommissionButtonDisabled
			? window.getCTTranslatedText("agentDecommissionSupportedMinVersion", {
					AgentDecommissionSupportedMinVersion,
				})
			: "";
	};

	const moreActionMenuOptions: Array<MenuOption> = [
		{
			label: window.getCTTranslatedText("diagnosticsButton"),
			handler: () => handleButtonChange(AgentActionType.DIAGNOSTIC, ""),
			disabled: isDiagnosticsButtonDisabled,
			tooltipMessage: getDiagnosticsTooltipMessage(),
		},
	];

	if (upgradeAgentPerm) {
		const debugLogSubMenuOptions = [
			{
				label: window.getCTTranslatedText("Enable"),
				handler: () =>
					handleButtonChange(AgentActionType.DEBUGLOG, DebugLogStatus.Enable),
			},
			{
				label: window.getCTTranslatedText("Disable"),
				handler: () =>
					handleButtonChange(AgentActionType.DEBUGLOG, DebugLogStatus.Disable),
			},
		];

		moreActionMenuOptions.push({
			label: window.getCTTranslatedText("debugLog"),
			disabled: isDebugLogButtonDisabled,
			tooltipMessage: getDebugLogTooltipMessage(),
			type: "subMenu",
			subMenuOptions: debugLogSubMenuOptions,
		});
	}

	const policyTamperingSubMenuOption = [
		{
			label: window.getCTTranslatedText("Enable"),
			handler: () =>
				handleButtonChange(
					AgentActionType.POLICY_TAMPERING,
					PolicyTamperingStatus.Enable
				),
		},
		{
			label: window.getCTTranslatedText("Disable"),
			handler: () =>
				handleButtonChange(
					AgentActionType.POLICY_TAMPERING,
					PolicyTamperingStatus.Disable
				),
		},
	];

	const coexistenceSubMenuOption = [
		{
			label: window.getCTTranslatedText("Enable"),
			handler: () =>
				handleButtonChange(
					AgentActionType.FIREWALL_COEXISTENCE,
					FirewallCoexistenceStatus.Enable
				),
		},
		{
			label: window.getCTTranslatedText("Disable"),
			handler: () =>
				handleButtonChange(
					AgentActionType.FIREWALL_COEXISTENCE,
					FirewallCoexistenceStatus.Disable
				),
		},
	];

	moreActionMenuOptions.push({
		label: window.getCTTranslatedText("policyTampering"),
		disabled: isPolicyTamperingDisabled,
		tooltipMessage: getPolicyTamperingTooltipMessage(),
		type: "subMenu",
		subMenuOptions: policyTamperingSubMenuOption,
	});

	moreActionMenuOptions.push({
		label: window.getCTTranslatedText("firewallCoexistence"),
		disabled: isFirewallCoexistenceDisabled,
		tooltipMessage: getFirewallCoexistenceTooltipMessage(),
		type: "subMenu",
		subMenuOptions: coexistenceSubMenuOption,
	});

	if (upgradeAgentPerm && isTrafficConfigEnabled) {
		const trafficConfigSubMenuOptions: Array<MenuItemOption> = [
			{
				label: window.getCTTranslatedText("inboundTraffic"),
				handler: () =>
					handleButtonChange(
						AgentActionType.TRAFFIC_CONFIG,
						TrafficConfigStatus.EnableInboundOnly
					),
			},
			{
				label: window.getCTTranslatedText("outboundTraffic"),
				handler: () =>
					handleButtonChange(
						AgentActionType.TRAFFIC_CONFIG,
						TrafficConfigStatus.EnableOutboundOnly
					),
			},
			{
				label: window.getCTTranslatedText("bidirectionalTraffic"),
				handler: () =>
					handleButtonChange(
						AgentActionType.TRAFFIC_CONFIG,
						TrafficConfigStatus.EnableAll
					),
			},
			{
				label: window.getCTTranslatedText("disableAllTraffic"),
				handler: () =>
					handleButtonChange(
						AgentActionType.TRAFFIC_CONFIG,
						TrafficConfigStatus.DisableAll
					),
			},
		];

		moreActionMenuOptions.push({
			label: window.getCTTranslatedText("trafficConfig"),
			disabled: isTrafficConfigButtonDisabled,
			tooltipMessage: getTrafficConfigTooltipMessage(),
			type: "subMenu",
			subMenuOptions: trafficConfigSubMenuOptions,
		});
	}

	if (upgradeAgentPerm && isNsTrafficConfigEnabled) {
		const nsTrafficConfigSubMenuOptions: Array<MenuItemOption> = [
			{
				label: window.getCTTranslatedText("Enable"),
				handler: () =>
					handleButtonChange(
						AgentActionType.NS_TRAFFIC_CONFIG,
						NsTrafficConfigStatus.Enable
					),
			},
			{
				label: window.getCTTranslatedText("Disable"),
				handler: () =>
					handleButtonChange(
						AgentActionType.NS_TRAFFIC_CONFIG,
						NsTrafficConfigStatus.Disable
					),
			},
		];

		moreActionMenuOptions.push({
			label: window.getCTTranslatedText("nsTrafficConfig"),
			disabled: isNsTrafficConfigButtonDisabled,
			tooltipMessage: getNsTrafficConfigTooltipMessage(),
			type: "subMenu",
			subMenuOptions: nsTrafficConfigSubMenuOptions,
		});
	}

	if (upgradeAgentPerm) {
		moreActionMenuOptions.push({
			label: window.getCTTranslatedText("decommissionButton"),
			handler: () => handleButtonChange(AgentActionType.DECOMMISSION, ""),
			disabled: isDecommissionButtonDisabled,
			tooltipMessage: getDecommissionTooltipMessage(),
		});
	}

	return (
		<>
			{openAgentUpgradeMenu && (
				<AgentUpgradeDrawer
					isOpen={openAgentUpgradeMenu}
					onCancel={onClose}
					data={props?.selectedData}
					columns={AGENT_UPGRADE_COLUMNS}
					selection={props.selection}
				/>
			)}

			{openAgentRestartMenu && (
				<AgentBaseDrawer
					isOpen={openAgentRestartMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					drawerType={DRAWER_TYPE.AGENT_RESTART}
				/>
			)}

			{openAgentDiagnosticMenu && (
				<AgentBaseDrawer
					isOpen={openAgentDiagnosticMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					drawerType={DRAWER_TYPE.AGENT_CONNECT_DIAGNOSTIC}
				/>
			)}

			{openAgentDecommissionMenu && (
				<AgentBaseDrawer
					isOpen={openAgentDecommissionMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					drawerType={DRAWER_TYPE.AGENT_DECOMMISSION}
				/>
			)}

			{openAgentDebugLogMenu && (
				<AgentBaseDrawer
					isOpen={openAgentDebugLogMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					action={debugLogAction as DebugLogStatus}
					drawerType={DRAWER_TYPE.AGENT_DEBUG_LOG}
				/>
			)}

			{openPolicyTamperingMenu &&
				props?.selectedData &&
				policyTamperingAction && (
					<AgentBaseDrawer
						isOpen={openPolicyTamperingMenu}
						onCancel={onClose}
						data={props?.selectedData}
						selection={props?.selection}
						action={policyTamperingAction as PolicyTamperingStatus}
						drawerType={DRAWER_TYPE.AGENT_POLICY_TAMPERING}
						subTitle={`: ${AGENT_BASE_DRAWER_ACTION_LABEL[policyTamperingAction]}`}
					/>
				)}

			{openFirewallCoexistenceMenu &&
				props?.selectedData &&
				firewallCoexistenceAction && (
					<AgentBaseDrawer
						isOpen={openFirewallCoexistenceMenu}
						onCancel={onClose}
						data={props?.selectedData}
						selection={props?.selection}
						action={firewallCoexistenceAction}
						drawerType={DRAWER_TYPE.FIREWALL_COEXISTENCE}
						subTitle={`: ${AGENT_BASE_DRAWER_ACTION_LABEL[firewallCoexistenceAction]}`}
					/>
				)}

			{openTrafficConfigMenu && props?.selectedData && trafficConfigAction && (
				<AgentBaseDrawer
					isOpen={openTrafficConfigMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props?.selection}
					action={trafficConfigAction}
					drawerType={DRAWER_TYPE.AGENT_TRAFFIC_CONFIG}
					subTitle={`: ${window.getCTTranslatedText(
						TrafficConfigStatusLabels[trafficConfigAction]
					)}`}
				/>
			)}

			{openNsTrafficConfigMenu &&
				props?.selectedData &&
				nsTrafficConfigAction && (
					<AgentBaseDrawer
						isOpen={openNsTrafficConfigMenu}
						onCancel={onClose}
						data={props?.selectedData}
						selection={props?.selection}
						action={nsTrafficConfigAction}
						drawerType={DRAWER_TYPE.AGENT_TRAFFIC_CONFIG_NORTH_SOUTH}
						subTitle={`: ${AGENT_BASE_DRAWER_ACTION_LABEL[nsTrafficConfigAction]}`}
					/>
				)}

			{openAutoUpgradeMenu && (
				<AgentBaseDrawer
					isOpen={openAutoUpgradeMenu}
					onCancel={onClose}
					data={props?.selectedData}
					selection={props.selection}
					action={autoUpgradeAction}
					drawerType={DRAWER_TYPE.AGENT_AUTO_UPGRADE}
				/>
			)}

			{(upgradeAgentPerm || createDepKeyPerm) && (
				<GridToolbar {...props} requestAPIRefresh={requestAPIRefresh}>
					<Stack
						direction="row"
						spacing={3}
						sx={{ width: "100%" }}
						justifyItems="flex-end"
						alignItems={"flex-end"}
						justifyContent="flex-end"
					>
						{props?.selection.length > 0 && upgradeAgentPerm && (
							<>
								<RestartActions
									{...{ isRestartButtonDisabled, handleButtonChange }}
								/>
								<UpgradeActions
									{...{ isUpgradeButtonDisabled, handleButtonChange }}
								/>
								<AutoUpgradeActions {...{ handleButtonChange }} />
								<MoreOptionsMenu
									menuOptions={moreActionMenuOptions}
									toolTipPlacement={"left"}
								/>
							</>
						)}

						{createDepKeyPerm &&
							!openAgentUpgradeMenu &&
							props?.selection.length === 0 && <AgentDownload route={route} />}
					</Stack>
				</GridToolbar>
			)}
		</>
	);
}
