import { CustomCommonStoreType } from "common/types/types";
import { Asset } from "pages/assets/types";
import { Network } from "pages/networks/types";
import { PortStatus } from "pages/ports/types";
import { TagBasedPolicy } from "pages/tags/components/tag-policy-list/types";
import { TemplatePath, TemplatePort } from "pages/templates/types";

export interface PathColumnRenderProps {
	path: Path;
	viewOnly?: boolean;
	asLink?: boolean;
	useFacetStore?: CustomCommonStoreType;
}

export enum PathDirection {
	Inbound = "inbound",
	Outbound = "outbound",
}

export interface MatchedByTemplates {
	templateId: string;
	templateName?: string;
	templatePorts?: TemplatePort[];
	templatePaths?: TemplatePath[];
}

export enum Protocol {
	"ANY" = "ANY",
	"ICMP" = "ICMP",
	"IGMP" = "IGMP",
	"TCP" = "TCP",
	"UDP" = "UDP",
	"IPV6-ICMP" = "IPV6-ICMP",
}

export const PORTLESS_PROTOCOLS: (Protocol | string)[] = [
	Protocol.ANY,
	Protocol.ICMP,
	Protocol.IGMP,
	Protocol["IPV6-ICMP"],
];

export interface Path {
	channelHash: string;
	protocol: Protocol;
	port: string;
	portName: string;
	srcIp: string;
	sourceAsset?: Asset;
	destinationAsset?: Asset;
	domain: string;
	dstIp: string[];
	bandwidthInBytes: number | string;
	pathLastObserved: string;
	pathLastObservedAbsolute: string;
	connectionCount: number;
	reviewed: PathDraftConfigStatus;
	enforced: PathEnforcedConfigStatus;
	method?: string;
	uri?: string;
	direction: PathDirection;
	listenPortReviewed: PortStatus;
	sourceNamedNetwork: NamedNetwork | null;
	sourceTagBasedPolicy: TagBasedPolicy | null;
	destinationNamedNetwork: NamedNetwork | null;
	destinationTagBasedPolicy: TagBasedPolicy | null;
	isRowSelectable?: boolean;
	compassDirection: CompassDirection;
	firewallDenyCount?: number;
	firewallAllowCount?: number;
	firewallLastReported: string;
	totalComments: number;
	rawBandwidthInBytes: string | number;
	rawPathLastObserved: string;
	matchedByTemplates: MatchedByTemplates[];
	matchedByTemplatesList: string;
	process: string;
	destinationProcess?: string;
}

export enum CompassDirection {
	NorthSouth = "north-south",
	EastWest = "east-west",
}
export interface NamedNetwork
	extends Pick<Network, "namedNetworkId" | "namedNetworkName"> {}

export enum PathStatus {
	Unreviewed = "unreviewed",
	Allow = "allowed",
	Deny = "denied",
	AllowTestDenied = "allowed-test-denied",
	AllowTestDeniedViolation = "allowed-test-denied-violation",
	DeniedByTemplate = "denied-by-template",
	AllowedByTemplate = "allowed-by-template",
	AllowedByProgressive = "allowed-by-progressive",
	AllowedByTestUIOnly = "allowed-by-test",
}

export enum PathDraftConfigStatus {
	Unreviewed = "unreviewed",
	Allow = "allowed",
	Deny = "denied",
	AllowedByTemplate = "allowed-by-template",
	DeniedByTemplate = "denied-by-template",
	AllowedByProgressive = "allowed-by-progressive",
}

export enum PathEnforcedConfigStatus {
	Unreviewed = "unreviewed",
	Allow = "allowed",
	Deny = "denied",
	AllowTestDenied = "allowed-test-denied",
	AllowTestDeniedViolation = "allowed-test-denied-violation",
	AllowedByTemplate = "allowed-by-template",
	DeniedByTemplate = "denied-by-template",
	AllowedByProgressive = "allowed-by-progressive",
}

export interface PathRule {
	channelHash?: string;
	protocol: string;
	port: string;
	portName?: string;
	srcIp?: string;
	sourceAsset?: Asset;
	destinationAsset?: Asset;
	domain?: string;
	dstIp?: string[];
	method?: string;
	uri?: string;
	direction: PathDirection;
	sourceAssetId?: string;
	destinationAssetId?: string;
	sourceNamedNetwork?: NamedNetwork;
	destinationNamedNetwork?: NamedNetwork;
	sourceTagBasedPolicy?: TagBasedPolicy;
	destinationTagBasedPolicy?: TagBasedPolicy;
}

export interface PathFilters {
	criteria: string;
	srcCriteria?: string;
	dstCriteria?: string;
}
