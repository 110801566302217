import { Box, Stepper, styled } from "@mui/material";

export const CTStepper = styled(Stepper)(({ theme }) => ({
	width: "100%",
}));

interface CTStepperContentProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	style?: React.CSSProperties;
}

export function CTStepperContent(props: CTStepperContentProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`stepper-content-${index}`}
			aria-labelledby={`stepper-content-tab-${index}`}
			{...other}
			style={{ width: "100%", ...props.style }}
		>
			{value === index && <Box sx={{ width: "100%", mt: 4 }}>{children}</Box>}
		</div>
	);
}
