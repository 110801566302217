import { SortOrder } from "modules/core/types";

export interface PackageParams {
	sort: SortOrder[];
	limit: number;
	offset: number;
}

export interface VulnerabilityPackageBody {
	product: string;
	vendor: string;
	version: string;
	vulnSource: string;
	totalCveCount: number;
	highCveCount: number;
	criticalCveCount: number;
	maxSeverity: number;
	cvssScore: number;
}

export interface VulnerabilityBody {
	cveID: string;
	description: string;
	severity: number;
	exploitURL: string;
	affectedPorts?: number[];
	remotelyExploitable: boolean;
}

export enum EntityType {
	Assets = "assets",
	Ports = "openports",
	Paths = "paths",
	Templates = "templates",
}

export enum VulnerabilityTypeQuery {
	LateralMovement = "lateralMovement",
	KnownExploit = "exploitAvailable",
}

export interface VulnerabilityPackageReadOnlyBody {
	pack: VulnerabilityPackageBody;
}

export interface VulnerabilityReadOnlyBody {
	vulnerability: VulnerabilityBody;
}

export type VulnerabilityPackageListResult = {
	items?: VulnerabilityPackageBody[];
};

export type VulnerabilityListResult = {
	items?: VulnerabilityBody[];
};
