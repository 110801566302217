import { parseErrorMessage } from "common/utils";
import { MatchedByTemplateType } from "modules/matched-by-templates/constants";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { MatchedByTemplates, Path } from "pages/paths/types";
import { Port } from "pages/ports/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MatchedByTemplateRuleEngine } from "./components";
import { usePathRuleMatchesAPI } from "./hooks";

interface MatchedByTemplateDrawerProps {
	isOpen: boolean;
	onClose: () => void;
	winningTemplates: MatchedByTemplates[];
	id?: string;
	assetId?: string;
	type: MatchedByTemplateType;
	port?: Port;
	path?: Path;
}

export const MatchedByTemplateDrawer = ({
	isOpen,
	onClose,
	winningTemplates,
	id,
	assetId,
	type,
	port,
	path,
}: MatchedByTemplateDrawerProps) => {
	const ruleMatchesMutation = usePathRuleMatchesAPI({
		assetId,
		id,
		type,
		direction:
			type === MatchedByTemplateType.PATH ? path?.direction : undefined,
	});
	const ruleMatchesMutate = ruleMatchesMutation.mutate;
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!id && !assetId) {
			return;
		}

		const body = {};
		ruleMatchesMutate(body, {
			onSuccess() {
				setIsLoading(false);
			},
			onError: error => {
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				setIsLoading(false);
			},
		});
	}, [ruleMatchesMutate, setSnackbar, assetId, id]);

	const getMatchingTemplateData = useCallback(() => {
		const list: MatchedByTemplates[] = [...winningTemplates];
		const pathRuleHash: string[] = [];
		const portRuleHash: string[] = [];

		if (ruleMatchesMutation?.data) {
			const contributingTemplates =
				ruleMatchesMutation?.data?.matchedTemplateDetails;

			const matchedTemplateIDs = Object.keys(contributingTemplates ?? {});

			matchedTemplateIDs.forEach((templateId: string) => {
				pathRuleHash.push(
					...(contributingTemplates?.[templateId]?.pathRuleHash ?? [])
				);
				portRuleHash.push(
					...(contributingTemplates?.[templateId]?.portRuleHash ?? [])
				);

				if (
					winningTemplates.find(template => template.templateId === templateId)
				) {
					return;
				}

				list.push({
					templateId: templateId,
					templateName: "",
				});
			});
		}

		return {
			list,
			pathRuleHash,
			portRuleHash,
		};
	}, [ruleMatchesMutation?.data, winningTemplates]);

	const { list, pathRuleHash, portRuleHash } = useMemo(
		() => getMatchingTemplateData(),
		[getMatchingTemplateData]
	);

	const winningTemplatesIDs = useMemo(
		() => winningTemplates.map(template => template.templateId) ?? [],
		[winningTemplates]
	);

	return isLoading ? null : (
		<MatchedByTemplateRuleEngine
			isOpen={isOpen}
			onClose={onClose}
			list={list}
			winningTemplates={winningTemplatesIDs}
			pathRuleHashList={pathRuleHash}
			portRuleHashList={portRuleHash}
			type={type}
			port={port}
			path={path}
			isAnyMatch={ruleMatchesMutation?.data?.isAnyMatch}
		/>
	);
};
