import { LibraryAdd } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
	Alert,
	Button,
	DialogActions,
	DialogContent,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
	Stack,
	Typography,
	debounce,
} from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useQueryClient } from "@tanstack/react-query";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import {
	RestrictPortToAllowedPathsTable,
	getRowId,
} from "modules/add-to-templates/components/AddToTemplateDialog/RestrictPortToAllowedPathsTable";
import { PortRulesBodyFromPathRules } from "modules/add-to-templates/components/AddToTemplateDialog/helpers/port-rules-body";
import { Annotation } from "modules/annotation";
import { SortOrder } from "modules/core/types";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import { useAssetStore } from "pages/assets/store/useAssetStore";
import { Path, PathDirection, PathRule } from "pages/paths/types";
import {
	Port,
	PortAllowedStatus,
	PortRulePreference,
	PortStatus,
} from "pages/ports/types";
import { useTagPolicyStore } from "pages/tags/components/tag-policy-list/store";
import { TEMPLATES_LIST_COLUMNS } from "pages/templates/components/template-data-grid/constants";
import { TemplateDetail } from "pages/templates/components/template-detail/TemplateDetail";
import { TemplateFormDrawer } from "pages/templates/components/template-form-drawer";
import {
	PortFieldKeys,
	PortList,
	TemplateFormMode,
} from "pages/templates/components/template-form-drawer/components/template-form/types";
import { useTemplateStore } from "pages/templates/store";
import {
	AddToTemplateListProps,
	Template,
	TemplateFormProps,
	TemplateType,
} from "pages/templates/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ListTable } from "../list-table";
import { PathDirectionOption } from "../path-direction-option";
import { PortStatusOption } from "../port-status-option";
import { SearchBar } from "../search-bar";
import { SelectedTemplateItem } from "../selected-template-item";
import { PathRulesBody, PortRulesBody, useUpdateTemplate } from "./helpers";
import {
	useAssignTemplateToAssets,
	useAssignTemplateToTags,
} from "./helpers/use-assign-template";
import { useTemplatesSuggestions } from "./helpers/use-templates-suggestions";
import {
	AddToTemplateDialogProps,
	FormatTemplateRequestBodyProps,
	TemplateActionType,
} from "./types";

const formatTemplateRequestBody = ({
	rules,
	body,
}: FormatTemplateRequestBodyProps) => {
	rules?.forEach((rule: any, index: number) => {
		if (
			rule?.capabitlities &&
			!rule?.capabitlities?.processAllowedInTemplate &&
			body?.["templatePorts"]?.[index]
		) {
			body["templatePorts"][index][PortFieldKeys.Process] = [];
		}
	});
	return body;
};

export default function AddToTemplateDialog({
	page,
	rules,
	onConfirm,
	onCancel,
	title,
	showSrcDestOption,
	showPortStatusOption,
	btnTitle,
	criteria,
	multiselect = false,
	onSelectCallback,
	policyId,
	showNonWindows = false,
	hasPermission,
	actionType,
}: AddToTemplateDialogProps) {
	const [shouldShowPortStatusSuggestions, setShouldShowPortStatusSuggestions] =
		useState(false);
	const [portSelection, setPortSelection] = useState<Array<GridRowId>>([]);
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);
	const templatesSuggestMutation = useTemplatesSuggestions();
	const [templateList, setTemplateList] = useState<Array<Template>>([]);
	const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(100);
	const [portStatus, setPortStatus] = useState<PortAllowedStatus>(
		PortAllowedStatus.AllowAny
	);
	const [portRule, setPortRule] = useState<PortRulePreference>(
		PortRulePreference.Port
	);
	const [pathDirection, setPathDirection] = useState<PathDirection>(
		PathDirection.Inbound
	);

	const [selectedTemplate, setSelectedTemplate] = useState<
		Template | AddToTemplateListProps | undefined
	>({});

	const [tempSelectedTemplate, setTempSelectedTemplate] = useState<
		Template | AddToTemplateListProps
	>({});

	const [selectedTemplateList, setSelectedTemplateList] = useState<
		Array<Template>
	>([]);
	const [isNonWindowsAgentPresent, setIsNonWindowsAgentPresent] =
		useState<boolean>(false);
	useEffect(() => {
		const hasNonWindowsAgent = rules?.some(
			(rule: any) => !rule?.processAllowedInTemplate
		);
		setIsNonWindowsAgentPresent(!!hasNonWindowsAgent);
	}, [rules]);
	const apiRefreshRequest = useTemplateStore(state => state.apiRefreshRequest);
	const requestAPIRefresh = useTemplateStore(state => state.requestAPIRefresh);
	const requestTagPolicyAPIRefresh = useTagPolicyStore(
		state => state.requestAPIRefresh
	);
	const requestAssetAPIRefresh = useAssetStore(
		state => state.requestAPIRefresh
	);
	const defaultSortOrder: Array<SortOrder> = [
		{ field: "oobTemplate", order: "asc" },
		{ field: "templateName", order: "asc" },
	];
	const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder || []);
	const [showProcessOption, setShowProcessOption] = useState<
		boolean | undefined
	>(true);
	const onSortChange = useCallback((sort: Array<SortOrder>) => {
		setSort(sort);
	}, []);

	const notify = useEventSubscriptionStore(state => state.notify);
	const [searchText, setSearchText] = useState("");
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [showList, setShowList] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const [createDrawerVisibility, setCreateDrawerVisibility] = useState(false);

	const onCloseCreateDrawer = (value?: boolean) => {
		setCreateDrawerVisibility(false);
	};

	const queryClient = useQueryClient();

	const updateAddToTemplateMutation = useUpdateTemplate(
		selectedTemplate?.templateId
	);

	const assignTemplateToAssetsMutation = useAssignTemplateToAssets();

	const assignTemplateToTagsMutation = useAssignTemplateToTags({
		policyId: policyId,
	});

	const total = useMemo(
		() => templatesSuggestMutation.data?.metadata?.total ?? 0,
		[templatesSuggestMutation.data]
	);

	const mutate = useMemo(
		() => debounce(templatesSuggestMutation.mutate, 300),
		[templatesSuggestMutation.mutate]
	);

	const updateBodyWithTemplatePorts = useCallback(
		(
			body: any,
			selectedTemplate: Template,
			status?: PortStatus,
			portSelection?: GridRowId[]
		) => {
			let updatedBody = { ...body };
			let newPortStatus =
				status ?? (portStatus as PortAllowedStatus | PortStatus);
			if (selectedTemplate?.templateType === TemplateType.BlockTemplate) {
				newPortStatus = PortStatus.Deny;
			}

			const selectedRules = rules?.filter((rule: any) =>
				portSelection ? portSelection?.includes(getRowId(rule)) : true
			);

			if (status === PortStatus.PathRestricted) {
				updatedBody["templatePorts"] = PortRulesBodyFromPathRules({
					rules: selectedRules as Array<Path>,
					portStatus: newPortStatus,
				});
			} else {
				updatedBody["templatePorts"] = PortRulesBody({
					rules: selectedRules as Array<Port>,
					portStatus: newPortStatus,
					templateType:
						selectedTemplate?.templateType ?? TemplateType?.ApplicationTemplate,
				});
			}

			if (updatedBody["templatePorts"]) {
				if (portRule === PortRulePreference.Port) {
					updatedBody["templatePorts"].forEach((obj: any) => {
						obj[PortFieldKeys.Process] = [];
					});
				}
				if (portRule === PortRulePreference.Process) {
					updatedBody["templatePorts"].forEach((obj: any) => {
						if (obj[PortFieldKeys.Process][0]) {
							obj[PortFieldKeys.Port] = PortList.ANY;
						} else {
							obj[PortFieldKeys.Process] = [];
						}
					});
				}
				if (portRule === PortRulePreference.Port_Process) {
					updatedBody["templatePorts"].forEach((obj: any) => {
						if (!obj[PortFieldKeys.Process][0]) {
							obj[PortFieldKeys.Process] = [];
						}
					});
				}
				updatedBody = formatTemplateRequestBody({
					rules: selectedRules,
					body: updatedBody,
				});
			}

			return updatedBody;
		},
		[portRule, portStatus, rules]
	);

	const buildRequestBody = useCallback(
		(selectedTemplate: Template) => {
			let body: any = { comment };

			if (page === "ports") {
				body = updateBodyWithTemplatePorts(body, selectedTemplate);
			} else if (page === "paths") {
				body["templatePaths"] = PathRulesBody({
					rules: rules as Array<Path>,
					pathDirection: pathDirection as PathDirection,
				});

				if (shouldShowPortStatusSuggestions) {
					body = updateBodyWithTemplatePorts(
						body,
						selectedTemplate,
						PortStatus.PathRestricted,
						portSelection
					);
				}
			} else if (page === "pathRules") {
				body["templatePaths"] = rules?.map(rule => ({
					...(rule as PathRule),
					reviewed: portStatus,
				}));
				body["templatePorts"] = [];
			}

			return body;
		},
		[
			updateBodyWithTemplatePorts,
			comment,
			page,
			pathDirection,
			portSelection,
			portStatus,
			rules,
			shouldShowPortStatusSuggestions,
		]
	);

	const addToTemplate = useCallback(
		(selectedTemplate: Template) => {
			const body = buildRequestBody(selectedTemplate);

			updateAddToTemplateMutation.mutate(body, {
				onSuccess: response => {
					onConfirm();
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "RequestToAddRulesToTemplateSubmitted",
					});
					queryClient.invalidateQueries({
						queryKey: ["policy"],
					});
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		},
		[
			buildRequestBody,
			onConfirm,
			notify,
			queryClient,
			updateAddToTemplateMutation,
			setSnackbar,
		]
	);

	const assignTemplateToAssets = useCallback(
		async (selectedTemplateList: Template[]) => {
			interface TemplatesToAssets {
				templates?: Array<string>;
				criteria: string;
				comment?: string;
				templateId?: string;
			}

			let body: TemplatesToAssets = {
				criteria: criteria ?? "",
				comment: comment,
			};

			const selectedTemplateIds = (selectedTemplateList ?? []).map(
				(template: Template) => template.templateId
			);

			body["templates"] = [...selectedTemplateIds];

			if (multiselect) {
				body["templates"] = [...selectedTemplateIds];
			} else {
				body["templateId"] = selectedTemplate?.templateId;
			}

			await assignTemplateToAssetsMutation.mutateAsync(body, {
				onSuccess: response => {
					setComment(undefined);
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "TemplateAssignmentSubmitted",
					});
					queryClient.invalidateQueries({
						queryKey: ["asset"],
					});
					requestAssetAPIRefresh();
					requestAPIRefresh();
					setIsLoading(false);
					onConfirm();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		},
		[
			criteria,
			comment,
			multiselect,
			assignTemplateToAssetsMutation,
			selectedTemplate?.templateId,
			notify,
			queryClient,
			requestAssetAPIRefresh,
			requestAPIRefresh,
			onConfirm,
			setSnackbar,
		]
	);

	const assignTemplateToTags = useCallback(
		async (selectedTemplateList: Template[]) => {
			interface TemplatesToTags {
				templates?: Array<string>;
				comment?: string;
			}

			let body: TemplatesToTags = {
				templates: [],
				comment: comment,
			};

			const selectedTemplateIds = (selectedTemplateList ?? []).map(
				(template: Template) => template.templateId
			);

			body["templates"] = [...selectedTemplateIds];

			await assignTemplateToTagsMutation.mutateAsync?.(body, {
				onSuccess: response => {
					setComment(undefined);
					notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
						label: "TemplateAssignmentSubmitted",
					});
					queryClient.invalidateQueries({
						queryKey: ["tagbasedpolicy"],
					});
					requestTagPolicyAPIRefresh();
					onConfirm();
				},
				onError: error => {
					setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
				},
			});
		},
		[
			comment,
			assignTemplateToTagsMutation,
			notify,
			queryClient,
			requestTagPolicyAPIRefresh,
			onConfirm,
			setSnackbar,
		]
	);

	const onClose = () => {
		setSelectedTemplate({});
		onCancel();
	};

	const confirmChanges = () => {
		if (multiselect) {
			if (onSelectCallback) {
				onSelectCallback(selectedTemplateList);
				onConfirm();
			} else {
				// process bulk assignment apis
				if (page === "tags") {
					assignTemplateToTags(selectedTemplateList);
				} else if (page === "assets") {
					assignTemplateToAssets(selectedTemplateList);
				}
			}
		} else {
			if (selectedTemplate?.templateId) {
				if (page === "assets" || page === "usergroup") {
					assignTemplateToAssets(selectedTemplateList);
				} else if (
					page === "ports" ||
					page === "paths" ||
					page === "pathRules"
				) {
					addToTemplate(selectedTemplate as Template);
				} else if (page === "tags") {
					assignTemplateToTags(selectedTemplateList);
				}
			} else {
				setSelectedTemplate({});
			}
		}
	};

	useEffect(() => {
		let nonSystemTemplatesCriteria = "oobtemplate = false";
		const allowTemplatesCriteria = `templateType != '${TemplateType.BlockTemplate}'`;

		if (page === "paths") {
			nonSystemTemplatesCriteria = `${nonSystemTemplatesCriteria} and ${allowTemplatesCriteria}`;
		}

		const hasSearch = searchText?.trim()?.length > 0;

		const criteria =
			page === "assets" || page === "tags" || page === "usergroup"
				? searchText
					? `'${searchText}'`
					: "*"
				: searchText
					? `'${searchText}' AND ${nonSystemTemplatesCriteria}`
					: nonSystemTemplatesCriteria;

		const body = {
			criteria: criteria,
			pagination: {
				offset: hasSearch ? 0 : pageCount * pageSize,
				limit: pageSize,
				sort: sort,
			},
		};

		mutate(body, {
			onSuccess: response => {
				setTemplateList(response?.items || ([] as Array<Template>));
			},
		});
	}, [mutate, apiRefreshRequest, searchText, page, sort, pageCount, pageSize]);

	useEffect(() => {
		const flag = rules?.some((ele: any) => {
			return Boolean(ele.listenProcessNames?.[0]);
		});
		setShowProcessOption(flag);
	}, [rules]);

	const updatePortStatus = (status: PortAllowedStatus) => {
		setPortStatus(status);
	};
	const updatePortRules = (status: PortRulePreference) => {
		setPortRule(status);
	};
	const updatePathDirection = (direction: PathDirection) => {
		setPathDirection(direction);
	};

	const updateTemplateData = ({
		templateName = "",
		templateDescription,
		templateType,
		templateId,
	}: TemplateFormProps) => {
		const template: Template = {
			templateName,
			templateDescription: templateDescription || "",
			templateId: templateId || "",
			templateType: templateType,
			assignedByTagBasedPolicy: true,
			totalComments: 0,
			templateUnassignmentsPendingFirewallSynchronize: 0,
		};

		if (actionType === TemplateActionType.assign && page === "assets") {
			assignTemplateToAssets([template]);
		}

		if (actionType === TemplateActionType.assign && page === "tags") {
			assignTemplateToTags([template]);
		}

		if (page === "ports" || page === "paths" || page === "pathRules") {
			// pathRules page is for path recommendation drawer.
			setSelectedTemplate(template);
			setShowList(false);
		}

		requestAPIRefresh();
	};

	const onSearchTextChange = (searchText: string) => {
		setSearchText(searchText || "");
	};

	const onSearchFocus = () => {
		setShowList(true);
	};

	const handleRowClickEvent = (row: any) => {
		const template: Template = row as Template;
		if (row?.templateId) {
			setSelectedTemplate(template);
			setShowList(false);
		}
	};

	const onBack = () => {
		setSelectedTemplate(undefined);
		setShowList(true);
	};

	const triggerCreateNewTemplateFlow = () => {
		const template: AddToTemplateListProps | Template = {
			templateId: "",
			templateName: searchText,
			templateDescription: "",
		};
		setTempSelectedTemplate(template);
		setCreateDrawerVisibility(true);
	};

	const onTempSelectCallback = useCallback((templates: Array<Template>) => {
		setSelectedTemplateList(templates);
	}, []);

	const isValid = () => {
		if (multiselect) {
			return Boolean(selectedTemplateList?.length > 0);
		} else {
			return Boolean(selectedTemplate?.templateId);
		}
	};

	const shouldShowPortStatusSuggestion =
		userPermissions.has("UPDATE_PORT") &&
		!showList &&
		selectedTemplate?.templateId &&
		selectedTemplate.templateType !== TemplateType.BlockTemplate &&
		page === "paths" &&
		pathDirection === PathDirection.Inbound;

	const columns = useMemo(
		() =>
			TEMPLATES_LIST_COLUMNS.filter(
				col => col.field !== "templateUnassignmentsPendingFirewallSynchronize"
			),
		[]
	);

	return (
		<>
			<DialogContent sx={{ height: "100%" }}>
				<Stack alignItems={"flex-start"} sx={{ width: "100%", height: "100%" }}>
					<Stack sx={{ width: "100%", height: "100%" }}>
						<Stack
							direction="row"
							sx={{ width: "100%" }}
							alignItems={"center"}
							mb={2}
							spacing={1}
						>
							{showList ? (
								<Typography variant="h6">
									{window.getCTTranslatedText(title)}
								</Typography>
							) : (
								<Link
									typography={"h6"}
									underline="hover"
									color={theme => theme.palette.text.primary}
									component={RouterLink}
									to={"#"}
									onClick={onBack}
								>
									{window.getCTTranslatedText(title)}
								</Link>
							)}
							{!showList && (
								<>
									<NavigateNextIcon />
									<Typography variant="h6">
										{window.getCTTranslatedText("Selected Template")}
									</Typography>
								</>
							)}
						</Stack>
						<Stack
							sx={{ width: "100%", flex: 1, overflow: "auto" }}
							spacing={2}
						>
							{showList && (
								<Stack alignItems="flex-start" sx={{ width: "100%" }}>
									{(isNonWindowsAgentPresent || showNonWindows) && (
										<Alert sx={{ width: "100%", mb: 2 }} severity={"warning"}>
											{window.getCTTranslatedText("ProcessTemplateWarning")}
										</Alert>
									)}
									<Stack direction="row" sx={{ width: "100%" }} spacing={3}>
										<SearchBar
											id="template-list-searchbar"
											label={window.getCTTranslatedText("Search Templates")}
											placeholder={"Search Templates"}
											selectedValue={selectedTemplate?.templateName}
											onChange={onSearchTextChange}
											onFocus={onSearchFocus}
										/>
										<Button
											variant="contained"
											onClick={triggerCreateNewTemplateFlow}
											sx={{ width: "180px" }}
										>
											{window.getCTTranslatedText("Create Template")}
										</Button>
									</Stack>
								</Stack>
							)}
							{showList &&
								(templateList?.length > 0 ||
									(templateList?.length === 0 &&
										searchText?.trim().length === 0)) && (
									<Stack sx={{ flex: 1, overflow: "hidden" }}>
										<Stack
											alignItems="flex-start"
											sx={{ flex: 1, overflow: "hidden" }}
										>
											<ListTable
												list={templateList}
												columns={columns}
												mutation={templatesSuggestMutation}
												rowClickCallback={handleRowClickEvent}
												multiselect={multiselect}
												onSelectCallback={onTempSelectCallback}
												onSortChange={onSortChange}
												hasPermission={hasPermission}
												isLoading={isLoading}
												setPage={setPageCount}
												setPageSize={setPageSize}
												pageSize={pageSize}
												page={pageCount}
												totalCount={total}
											/>
										</Stack>

										{selectedTemplateList.length > 0 && (
											<Annotation setComment={setComment} comment={comment} />
										)}
									</Stack>
								)}

							{templateList?.length === 0 && searchText?.trim().length > 0 && (
								<Paper
									sx={{
										flex: 1,
										width: "100%",
										height: "100%",
									}}
								>
									{userPermissions.has("CREATE_TEMPLATE") &&
									(page === "ports" ||
										page === "paths" ||
										page === "pathRules" ||
										page === "usergroup") ? (
										<List dense={false} disablePadding>
											<ListItem disablePadding>
												<ListItemButton
													role={undefined}
													onClick={triggerCreateNewTemplateFlow}
												>
													<ListItemIcon>
														<LibraryAdd />
													</ListItemIcon>
													<ListItemText
														primary={`Create new template "${searchText}"`}
													/>
												</ListItemButton>
											</ListItem>
										</List>
									) : (
										<Stack
											alignContent={"center"}
											justifyContent="center"
											justifyItems={"center"}
											sx={{ py: 5, px: 3 }}
										>
											<Typography variant="body2" sx={{ textAlign: "center" }}>
												{window.getCTTranslatedText("No Templates available")}
											</Typography>
										</Stack>
									)}
								</Paper>
							)}
							{!showList && selectedTemplate?.templateId && (
								<>
									{(isNonWindowsAgentPresent || showNonWindows) && (
										<Alert sx={{ width: "100%" }} severity={"warning"}>
											{window.getCTTranslatedText("ProcessTemplateWarning")}
										</Alert>
									)}
									{page === "assets" ||
									page === "tags" ||
									page === "usergroup" ? (
										<>
											<TemplateDetail
												templateId={selectedTemplate?.templateId}
												viewOnly={true}
											/>
											<Annotation setComment={setComment} comment={comment} />
										</>
									) : (
										<>
											<SelectedTemplateItem
												selectedTemplate={selectedTemplate}
												page={page}
											/>
										</>
									)}
								</>
							)}

							{!showList &&
								selectedTemplate?.templateId &&
								showSrcDestOption && (
									<Stack mt={3}>
										<PathDirectionOption
											updatePathDirection={updatePathDirection}
										/>
									</Stack>
								)}

							{!showList &&
								selectedTemplate?.templateId &&
								showPortStatusOption && (
									<Stack mt={3}>
										<PortStatusOption
											updatePortStatus={updatePortStatus}
											updatePortRule={updatePortRules}
											showPortStatus={
												selectedTemplate?.templateType !==
												TemplateType.BlockTemplate
											}
											showProcessOptions={showProcessOption}
										/>
									</Stack>
								)}

							{shouldShowPortStatusSuggestion && (
								<RestrictPortToAllowedPathsTable
									rules={rules}
									portSelection={portSelection}
									setPortSelection={setPortSelection}
									shouldShowPortStatusSuggestions={
										shouldShowPortStatusSuggestions
									}
									setShouldShowPortStatusSuggestions={
										setShouldShowPortStatusSuggestions
									}
								/>
							)}
						</Stack>

						{!showList && selectedTemplate?.templateId && (
							<Annotation setComment={setComment} comment={comment} />
						)}
					</Stack>
					{userPermissions.has("CREATE_TEMPLATE") && createDrawerVisibility && (
						<TemplateFormDrawer
							template={tempSelectedTemplate as TemplateFormProps}
							title={"Create New Template"}
							isOpen={createDrawerVisibility}
							onClose={onCloseCreateDrawer}
							updateTemplateData={updateTemplateData}
							btnTitle={"Create"}
							mode={TemplateFormMode.CREATE}
							backButtonEnable={true}
							actionType={actionType}
							page={page}
						/>
					)}
				</Stack>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<ToolbarAction
					loading={
						updateAddToTemplateMutation?.isLoading ||
						assignTemplateToAssetsMutation?.isLoading
					}
					save={confirmChanges}
					cancel={showList ? onClose : onBack}
					isValid={isValid()}
					actionBtnText={btnTitle}
					secondaryBtnText={selectedTemplate?.templateId ? "back" : "cancel"}
				/>
			</DialogActions>
		</>
	);
}
