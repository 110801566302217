import { useTheme } from "@mui/material";
import {
	CORE_TAG_ICONS,
	DEFAULT_OS_ICON,
	DEFAULT_TAG_ICON,
	IconObj,
	MISC_TAG_ICONS,
	OS_ICON_MAP,
	UNMANAGED_DEVICE_CORE_TAG_ICONS,
} from "common/constants/tagIcons";
import { IconDictionary } from "common/types/types";
import { UnmanagedDeviceCoreTagKey } from "pages/appliance-detail/components/appliance-unmanaged-devices/constants";
import { CoreTagsKeys, OSType } from "pages/assets/types";

type IconKeyMap = {
	[IconDictionary.CORE_TAG]: CoreTagsKeys;
	[IconDictionary.MISC]: keyof typeof MISC_TAG_ICONS;
	[IconDictionary.OS]: OSType;
	[IconDictionary.UNMANAGED_DEVICE]: UnmanagedDeviceCoreTagKey;
};

function useIcons() {
	const theme = useTheme();

	const createIcon = ({ icon: IconComponent, defaultProps }: IconObj) => {
		const color =
			theme.palette.mode === "dark"
				? "rgba(255, 255, 255, 0.38)"
				: "rgba(0, 0, 0, 0.38)";

		const props = {
			width: "24px",
			height: "24px",
			color,
			sx: {
				color,
			},
			...defaultProps,
		};

		return (
			overrideProps: any = {} // TODO: fix any
		) => <IconComponent {...props} {...overrideProps} />;
	};

	const iconDictionaryMap: Record<IconDictionary, (key: string) => IconObj> = {
		[IconDictionary.CORE_TAG]: key =>
			CORE_TAG_ICONS[key as CoreTagsKeys] ?? DEFAULT_TAG_ICON,
		[IconDictionary.MISC]: key =>
			MISC_TAG_ICONS[key as keyof typeof MISC_TAG_ICONS] ?? DEFAULT_TAG_ICON,
		[IconDictionary.OS]: key => OS_ICON_MAP[key as OSType] ?? DEFAULT_OS_ICON,
		[IconDictionary.UNMANAGED_DEVICE]: key =>
			UNMANAGED_DEVICE_CORE_TAG_ICONS[key as UnmanagedDeviceCoreTagKey] ??
			DEFAULT_TAG_ICON,
	};

	const getIcon = <D extends IconDictionary>(
		dictionaryToSearch: D,
		key: IconKeyMap[D]
	) => {
		const iconObj = iconDictionaryMap[dictionaryToSearch]?.(key);
		return createIcon(iconObj ?? DEFAULT_TAG_ICON);
	};

	return {
		getIcon,
	};
}

export default useIcons;
