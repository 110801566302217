import LoadingButton from "@mui/lab/LoadingButton";
import {
	Button,
	capitalize,
	CircularProgress,
	Stack,
	Typography,
} from "@mui/material";
import { CTInputField } from "common/atoms/ct-input-field";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { useCore } from "modules/core";
import { useCriteriaBuilder } from "modules/core/Core";
import { COMMON_FACETS } from "modules/facets/constants";
import { useAssetAggregateAPI } from "modules/recommendation-workflows/PathRecommendationDrawer";
import { Scope } from "modules/scope-metadata/types";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import numeral from "numeral";
import { FormSectionWrapper } from "pages/create-tag-policy/components/PolicyForm";
import { PolicySelectionForm } from "pages/create-tag-policy/components/PolicySelectionForm";
import { useTagPolicyListAPI } from "pages/tags/components/tag-policy-list/TagPolicyList";
import { useEffect, useMemo } from "react";
import { Control, useController, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	useFirewallHostGroupFacetStore,
	useFirewallHostGroupStore,
} from "../store";
import useCreateFirewallHostGroup from "./hooks/useCreateFirewallHostGroup";
import { CreateFirewallHostGroupWrapper } from "./styles";
import { CreateFirewallGroupRequest } from "./types";

type Form = Omit<CreateFirewallGroupRequest, "criteriaAsParams">;

interface TextFieldProps {
	control: Control<Form, any>;
	name: "crowdstrikeFirewallHostgroupName" | "description" | "criteria";
	displayName: string;
	required?: boolean;
}

interface CriteriaProps {
	control: Control<Form, any>;
	name: "criteria";
}

const TextField = ({
	control,
	name,
	displayName,
	required = false,
}: TextFieldProps) => {
	const { field } = useController({
		name,
		control,
		rules: { required },
	});

	return (
		<CTInputField
			field={field.name}
			displayName={displayName}
			value={field.value}
			handleUpdate={e => field.onChange(e.value)}
		/>
	);
};

const Criteria = ({ control, name = "criteria" }: CriteriaProps) => {
	const {
		field: { onChange },
	} = useController({
		name,
		control,
		rules: { required: true },
	});

	const coreResponse = useCore<Scope.CrowdStrikeFirewallHostGroup>({
		useStore: useFirewallHostGroupStore,
		facetGroupInfo: COMMON_FACETS,
		scope: Scope.CrowdStrikeFirewallHostGroup,
		useFacetStore: useFirewallHostGroupFacetStore,
		useApi: useTagPolicyListAPI,
		pageSize: 1,
	});

	const assetAggMutation = useAssetAggregateAPI();
	const assetAggMutate = assetAggMutation.mutate;
	const {
		data: assetAgg,
		isLoading: isLoadingAssetAgg,
		reset: resetAggCount,
	} = assetAggMutation;

	const assetAggCount: number = useMemo<number>(() => {
		return Number(assetAgg?.items?.statistics?.assetidcount ?? 0);
	}, [assetAgg]);

	const { facets, metadata, setFacets } = useFirewallHostGroupFacetStore();

	const criteriaBuilderValue = useCriteriaBuilder("", facets, metadata);
	const searchCriteria =
		criteriaBuilderValue === "*" ? "" : criteriaBuilderValue;

	useEffect(() => {
		const criteria = searchCriteria;
		onChange(criteria ?? "");

		if (!criteria) {
			resetAggCount();
			return;
		}

		assetAggMutate({
			criteria,
			scope: Scope.Asset,
			statistics: ["count(assetid)"],
		});
	}, [assetAggMutate, onChange, resetAggCount, searchCriteria]);

	useEffect(() => {
		return () => {
			setFacets(new Map());
		};
	}, [setFacets]);

	return (
		<FormSectionWrapper className="criteria">
			<PolicySelectionForm
				coreResponse={coreResponse}
				useFacetStore={useFirewallHostGroupFacetStore}
				viewOnly={false}
				title="Criteria *"
				addCriteriaBtnTitle="Add Tags"
				hideSavedQuery
			/>
			<Stack
				alignItems={"flex-end"}
				sx={{
					mt: 1,
					display: Boolean(searchCriteria) ? undefined : "none",
				}}
			>
				{isLoadingAssetAgg ? (
					<CircularProgress size={18} />
				) : (
					<Typography variant="overline">{`${numeral(assetAggCount).format(
						"0a"
					)} ${window.getCTTranslatedText("matching assets")}`}</Typography>
				)}
			</Stack>
		</FormSectionWrapper>
	);
};

export function CreateFirewallHostGroup() {
	const title = window.getCTTranslatedText("Create Firewall Host Group");
	const navigate = useNavigate();
	const [urlParams] = useSearchParams();
	const notify = useEventSubscriptionStore(state => state.notify);
	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const {
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<Form>({
		defaultValues: {
			crowdstrikeFirewallHostgroupName: "",
			description: "",
			criteria: "",
		},
	});
	const { mutate, isLoading } = useCreateFirewallHostGroup();
	const { clear } = useFirewallHostGroupFacetStore();

	const onSubmit = (data: Form) => {
		const criteriaAsParams = new URLSearchParams(urlParams);

		const body = {
			...data,
			criteriaAsParams: criteriaAsParams.toString(),
		};

		mutate(body, {
			onSuccess: () => {
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "CreateFirewallHostGroupSuccess",
				});
				clear();
				navigate("/crowdstrike-groups?tab=firewall-host-groups");
			},
			onError: error => {
				// @ts-ignore: Axios error type
				let message = error?.response?.data?.message ?? "SomethingWentWrong";
				const capitalizedMessage = capitalize(message);

				setSnackbar(true, SnackBarSeverity.Error, capitalizedMessage);
			},
		});
	};

	const onCancel = () => {
		clear();
		navigate("/crowdstrike-groups?tab=firewall-host-groups");
	};

	return (
		<CreateFirewallHostGroupWrapper sx={{ p: 4 }}>
			<Typography variant="h6" className="title">
				{title}
			</Typography>
			<TextField
				control={control}
				name="crowdstrikeFirewallHostgroupName"
				displayName="Name"
				required
			/>
			<TextField
				control={control}
				name="description"
				displayName="Description"
			/>
			<Criteria control={control} name="criteria" />
			<Stack
				direction="row"
				justifyContent="flex-end"
				gap={2}
				className="actions"
			>
				<Button
					variant="outlined"
					color="primary"
					type="button"
					onClick={onCancel}
				>
					{window.getCTTranslatedText("Cancel")}
				</Button>

				<LoadingButton
					loading={isLoading}
					variant="contained"
					color="primary"
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
				>
					{window.getCTTranslatedText("Save")}
				</LoadingButton>
			</Stack>
		</CreateFirewallHostGroupWrapper>
	);
}
