import { CheckCircleOutline } from "@mui/icons-material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import numeral from "numeral";
import { PathAPIDetails } from "pages/paths/components/path-api-details";
import { PathDestination } from "pages/paths/components/path-destination";
import { PathDetails } from "pages/paths/components/path-details";
import { PathSource } from "pages/paths/components/path-source";
import { PortDetails } from "pages/ports/components/port-details";
import { StatusOptionsText as PortStatusOptionsText } from "pages/ports/constants";
import { PolicyCriteria } from "pages/tags/components/tag-policy-list/components/policy-criteria";
import { RestrictPortToAllowedPaths } from "pages/templates/components/RestrictPortToAllowedPaths";
import { ActionTemplateDialog } from "pages/templates/components/action-template-dialog";
import {
	getDestinationSortingName,
	getSourceSortingName,
	parsePortValue,
	shouldSuggestRestrictPortToAllowedPathsForPaths,
	shouldSuggestRestrictPortToAllowedPathsForPorts,
} from "pages/templates/components/template-data-grid/helper";
import { TemplateType } from "pages/templates/components/template-type";
import {
	TemplatePath,
	TemplatePort,
	TemplateType as TemplateTypeEnum,
} from "pages/templates/types";
import { AssetCount } from "../asset-count";
import { GroupCount } from "../group-count";
import { RemoveTemplateDialog } from "../remove-template-dialog";
import { RemoveTemplateRuleDialog } from "../remove-template-rule-dialog";
import { TemplateAnnotations } from "../template-annotations";
import { TemplateCategory } from "../template-category/TemplateCategory";
import { TemplateNameWithDrawer } from "../template-name-with-drawer";
import { TemplatePendingFirewall } from "../template-pending-firewall";
import { TemplateTypeIcon } from "../template-type-icon";
import { UsedByPolicies } from "../used-by-policies";

export const BASE_TEMPLATES_COLUMNS: GridColDef[] = [
	{
		field: "isoobtemplate",
		headerName: "",
		width: 70,
		minWidth: 70,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<Stack>
				<TemplateTypeIcon template={params?.row} />
			</Stack>
		),
	},
	{
		field: "templateName",
		headerName: "Name",
		width: 200,
		minWidth: 160,
		sortingOrder: ["asc", "desc"],
		flex: window.screen.availWidth > 1200 ? 0.5 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip title={params?.row?.templateName || ""}>
					<Typography
						variant="body2"
						noWrap
						sx={{ textTransform: "capitalize" }}
					>
						{params?.row?.templateName || ""}
					</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "templateDescription",
		headerName: "Description",
		sortable: false,
		width: 400,
		minWidth: 160,
		flex: window.screen.availWidth > 1200 ? 0.5 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip
					title={window.getCTTranslatedText(
						params?.row?.templateDescription || ""
					)}
				>
					<Typography variant="body2" noWrap>
						{window.getCTTranslatedText(params?.row?.templateDescription || "")}
					</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "templateType",
		headerName: "Type",
		width: 160,
		minWidth: 140,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <TemplateType template={params?.row} />;
		},
	},
	{
		field: "templateCategory",
		headerName: "Category",
		width: 160,
		minWidth: 140,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		// sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <TemplateCategory template={params?.row} />;
		},
	},
	{
		field: "templatePorts",
		headerName: "Ports",
		width: 160,
		minWidth: 140,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Typography variant="body2">
					{numeral(params?.row?.templatePorts ?? 0).format("0a")}
				</Typography>
			);
		},
	},
	{
		field: "templatePaths",
		headerName: "Paths",
		width: 160,
		minWidth: 140,
		sortingOrder: ["asc", "desc"],
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Typography variant="body2">
					{numeral(params?.row?.templatePaths ?? 0).format("0a")}
				</Typography>
			);
		},
	},
	{
		field: "templateAssignments",
		headerName: "Used by assets",
		width: 140,
		minWidth: 140,
		sortingOrder: ["asc", "desc"],
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetCount template={params?.row} />;
		},
	},
	{
		field: "templateTagBasedPolicyAssignments",
		headerName: "UsedBySegments",
		width: 140,
		minWidth: 140,
		sortingOrder: ["asc", "desc"],
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <UsedByPolicies template={params?.row} />;
		},
	},
];

const COMMON_TEMPLATE_COLUMNS: GridColDef[] = [
	{
		field: "action",
		headerName: "Action",
		sortable: false,
		width: 100,
		minWidth: 100,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <ActionTemplateDialog template={params.row} />;
		},
	},
	{
		field: "comment",
		headerName: "",
		headerAlign: "left",
		align: "left",
		width: 50,
		minWidth: 50,
		flex: window.screen.availWidth > 1200 ? 0.1 : undefined,
		sortingOrder: ["desc", "asc"],
		sortable: false,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <TemplateAnnotations template={params.row} />;
		},
	},
];

export const ENDPOINT_TEMPLATE_COLUMNS: GridColDef[] = [
	...BASE_TEMPLATES_COLUMNS,
	{
		field: "ugTemplateAssignments",
		headerName: "Used by User Groups",
		width: 140,
		minWidth: 140,
		sortingOrder: ["asc", "desc"],
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <GroupCount template={params?.row} />;
		},
	},
	...COMMON_TEMPLATE_COLUMNS,
];

export const TEMPLATES_COLUMNS: GridColDef[] = [
	...BASE_TEMPLATES_COLUMNS,
	...COMMON_TEMPLATE_COLUMNS,
];

export const BASE_PORT_COLUMNS = ({
	mode,
	isProcessTemplateEnabled,
}: {
	mode?: string;
	isProcessTemplateEnabled?: boolean;
}): GridColDef[] => [
	...(mode === "groupedByRule"
		? [
				{
					field: "isWinningRule",
					headerName: "",
					sortable: false,
					width: 50,
					minWidth: 50,
					renderCell: (params: GridRenderCellParams<any, any, any>) => {
						return params.row.isWinningRule ? (
							<CheckCircleOutline fontSize="small" color={"success"} />
						) : (
							<RemoveCircleOutlineIcon fontSize="small" color="error" />
						);
					},
				},
			]
		: []),
	{
		field: "listenPort",
		headerName: "Port",
		width: mode === "groupedByRule" ? 120 : 280,
		minWidth: 160,
		sortingOrder: ["asc", "desc"],
		flex:
			window.screen.availWidth > 1200
				? mode === "groupedByRule"
					? 0.4
					: 0
				: undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<PortDetails
					port={params.row}
					viewOnly={true}
					showInternetFacingComp={false}
				/>
			);
		},
		sortComparator: (_, __, params1, params2) => {
			const row1 = params1?.api?.getRow(params1.id);
			const row2 = params2?.api?.getRow(params2.id);

			const port1 = parsePortValue(row1?.listenPort || "");
			const port2 = parsePortValue(row2?.listenPort || "");

			return port1 - port2;
		},
	},
	...(isProcessTemplateEnabled
		? [
				{
					field: "listenProcessNames",
					headerName: "Process",
					width: 280,
					minWidth: 160,
					flex: window.screen.availWidth > 1200 ? 0.5 : undefined,
					renderCell: (params: GridRenderCellParams<any, any, any>) => (
						<Tooltip title={params?.row?.listenProcessNames?.join("\n") || ""}>
							<Typography variant="body2" noWrap>
								{params?.row?.listenProcessNames?.join(" ") || ""}
							</Typography>
						</Tooltip>
					),
					sortComparator: (v1: string[], v2: string[]) => {
						const name1 = v1.join(" ");
						const name2 = v2.join(" ");
						return name1.localeCompare(name2);
					},
				},
			]
		: []),
	{
		field: "listenPortReviewed",
		headerName: "Status",
		width: 160,
		minWidth: 160,
		sortingOrder: ["asc", "desc"],
		flex:
			window.screen.availWidth > 1200
				? mode === "groupedByRule"
					? 0.6
					: 0.4
				: undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Box>
					{window.getCTTranslatedText(
						PortStatusOptionsText[params.row.listenPortReviewed]
					)}
				</Box>
			);
		},
	},
	...(mode === "groupedByRule"
		? [
				{
					field: "templateName",
					headerName: "Template Name",
					flex: 0.4,
					renderCell: (params: GridRenderCellParams<any, any, any>) => {
						return (
							<TemplateNameWithDrawer
								templateId={params?.row?.templateId}
								templateName={params?.row?.templateName}
							/>
						);
					},
				},
			]
		: []),
];

export const PORT_COLUMNS = (
	templateType?: TemplateTypeEnum,
	title?: string,
	pathList?: Array<TemplatePath>,
	mode?: string,
	isProcessTemplateEnabled?: boolean
): GridColDef[] => {
	return [
		...BASE_PORT_COLUMNS({
			mode,
			isProcessTemplateEnabled,
		}),
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 80,
			minWidth: 80,
			flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<Stack direction="row" alignItems="center" spacing={1}>
						<RemoveTemplateRuleDialog rule={params.row} page="port" />
						{shouldSuggestRestrictPortToAllowedPathsForPorts(
							params.row,
							templateType,
							pathList
						) && (
							<RestrictPortToAllowedPaths
								rule={params.row}
								allRules={params.row.paths}
								ruleType="PORT"
								templateType={templateType}
								title={title ?? ""}
							/>
						)}
					</Stack>
				);
			},
		},
	];
};

export const BASE_INBOUND_PATH_COLUMNS = (mode?: string): GridColDef[] => {
	return [
		...(mode === "groupedByRule"
			? [
					{
						field: "isWinningRule",
						headerName: "",
						sortable: false,
						width: 50,
						minWidth: 50,
						renderCell: (params: GridRenderCellParams<any, any, any>) => {
							return params.row.isWinningRule ? (
								<CheckCircleOutline fontSize="small" color={"success"} />
							) : (
								<RemoveCircleOutlineIcon fontSize="small" color="error" />
							);
						},
					},
				]
			: []),
		{
			field: "port",
			headerName: "Port",
			width: 120,
			minWidth: 120,
			sortingOrder: ["asc", "desc"],
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<Stack direction={"row"} alignItems={"center"}>
						<PathDetails path={params.row} viewOnly={true} />
						{params.row.channelHash.startsWith("new-rule") && (
							<Chip
								size="small"
								label={window.getCTTranslatedText("New rule")}
								color="info"
							/>
						)}
					</Stack>
				);
			},
			sortComparator: (_, __, params1, params2) => {
				const row1 = params1?.api?.getRow(params1.id);
				const row2 = params2?.api?.getRow(params2.id);

				const port1 = parsePortValue(row1?.port || "");
				const port2 = parsePortValue(row2?.port || "");

				return port1 - port2;
			},
		},
		{
			field: "api",
			headerName: "API",
			width: 150,
			minWidth: 140,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathAPIDetails path={params.row} />;
			},
			sortComparator: (_, __, params1, params2) => {
				const row1 = params1?.api?.getRow(params1.id);
				const row2 = params2?.api?.getRow(params2.id);
				const name1 = `${row1.method} ${row1?.uri}`;
				const name2 = `${row2.method} ${row2?.uri}`;
				return name1?.localeCompare(name2);
			},
		},
		{
			field: "source",
			headerName: "Source",
			width: 320,
			minWidth: 160,
			flex: 0.6,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				if (params.row.sourceTagBasedPolicy) {
					return <PolicyCriteria policy={params.row.sourceTagBasedPolicy} />;
				}
				return <PathSource path={params.row} viewOnly={true} asLink />;
			},
			sortComparator: (_, __, params1, params2) => {
				const row1 = params1?.api?.getRow(params1.id);
				const row2 = params2?.api?.getRow(params2.id);
				const name1 = getSourceSortingName(row1);
				const name2 = getSourceSortingName(row2);

				return name1.localeCompare(name2);
			},
		},
		...(mode === "groupedByRule"
			? [
					{
						field: "templateName",
						headerName: "Template Name",
						flex: 0.4,
						renderCell: (params: GridRenderCellParams<any, any, any>) => {
							return (
								<TemplateNameWithDrawer
									templateId={params?.row?.templateId}
									templateName={params?.row?.templateName}
								/>
							);
						},
					},
				]
			: []),
	];
};

export const INBOUND_PATH_COLUMNS = (
	templateType?: TemplateTypeEnum,
	title?: string,
	portList?: Array<TemplatePort>
): GridColDef[] => {
	return [
		...BASE_INBOUND_PATH_COLUMNS(),
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 80,
			minWidth: 80,
			flex: 0.2,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				const rows = params.api.getAllRowIds().map(id => params.api.getRow(id));

				return (
					<Stack direction="row" alignItems="center" spacing={1}>
						<RemoveTemplateRuleDialog rule={params.row} page="path" />
						{shouldSuggestRestrictPortToAllowedPathsForPaths(
							params.row,
							templateType,
							portList
						) && (
							<RestrictPortToAllowedPaths
								rule={params.row}
								allRules={rows}
								ruleType="PATH"
								templateType={templateType}
								title={title ?? ""}
							/>
						)}
					</Stack>
				);
			},
		},
	];
};

export const BASE_OUTBOUND_PATH_COLUMNS = (mode?: string): GridColDef[] => {
	return [
		...(mode === "groupedByRule"
			? [
					{
						field: "isWinningRule",
						headerName: "",
						sortable: false,
						width: 50,
						minWidth: 50,
						renderCell: (params: GridRenderCellParams<any, any, any>) => {
							return params.row.isWinningRule ? (
								<CheckCircleOutline fontSize="small" color={"success"} />
							) : (
								<RemoveCircleOutlineIcon fontSize="small" color="error" />
							);
						},
					},
				]
			: []),
		{
			field: "port",
			headerName: "Port",
			width: 120,
			minWidth: 120,
			sortingOrder: ["asc", "desc"],
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return (
					<Stack direction={"row"} alignItems={"center"}>
						<PathDetails path={params.row} viewOnly={true} />
						{params.row.channelHash.startsWith("new-rule") && (
							<Chip
								size="small"
								label={window.getCTTranslatedText("New rule")}
								color="info"
							/>
						)}
					</Stack>
				);
			},
			sortComparator: (_, __, params1, params2) => {
				const row1 = params1?.api?.getRow(params1.id);
				const row2 = params2?.api?.getRow(params2.id);

				const port1 = parsePortValue(row1?.port || "");
				const port2 = parsePortValue(row2?.port || "");

				return port1 - port2;
			},
		},
		{
			field: "api",
			headerName: "API",
			width: 150,
			minWidth: 150,
			flex: 0.4,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <PathAPIDetails path={params.row} />;
			},
			sortComparator: (_, __, params1, params2) => {
				const row1 = params1?.api?.getRow(params1.id);
				const row2 = params2?.api?.getRow(params2.id);
				const name1 = `${row1.method} ${row1?.uri}`;
				const name2 = `${row2.method} ${row2?.uri}`;
				return name1?.localeCompare(name2);
			},
		},
		{
			field: "domain",
			headerName: "Destination",
			width: 320,
			minWidth: 160,
			flex: 0.6,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				if (params.row.destinationTagBasedPolicy) {
					return (
						<PolicyCriteria policy={params.row.destinationTagBasedPolicy} />
					);
				}
				return <PathDestination path={params.row} viewOnly={true} asLink />;
			},
			sortComparator: (_, __, params1, params2) => {
				const row1 = params1?.api?.getRow(params1.id);
				const row2 = params2?.api?.getRow(params2.id);
				const name1 = getDestinationSortingName(row1);
				const name2 = getDestinationSortingName(row2);

				return name1.localeCompare(name2);
			},
		},
		...(mode === "groupedByRule"
			? [
					{
						field: "templateName",
						headerName: "Template Name",
						flex: 0.4,
						renderCell: (params: GridRenderCellParams<any, any, any>) => {
							return (
								<TemplateNameWithDrawer
									templateId={params?.row?.templateId}
									templateName={params?.row?.templateName}
								/>
							);
						},
					},
				]
			: []),
	];
};

export const OUTBOUND_PATH_COLUMNS = (mode?: string): GridColDef[] => {
	return [
		...BASE_OUTBOUND_PATH_COLUMNS(mode),
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 80,
			minWidth: 80,
			flex: 0.2,
			renderCell: (params: GridRenderCellParams<any, any, any>) => {
				return <RemoveTemplateRuleDialog rule={params.row} page="path" />;
			},
		},
	];
};

export const BASE_TEMPLATES_LIST_COLUMNS: GridColDef[] = [
	{
		field: "oobTemplate",
		headerName: "",
		width: 70,
		minWidth: 70,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => (
			<Stack>
				<TemplateTypeIcon template={params?.row} />
			</Stack>
		),
	},
	{
		field: "templateName",
		headerName: "Name",
		width: 140,
		minWidth: 140,
		flex: window.screen.availWidth > 1200 ? 0.4 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip title={params?.row?.templateName || ""}>
					<Typography
						variant="body2"
						noWrap
						sx={{ textTransform: "capitalize" }}
					>
						{params?.row?.templateName || ""}
					</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "templateDescription",
		headerName: "Description",
		sortable: false,
		width: 180,
		minWidth: 140,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return (
				<Tooltip title={params?.row?.templateDescription || ""}>
					<Typography variant="body2" noWrap>
						{params?.row?.templateDescription || ""}
					</Typography>
				</Tooltip>
			);
		},
	},
	{
		field: "templateType",
		headerName: "Type",
		width: 160,
		minWidth: 140,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <TemplateType template={params?.row} />;
		},
	},
];

export const TEMPLATES_LIST_COLUMNS: GridColDef[] = [
	...BASE_TEMPLATES_LIST_COLUMNS,
	{
		field: "templateAssignments",
		headerName: "Used by",
		width: 160,
		minWidth: 140,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetCount template={params?.row} viewOnly={true} />;
		},
	},
	{
		field: "templateUnassignmentsPendingFirewallSynchronize",
		headerName: "",
		sortable: false,
		width: 70,
		minWidth: 70,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <TemplatePendingFirewall template={params?.row} />;
		},
	},
];

export const ASSET_TEMPLATES_COLUMNS: GridColDef[] = [
	...BASE_TEMPLATES_LIST_COLUMNS,
	{
		field: "action",
		headerName: "Action",
		sortable: false,
		width: 70,
		minWidth: 70,
		flex: window.screen.availWidth > 1200 ? 0.2 : undefined,
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <RemoveTemplateDialog template={params.row} />;
		},
	},
];

export const TAG_TEMPLATES_COLUMNS: GridColDef[] = [
	...BASE_TEMPLATES_LIST_COLUMNS,
	{
		field: "templateAssignments",
		headerName: "Used by",
		width: 160,
		minWidth: 160,
		flex: window.screen.availWidth > 1200 ? 0.3 : undefined,
		sortingOrder: ["asc", "desc"],
		renderCell: (params: GridRenderCellParams<any, any, any>) => {
			return <AssetCount template={params?.row} viewOnly={true} />;
		},
	},
];

export const ASSIGNED_TEMPLATES_TAG_COLUMNS: GridColDef[] = [
	...BASE_TEMPLATES_LIST_COLUMNS,
];

const ColumnVisibilityModel: { [key: string]: boolean } = {
	// Hide columns from the display
};

const PinnedColumns = {
	left: ["isoobtemplate", "templateName"],
	right: ["action", "comment"],
};

export const TemplateColumnConfig = {
	ColumnVisibilityModel: ColumnVisibilityModel,
	PinnedColumns: PinnedColumns,
};
