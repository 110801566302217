import { Box, Stack, debounce } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { SortOrder } from "modules/core/types";
import { DataGrid } from "modules/data-grid/components/data-grid";
import { useExportCSV } from "modules/export-csv/hooks";
import { Scope } from "modules/scope-metadata/types";
import { useVulnerabilityStore } from "modules/vulnerability-drawer/stores";
import { useEffect, useMemo, useState } from "react";
import { VulnerabilityBody } from "../../types";
import { VULNERABILITY_COLUMNS } from "./constants";
import { getVulnerabilitySeverityText } from "./utils";

interface VulnerabilityDataGridProps {
	assetId: string;
	assetType: string;
	vulnerabilityType?: string;
}

const useVulnerabilitiesAPI = (
	assetType: string,
	assetId: string,
	vulnerabilityType?: string
) => {
	let params = "computeTotal=true";
	if (vulnerabilityType) {
		params = params + `&vulnerabilityType=${vulnerabilityType}`;
	}
	return useMutation<any, Error, any>([
		"vulnerability-list",
		`${assetType}/${assetId}/vulnerabilities?${params}`,
	]);
};

export const VulnerabilityDataGrid = (props: VulnerabilityDataGridProps) => {
	const defaultSortOrder: Array<SortOrder> = [
		{ field: "severity", order: "desc" },
	];

	const vulnerabilitiesAPI = useVulnerabilitiesAPI(
		props.assetType,
		props.assetId,
		props.vulnerabilityType
	);

	const [sort, setSort] = useState<Array<SortOrder>>(defaultSortOrder);
	const [rows, setRows] = useState<Array<VulnerabilityBody> | undefined>(
		undefined
	);
	const [totalCount, setTotalCount] = useState(0);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const apiRefreshRequest = useVulnerabilityStore(
		(state: { apiRefreshRequest: any }) => state.apiRefreshRequest
	);

	const mutate = useMemo(
		() => debounce(vulnerabilitiesAPI.mutate, 300),
		[vulnerabilitiesAPI.mutate]
	);

	useEffect(() => {
		const search = {
			pagination: {
				offset: page * pageSize,
				limit: pageSize,
				sort,
			},
		};

		const dataMapper = (pack: VulnerabilityBody) => {
			return pack;
		};
		mutate(search, {
			onSuccess(data) {
				if (data) {
					const responseData = data;
					setRows((responseData?.items || []).map(dataMapper) || []);
					setTotalCount(responseData?.metadata.total || 0);
				}
			},
		});
	}, [props, mutate, page, pageSize, sort, apiRefreshRequest]);

	let hasExploit = useMemo(() => {
		return rows?.some((row: VulnerabilityBody) => {
			return Boolean(row.exploitURL);
		});
	}, [rows]);

	let hasAffectedPorts = useMemo(() => {
		return rows?.some((row: VulnerabilityBody) => {
			return Boolean(row?.affectedPorts && row.affectedPorts.length > 0);
		});
	}, [rows]);

	const columns = useMemo(
		() =>
			VULNERABILITY_COLUMNS.filter(column => {
				if (column.field === "exploitURL") {
					return hasExploit;
				}
				if (column.field === "affectedPorts") {
					return hasAffectedPorts;
				}

				return true;
			}),
		[hasExploit, hasAffectedPorts]
	);

	const {
		triggerExportAsCsv,
		getExportStatus,
		getUrlToDownload,
		resetDownloadUrl,
	} = useExportCSV({
		useApi: () =>
			useVulnerabilitiesAPI(
				props.assetType,
				props.assetId,
				props.vulnerabilityType
			),
		page: 0,
		sort,
		scope: Scope.Asset,
		destinationCriteria: "",
		sourceCriteria: "",
		dataMapper: (item: VulnerabilityBody) => {
			const obj: Record<string, any> = {};

			VULNERABILITY_COLUMNS.forEach(column => {
				const field = column.field as keyof VulnerabilityBody;
				if (field === "severity") {
					obj[field] = getVulnerabilitySeverityText(item[field]);
				} else {
					obj[field] = String(item[field] ?? "");
				}
			});

			return obj as VulnerabilityBody;
		},
		removeSearchFields: ["criteria", "facetFields"],
	});

	return (
		<Stack sx={{ width: "100%", height: "100%" }}>
			<Box sx={{ flex: 1, overflow: "hidden" }}>
				<DataGrid
					rowHeight={64}
					columns={columns}
					pagination
					getRowId={({ cveID }: VulnerabilityBody) => cveID}
					paginationMode="server"
					sortingMode="server"
					initialState={{
						sorting: {
							sortModel: [{ field: "severity", sort: "desc" }],
						},
					}}
					triggerExportAsCsv={triggerExportAsCsv}
					getExportStatus={getExportStatus}
					getUrlToDownload={getUrlToDownload}
					resetDownloadUrl={resetDownloadUrl}
					frontendOnly
					isLoading={vulnerabilitiesAPI.isLoading || !rows}
					rows={rows}
					rowCount={totalCount}
					onPageChange={setPage}
					page={page}
					pageSize={pageSize}
					onPageSizeChange={setPageSize}
					onSortChange={sortModel => {
						if (sortModel?.length > 0) {
							setSort(sortModel);
						}
					}}
				/>
			</Box>
		</Stack>
	);
};
