export const getVulnerabilitySeverityText = (severity: number) => {
	switch (severity) {
		case 3:
			return window.getCTTranslatedText("High");
		case 4:
			return window.getCTTranslatedText("High");
		case 5:
			return window.getCTTranslatedText("Critical");
		default:
			return "";
	}
};
