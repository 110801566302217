import { Button, Stack } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid-pro";
import { useUserPermissionsStore } from "hooks/useUserPermission/store";
import { AddToTemplateDrawer } from "modules/add-to-templates/components/add-to-template-drawer";
import { TemplateActionType } from "modules/add-to-templates/components/AddToTemplateDialog/types";
import { CreateTagBasedPolicyDrawer } from "modules/create-tag-based-policy-drawer";
import {
	GridToolbar,
	ToolbarProps,
} from "modules/data-grid/components/data-grid-toolbar";
import { usePathStore } from "pages/paths/store";
import { Path } from "pages/paths/types";
import { useEffect, useMemo, useState } from "react";

export interface PathToolbarProps extends Omit<ToolbarProps, "children"> {
	selectedData: Array<Path> | undefined;
	showSrcDestOption: boolean;
	recommendationId?: string;
	selection?: Array<GridRowId>;
	clearSelection: Function;
}

export default function PathToolbar(props: PathToolbarProps) {
	const userPermissions = useUserPermissionsStore(
		state => state.userPermissions
	);
	const requestAPIRefresh = usePathStore(store => store.requestAPIRefresh);
	const [showAddToTemplateDialog, setShowAddToTemplateDialog] = useState(false);

	const [isCreateTagPolicyDrawerOpen, setIsCreateTagPolicyDrawerOpen] =
		useState(false);

	useEffect(() => {
		if (props.recommendationId === "templatizecoreinfraservices") {
			handleAddToTemplateDialogOpen(true);
		}
	}, [props.recommendationId]);

	const handleAddToTemplateDialogOpen = (dialogVisibility: boolean) => {
		setShowAddToTemplateDialog(dialogVisibility);
	};

	const handleClose = () => {
		setShowAddToTemplateDialog(false);
	};

	const addToTemplate = () => {
		setShowAddToTemplateDialog(false);
		requestAPIRefresh();
		props.clearSelection();
	};

	const onCreateTagBasedPolicyDrawerClose = () => {
		setIsCreateTagPolicyDrawerOpen(false);
		props.clearSelection();
	};

	const isCreateTagPolicyButtonDisable = useMemo(() => {
		const selectedData = props.selectedData;
		if (!selectedData || selectedData.length !== 1) {
			return true;
		}
		return !selectedData.every(
			pathData =>
				Object.keys(pathData.destinationAsset?.coreTags ?? {}).length > 0 &&
				Object.keys(pathData.sourceAsset?.coreTags ?? {}).length > 0
		);
	}, [props.selectedData]);

	return (
		<GridToolbar
			{...props}
			requestAPIRefresh={requestAPIRefresh}
			hiddenColumns={props.hiddenColumns}
		>
			{(props.selection?.length ?? 0) > 0 && (
				<Stack
					direction="row"
					spacing={2}
					sx={{ width: "100%", p: 1 }}
					justifyItems="flex-end"
					alignItems={"flex-end"}
					justifyContent="flex-end"
				>
					<AddToTemplateDrawer
						isOpen={showAddToTemplateDialog}
						page={"paths"}
						title="Add to Template"
						rules={props.selectedData}
						showSrcDestOption={props.showSrcDestOption}
						onCancel={handleClose}
						onConfirm={addToTemplate}
						btnTitle={"add"}
						actionType={TemplateActionType.add}
					/>

					<Stack direction="row" alignItems={"center"}>
						{userPermissions.has("CREATE_TAGBASEDPOLICY") && (
							<Button
								color="primary"
								variant="contained"
								onClick={() => setIsCreateTagPolicyDrawerOpen(true)}
								disabled={isCreateTagPolicyButtonDisable}
							>
								{window.getCTTranslatedText("create access policy")}
							</Button>
						)}

						{userPermissions.has("UPDATE_TEMPLATE") && (
							<Button
								color="primary"
								variant="contained"
								onClick={() => handleAddToTemplateDialogOpen(true)}
								sx={{ marginLeft: 2 }}
							>
								{window.getCTTranslatedText("Add to template")}
							</Button>
						)}
					</Stack>

					{isCreateTagPolicyDrawerOpen && props?.selectedData?.[0] && (
						<CreateTagBasedPolicyDrawer
							isOpen={isCreateTagPolicyDrawerOpen}
							onClose={onCreateTagBasedPolicyDrawerClose}
							defaultDestinationCoreTags={
								props?.selectedData?.[0]?.destinationAsset?.coreTags
							}
							defaultSourceCoreTags={
								props?.selectedData?.[0]?.sourceAsset?.coreTags
							}
							templateRules={[
								{
									listenPort: props?.selectedData?.[0]?.port,
									listenPortProtocol: props?.selectedData?.[0]?.protocol,
									listenPortReviewed: props?.selectedData?.[0]?.reviewed,
									listenProcessNames: props?.selectedData?.[0]?.process,
								},
							]}
						/>
					)}
				</Stack>
			)}
		</GridToolbar>
	);
}
