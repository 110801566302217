import { GridRowId } from "@mui/x-data-grid-pro";
import { Scope } from "modules/scope-metadata/types";
import {
	ProgressiveEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatus,
} from "pages/ports/types";
import { VulnerabilitySeverity } from "./components/asset-vulnerability-severity/AssetVulnerabilitySeverity";
export interface AssetColumnRenderProps {
	asset: Asset;
	viewOnly?: boolean;
}

export interface SecurityCoverage {
	overallPercent: number;
}

/*
inbound-internet-ports, no outbound support for ports
inbound-intranet-ports, no outbound support for ports
outbound-internet-paths, no inbound internet paths
inbound-intranet-paths
outbound-intranet-paths
*/

export interface CoverageCount {
	total?: number;
	unreviewed: number;
	reviewed?: number;
	allowed?: number;
	allowedPorts?: number;
	denied?: number;
}
export interface ReviewCoverage {
	inboundInternetPorts: CoverageCount;
	// outboundInternetPorts: number; Not supported for now
	inboundIntranetPorts: CoverageCount;
	// outboundIntranetPorts: number; Not supported for now
	inboundInternetPaths: CoverageCount;
	outboundInternetPaths: CoverageCount;
	inboundIntranetPaths: CoverageCount;
	outboundIntranetPaths: CoverageCount;
}

export interface PendingReviewCount {
	internetPorts: number;
	internetPaths: number;
	intranetPorts: number;
	intranetPaths: number;
	blockTemplates: Array<string> | undefined;
	intranetChange: Array<string> | undefined;
	allowTemplates?: Array<string> | null;
	namednetworkChange?: Array<string> | null;
	unassignedAllowTemplates?: Array<string> | null;
	unassignedBlockTemplates?: Array<string> | null;
	progressiveSyncPending?: boolean;
	fwCoexistenceCfgUpdatePending?: boolean;
}

export interface User {
	name: string;
	domainname: string;
	logincount: number;
	signedin: boolean;
	email: string;
	scimuserid: string;
	userEmail: string;
}

export interface UserGroup {
	groupid: string;
	groupName?: string;
}

export enum AssetAvailabilityEnums {
	Disconnected = "disconnected",
	Connected = "connected",
	Active = "active",
}

export enum AssetSecurityCategory {
	INBOUND = "inbound",
	OUTBOUND = "outbound",
}

export interface PendingNamedNetworkProps {
	namedNetworkName: string;
	namedNetworkId: string;
}

export interface PendingTemplatesProps {
	templateName: string;
	templateId: string;
}

export interface Asset {
	assetId: string;
	assetName: string;
	agentName?: string;
	hostName?: string;
	coreTags: { [key: string]: string };
	tags: Array<AssetTag>;
	cloudTags: Array<AssetTag>;
	osName: string;
	attackSurface: SecurityLevels;
	blastRadius: SecurityLevels;
	assetInternetFacing: boolean;
	inboundAssetStatus: AssetStatus;
	outboundAssetStatus: AssetStatus;
	businessValue: BusinessValue;
	inboundPaths: number;
	otherPaths: number;
	unreviewedPaths: number;
	totalPaths: number;
	reviewCoverage: ReviewCoverage;
	assetRisk: SecurityLevels;
	vulnerabilities: number;
	vulnerabilitySeverity: VulnerabilitySeverity;
	lateralMovementVulnerability: string;
	knownExploitVulnerability: string;
	securityPatches: number;
	totalInboundComments: number;
	totalOutboundComments: number;
	recommendation?: AssetRecommendation;
	newPathProcessingStopped?: boolean;
	fwCoexistenceCfgStatus: "enabled" | "disabled";
	pendingFWCoexistenceUpdateChanges?: boolean;
	type: AssetType;
	agentVersion?: string;
	agentId: string;
	agentStatus: string;
	cpuCoreCount: number;
	ramCapacityInMB: number;
	platform: string;
	diskCapacityInGB: number;
	kernelVersion: string;
	kernelArchitecture: string;
	virtualizationSystem: string;
	cpuName: string;
	totalAllowedPaths: number;
	totalDeniedPaths: number;
	totalInternetPaths: number;
	unreviewedInternetPaths: number;
	reviewedInboundInternetPaths: number;
	allowedInternetPaths: number;
	deniedInternetPaths: number;
	totalInboundPaths: number;
	unreviewedInboundPaths: number;
	reviewedInboundPaths: number;
	allowedInboundPaths: number;
	deniedInboundPaths: number;
	totalOtherPaths: number;
	allowedOtherPaths: number;
	deniedOtherPaths: number;
	unreviewedOtherPaths: number;
	reviewedOtherPaths: number;
	totalPorts: number;
	unreviewedPorts: number;
	allowAnyPorts: number;
	allowIntranetPorts: number;
	deniedPorts: number;
	totalInboundInternetPorts: number;
	unreviewedInboundInternetPorts: number;
	reviewedInboundInternetPorts: number;
	allowAnyInboundInternetPorts: number;
	allowIntranetInboundInternetPorts: number;
	deniedInboundInternetPorts: number;
	totalInboundPorts: number;
	unreviewedInboundPorts: number;
	reviewedInboundPorts: number;
	allowAnyInboundPorts: number;
	allowIntranetInboundPorts: number;
	deniedInboundPorts: number;
	disruptionScore: number;
	mostRecentNewPath: string;
	clusterIdentifier?: string;
	containerNamespace?: string;
	vendorInfo?: string;
	lastPolicyDeploymentTriggeredAt?: string;

	inboundInternetPorts: CoverageCount;
	inboundIntranetPorts: CoverageCount;
	inboundInternetPaths: CoverageCount;
	outboundInternetPaths: CoverageCount;
	inboundIntranetPaths: CoverageCount;
	outboundIntranetPaths: CoverageCount;
	usergroupOutboundIntranetPaths?: CoverageCount;
	usergroupOutboundInternetPaths?: CoverageCount;

	totalPortsPathRestricted: number;
	policyStatus: PolicyStatus;
	attackSurfacePendingChanges?: PendingReviewCount;
	blastRadiusPendingChanges?: PendingReviewCount;
	templatesAssigned?: number;
	namednetworksAssigned?: number;
	simulateInboundAssetStatus?: boolean;
	simulateOutboundAssetStatus?: boolean;
	autoSynchronizeEnabled?: boolean;
	lowestInboundAssetStatus?: AssetStatus;
	lowestOutboundAssetStatus?: AssetStatus;
	lowestProgressiveInboundPolicyStatus?: ProgressiveEnforcementStatus;
	lowestProgressiveInboundAssetStatus?: ProgressiveEnforcementStatus;
	lowestProgressiveOutboundPolicyStatus?: ProgressiveOutboundPortEnforcementStatus;
	lowestProgressiveOutboundAssetStatus?: ProgressiveOutboundPortEnforcementStatus;
	assetProgressiveLastRefreshed?: string;
	assetProgressiveOutboundLastRefreshed?: string;

	interfaces?: Array<NetworkInterface>;
	ipAddresses?: string;
	macAddresses?: string;
	users?: Array<User>;
	usergroups: Array<UserGroup>;
	assetAvailability?: AssetAvailabilityEnums;
	pendingAttackSurfaceChanges?: boolean;
	pendingBlastRadiusChanges?: boolean;
	totalComments: number;
	inboundUserStatus?: AssetStatus;
	outboundUserStatus?: AssetStatus;

	policiesAssigned: number;
	templateChanges?: PendingTemplatesProps[] | null;
	namedNetworkChanges?: PendingNamedNetworkProps[] | null;
	loggedinUser?: string;
	currentTrafficConfiguration?: string;
}

export interface NetworkInterface {
	name: string;
	macaddress?: string;
	flags?: Array<string>;
	ipaddresses?: Array<string>;
}

export enum PolicyStatus {
	Synchronized = "synchronized",
}

export enum RecommendedAction {
	ReviewInboundInternetPorts = "review-inbound-internet-ports",
	SecureInboundInternetPorts = "secure-inbound-internet-ports",
	ReviewInboundIntranetPorts = "review-inbound-intranet-ports",
	SecureInboundIntranetPorts = "secure-inbound-intranet-ports",
	ReviewOutboundInternetPaths = "review-outbound-internet-paths",
	SecureOutboundInternetPaths = "secure-outbound-internet-paths",
	ReviewInboundIntranetPaths = "review-inbound-intranet-paths",
	SecureInboundIntranetPaths = "secure-inbound-intranet-paths",
	ReviewOutboundIntranetPaths = "review-outbound-intranet-paths",
	SecureOutboundIntranetPaths = "secure-outbound-intranet-paths",
	ReviewComplete = "complete-review",
}

export enum AssetStatus {
	None = "none",
	Unsecured = "unsecured",
	SimulateSecureInternet = "simulate-secure-internet",
	SecureInternet = "secure-internet",
	SimulateSecureAll = "simulate-secure-all",
	SecureAll = "secure-all",
}

export enum AssetProperties {
	CPUName = "cpuName",
	KernelArchitecture = "kernelArchitecture",
	CPUCores = "cpuCoreCount",
	KernelVersion = "kernelVersion",
	RAMCapacityMB = "ramCapacityInMB",
	DiskCapacityGB = "diskCapacityInGB",
	Platform = "platform",
	VirtualizationSystem = "virtualizationSystem",
}

export const SimulatationStatusList: { [key: string]: boolean } = {
	[AssetStatus.SimulateSecureInternet]: true,
	[AssetStatus.SimulateSecureAll]: true,
};

export enum AssetType {
	Server = "server",
	Service = "service",
	User = "endpoint",
	Device = "device",
	UserGroup = "usergroup",
	Endpoint = "endpoint",
	EdrGroup = "edrgroup",
}

export enum OSType {
	Windows = "windows",
	Linux = "linux",
	Container = "container",
	Docker = "docker",
	Darwin = "darwin",
	AIX = "AIX",
	Solaris = "solaris",
	SunOS = "SunOS",
}
export interface AssetRecommendation {
	id: RecommendedAction;
	displayName: string;
}

export enum BusinessValue {
	High = "high",
	Medium = "medium",
	Low = "low",
}

export enum HighAssetRisk {
	Critical = "critical",
	High = "high",
}

export enum SecurityLevels {
	Low = "low",
	Medium = "medium",
	High = "high",
	Critical = "critical",
}

export enum SecurityLevelsBg {
	Critical = "criticalbg",
	High = "highbg",
	Medium = "mediumbg",
	Low = "lowbg",
}

export enum CoreTagsKeys {
	Application = "application",
	Environment = "environment",
	Location = "location",
	Owner = "owner",
	Role = "role",
	UserGroup = "usergroup",
	Department = "department",
	Model = "model",
	Category = "category",
	SubCategory = "subcategory",
	Manufacturer = "manufacturer",
	BusinessValue = "businessvalue",
	ClusterIdentifier = "clusteridentifier",
	ContainerNamespace = "containernamespace",
	KernelVersion = "kernelversion",
	PrimarySubnet = "primarysubnet",
	SerialNumber = "serialnumber",
}
export const CoreTagsLabels = {
	[CoreTagsKeys.Application]: "Application",
	[CoreTagsKeys.Location]: "Location",
	[CoreTagsKeys.Role]: "Role",
	[CoreTagsKeys.Owner]: "Owner",
	[CoreTagsKeys.Environment]: "Environment",
	[CoreTagsKeys.UserGroup]: "Group",
	[CoreTagsKeys.Department]: "Department",
	[CoreTagsKeys.Model]: "Model",
	[CoreTagsKeys.Category]: "Category",
	[CoreTagsKeys.SubCategory]: "Sub Category",
	[CoreTagsKeys.Manufacturer]: "Manufacturer",
	[CoreTagsKeys.BusinessValue]: "Business Value",
	[CoreTagsKeys.ClusterIdentifier]: "Cluster Identifier",
	[CoreTagsKeys.ContainerNamespace]: "Container Namespace",
	[CoreTagsKeys.KernelVersion]: "Version",
	[CoreTagsKeys.PrimarySubnet]: "Primary Subnet",
	[CoreTagsKeys.SerialNumber]: "Serial Number",
};

export interface AssetMetaDataReadonlyProps {
	asset?: Asset;
}

export interface AssetMetaDataProps extends AssetMetaDataReadonlyProps {
	updateAssetMetadata: Function;
}

export interface AssetMetaDataEditProps extends AssetMetaDataProps {
	handleAllowEdit: (bool: boolean) => void;
	allowEdit: boolean;
	assetIds?: Array<GridRowId>;
	mode?: string;
	scope?: Scope;
	title?: string;
	infoText?: string;
}

export interface AssetMetaDataEditViewProps extends AssetMetaDataProps {
	handleAllowEdit: (bool: boolean) => void;
	handleViewMore: Function;
	allowEdit: boolean;
	assetIds?: Array<GridRowId>;
	mode?: string;
}

export interface AssetMetaDataViewProps extends AssetMetaDataProps {
	handleViewMore: Function;
	viewMore: boolean;
	asset?: Asset;
}

export interface AssetCoreTagBaseProps extends AssetMetaDataReadonlyProps {
	coreTagKey: CoreTagsKeys | string;
}

export interface AssetCoreTagReadonlyProps extends AssetCoreTagBaseProps {
	index: number;
	isUserDefinedTagsExist?: boolean;
}

export interface AssetCoreTagEditProps extends AssetCoreTagBaseProps {
	handleUpdate: Function;
	getTagIcon?: Function;
	scope?: Scope;
}

export interface MetaDataItemProps {
	label: CoreTagsKeys | string;
	value: string | BusinessValue | Array<User>;
	icon: JSX.Element | Element;
}

export interface AssetTag {
	id: string;
	key: string;
	value: string;
	isCloudTag: boolean;
}

export enum SecurityType {
	ATTACK_SURFACE = "attacksurface",
	BLAST_RADIUS = "blastradius",
}

export enum SegmentationLevels {
	UnTagged = "true",
	Tagged = "false",
}

export const MAX_DELETE_COUNT = 10;
