import { useEffect, useRef, useState } from "react";

function useDelayedLoading(isLoading: boolean) {
	const isLoadingRef = useRef(isLoading);
	const [isDelayedLoading, setIsDelayedLoading] = useState(false);

	const DELAY = 2720;
	let hasTimeoutCompleted = useRef<boolean | undefined>(undefined);

	useEffect(() => {
		isLoadingRef.current = isLoading;

		if (isLoading) {
			hasTimeoutCompleted.current = undefined;
			setIsDelayedLoading(true);
			setTimeout(() => {
				hasTimeoutCompleted.current = true;
				setIsDelayedLoading(isLoadingRef.current);
			}, DELAY);
		}
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && hasTimeoutCompleted.current) {
			setIsDelayedLoading(false);
		}
	}, [isLoading]);

	return isDelayedLoading;
}

export default useDelayedLoading;
