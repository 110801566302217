import {
	Alert,
	Box,
	Button,
	DialogActions,
	DialogContent,
	FormControl,
	FormControlLabel,
	FormGroup,
	Stack,
	Switch,
	Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CTInputField } from "common/atoms/ct-input-field";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import {
	NOTIFY_ACTIONS,
	useEventSubscriptionStore,
} from "common/store/useEventSubscriptionStore";
import { parseErrorMessage } from "common/utils";
import * as isCidr from "is-cidr";
import { ToolbarAction } from "modules/drawer/toolbar-actions";
import { useSnackbarStore } from "modules/snackbar/store";
import { SnackBarSeverity } from "modules/snackbar/store/types";
import {
	IPRange,
	IPRangeInt,
	NetworkDetailInt,
	NetworkFormProps,
} from "pages/networks/types";
import {
	isValidIp,
	isValidateIPV4Range,
	isValidateIPV6Range,
} from "pages/templates/components/template-form-drawer/components/template-form/helpers/validators";
import AddIPInput from "./AddIPInput";
// import { Annotation } from "modules/annotation";

const rulesSectionWrapper = {
	py: 1,
	px: 3,
	borderRadius: 1,
};

export enum NetworkFieldKeys {
	Name = "namedNetworkName",
	Description = "namedNetworkDescription",
	Domain = "domain",
	IPRange = "iprange",
}

export const NetworkFieldOptions: Array<NetworkFieldKeys> = [
	NetworkFieldKeys.Name,
	NetworkFieldKeys.Description,
	NetworkFieldKeys.Domain,
];

export const NetworkFieldDisplayText = {
	[NetworkFieldKeys.Name]: "Name",
	[NetworkFieldKeys.Description]: "Description",
	[NetworkFieldKeys.Domain]: "Domain",
	[NetworkFieldKeys.IPRange]: "IP Range",
};

export const RequiredNetworkFields: { [key: string]: boolean } = {
	[NetworkFieldKeys.Name]: true,
	[NetworkFieldKeys.IPRange]: true,
};

export interface NetworkCreateFormProps {
	network?: NetworkFormProps | NetworkDetailInt | undefined;
	title?: string;
	updateNetworkData: Function;
	cancel: () => void;
	mode: "create" | "edit" | "add";
	btnTitle?: string;
	cidrList?: string[];
}

const useCreateNetworksAPI = () => {
	return useMutation<any, Error, any>(["networks", "namednetworks"]);
};

const useEditNetworksAPI = (namedNetworkId: string | undefined) => {
	const path = `namednetworks/${namedNetworkId}`;
	return useMutation<any, Error, any>(["networks", path, "put"]);
};

const useAddNetworksAPI = (namedNetworkId: string | undefined) => {
	const path = `namednetworks/${namedNetworkId}/networkranges`;
	return useMutation<any, Error, any>(["networks", path, "put"]);
};

export function NetworkForm({
	network,
	title,
	updateNetworkData,
	cancel,
	mode,
	btnTitle = "create",
	cidrList,
}: NetworkCreateFormProps) {
	const notify = useEventSubscriptionStore(state => state.notify);
	const [namedNetworkName, setNetworkName] = useState(
		network?.namedNetworkName || ""
	);
	const [namedNetworkDescription, setNetworkDescription] = useState(
		network?.namedNetworkDescription || ""
	);
	const [domain, setDomain] = useState(network?.domain || "");
	const [ipRangesList, setIPRangesList] = useState<Array<IPRangeInt>>(
		network?.ipRangesList || [{ value: "" }]
	);
	const [isProgrammedAsIntranetChecked, setIsProgramAsIntranetChecked] =
		useState(network?.programAsIntranet ?? false);

	const handlerOnSwitchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		let receivedEvent = event.target.checked;
		setIsProgramAsIntranetChecked(receivedEvent);
	};

	useEffect(() => {
		if (cidrList?.length) {
			setIPRangesList(
				cidrList.map((item, index) => ({
					id: `${index}`,
					value: item,
				}))
			);
		}
	}, [cidrList]);

	const setSnackbar = useSnackbarStore(state => state.setSnackbar);

	const networkId = network?.namedNetworkId;

	const createNetworksAPI = useCreateNetworksAPI();
	const editNetworksAPI = useEditNetworksAPI(networkId);
	const addNetworksAPI = useAddNetworksAPI(networkId);

	const [loading, setLoading] = useState(false);

	const queryClient = useQueryClient();

	useEffect(() => {
		if (mode === "add") {
			if (cidrList?.length) {
				setIPRangesList(
					cidrList.map((item, index) => ({
						id: `${index}`,
						value: item,
					}))
				);
			} else {
				const newIPRanges = [{ value: "" }];
				setIPRangesList(newIPRanges);
			}
		}
	}, [cidrList, mode]);

	const isValidNetwork = () => {
		let isValid = false;

		const ipRanges = (ipRangesList || []).filter(item => {
			return item?.value.trim() !== "";
		});

		let validIpranges = false;

		if (ipRanges?.length > 0) {
			validIpranges = ipRanges.every(network => {
				return (
					(isCidr.v4(network?.value) ||
						isCidr.v6(network?.value) ||
						isValidIp(network?.value) ||
						isValidateIPV4Range(network?.value) ||
						isValidateIPV6Range(network?.value)) &&
					!isDuplicate(network?.value)
				);
			});
		}

		const isNameValid = namedNetworkName?.trim().length > 0;

		if (validIpranges && isNameValid) {
			isValid = true;
		} else {
			isValid = false;
		}

		return isValid;
	};

	const isDuplicate = (val: string) => {
		if (mode === "edit") {
			return false;
		}
		return network?.ipRangesList?.some(ip => val === ip.value) ?? false;
	};

	const getValidNetworks = () => {
		const validIPRanges: Array<IPRange> = (ipRangesList || []).reduce(
			(filtered: Array<IPRange>, network) => {
				if (
					isCidr.v4(network?.value) ||
					isCidr.v6(network?.value) ||
					isValidIp(network?.value) ||
					isValidateIPV4Range(network?.value) ||
					isValidateIPV6Range(network?.value)
				) {
					const networkObj: IPRange = { ipRange: network?.value };
					filtered.push(networkObj);
				}
				return filtered;
			},
			[]
		);
		return validIPRanges;
	};

	const updateNetworkDetails = () => {
		if (isValidNetwork()) {
			setLoading(true);

			const body: NetworkFormProps = {
				namedNetworkName: namedNetworkName,
				namedNetworkDescription: namedNetworkDescription,
				domain: domain,
				// comment,
			};

			if (mode === "create") {
				body["ipRanges"] = getValidNetworks();
				createNetworks(body);
			} else if (mode === "edit") {
				body["programAsIntranet"] = isProgrammedAsIntranetChecked;
				editNetworks(body);
			} else if (mode === "add") {
				let networkbody: Array<IPRange> = getValidNetworks();
				addNetworks(networkbody);
			}
		}
	};

	const addNetworks = (body: Array<IPRange>) => {
		addNetworksAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["networks"],
				});
				updateNetworkData({
					ipRanges: ipRangesList.map(range => {
						return {
							ipRange: range?.value,
						};
					}),
					ipRangesList: ipRangesList,
				});
				setSnackbar(
					true,
					SnackBarSeverity.Success,
					"IPRangesAddedToNetworkSuccessfully"
				);
				setTimeout(() => {
					cancel();
					setLoading(false);
				}, 300);
			},
			onError: error => {
				setLoading(false);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const createNetworks = (body: NetworkFormProps) => {
		createNetworksAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["networks"],
				});
				updateNetworkData({
					namedNetworkName,
					namedNetworkDescription,
					domain,
					...response,
				});
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "CreateNamedNetworkRequestSubmittedSuccessfully",
				});
				setTimeout(() => {
					cancel();
					setLoading(false);
				}, 300);
			},
			onError: error => {
				setLoading(false);
				setSnackbar(true, SnackBarSeverity.Error, parseErrorMessage(error));
			},
		});
	};

	const editNetworks = (body: NetworkFormProps) => {
		editNetworksAPI.mutate(body, {
			onSuccess: response => {
				queryClient.invalidateQueries({
					queryKey: ["networks"],
				});
				updateNetworkData({
					namedNetworkName,
					namedNetworkDescription,
					domain,
				});
				notify(NOTIFY_ACTIONS.SHOW_BACKGROUND_PROCESS_TOAST, {
					label: "UpdateNamedNetworkRequestSubmittedSuccessfully",
				});
				setTimeout(() => {
					cancel();
					setLoading(false);
				}, 300);
			},
			onError: error => {
				setLoading(false);
				let errorMessage = parseErrorMessage(error);
				// @ts-ignore: Axios error type
				if (error?.response?.status === 409) {
					errorMessage = "nnNameAlreadyExists";
				}
				setSnackbar(true, SnackBarSeverity.Error, errorMessage);
			},
		});
	};

	const handleIPRangesChange = (e: TextFieldUpdate, index: number) => {
		if (e?.value !== undefined) {
			const value = e?.value.trim() || "";
			let newIPRanges = [...ipRangesList];
			newIPRanges[index]["value"] = value;
			setIPRangesList(newIPRanges);
		}
	};

	const addNewIPRange = () => {
		const newIPRanges = [...ipRangesList];
		newIPRanges.push({ value: "" });
		setIPRangesList(newIPRanges);
	};

	const removeIPRange = (index: number) => {
		const newIPRanges = [...ipRangesList];
		newIPRanges.splice(index, 1);
		setIPRangesList(newIPRanges);
	};

	// const keyListener = (event: any) => {
	//   if (event?.key === "Enter" || event?.keyCode === 13) {
	//     updateNetworkDetails();
	//   }
	// };

	const handleUpdate = ({ field, value }: TextFieldUpdate) => {
		switch (field) {
			case NetworkFieldKeys.Name:
				if (value !== namedNetworkName) {
					setNetworkName(value);
				}
				break;
			case NetworkFieldKeys.Description:
				if (value !== namedNetworkDescription) {
					setNetworkDescription(value);
				}
				break;
			case NetworkFieldKeys.Domain:
				if (value !== domain) {
					setDomain(value);
				}
				break;
		}
	};

	// const [comment, setComment] = useState<string | undefined>(undefined);

	return (
		<>
			<DialogContent>
				<Stack
					alignItems={"flex-start"}
					sx={{ position: "relative", height: "100%" }}
				>
					<Typography variant="h6" my={2}>
						{window.getCTTranslatedText(title ?? "")}
					</Typography>

					<Stack
						sx={{
							minWidth: "100%",
							marginTop: "16px",
							flex: 1,
							overflow: "scroll",
						}}
					>
						<Stack
							alignItems="flex-start"
							justifyContent="flex-start"
							spacing={4}
						>
							<FormControl sx={{ m: 0, minWidth: "100%" }}>
								<Stack direction={"column"} spacing={4}>
									{mode !== "add" && (
										<Stack spacing={4}>
											{NetworkFieldOptions.map(
												(key: NetworkFieldKeys, index: number) => {
													let selectedValue: string | undefined;
													if (key === NetworkFieldKeys.Name) {
														selectedValue = namedNetworkName;
													} else if (key === NetworkFieldKeys.Description) {
														selectedValue = namedNetworkDescription;
													} else {
														selectedValue = domain;
													}
													return (
														<Stack key={`${key}`}>
															<CTInputField
																field={key}
																displayName={NetworkFieldDisplayText[key]}
																value={selectedValue}
																handleUpdate={handleUpdate}
																required={RequiredNetworkFields[key]}
																autoFocus={index === 0}
															/>
														</Stack>
													);
												}
											)}
										</Stack>
									)}

									{mode !== "edit" && (
										<Box sx={{ minWidth: "100%", pb: 8 }}>
											<Stack
												alignItems="flex-start"
												justifyContent="flex-start"
												spacing={0}
												sx={{
													...rulesSectionWrapper,
													background: theme =>
														theme.palette.mode === "dark"
															? theme.palette.background.paper
															: theme.palette.custom.lightGreyBg,
												}}
											>
												<Stack
													direction="row"
													alignItems="center"
													justifyContent={"space-between"}
													sx={{ width: "100%", my: 2 }}
												>
													<Stack alignItems="center">
														<Typography variant="subtitle1">
															{window.getCTTranslatedText("IP Ranges")}
														</Typography>
													</Stack>
													<Stack alignItems="center">
														<Button
															variant="text"
															startIcon={<AddCircleOutlineIcon />}
															color="primary"
															onClick={() => addNewIPRange()}
														>
															{window.getCTTranslatedText(
																mode === "add" ? "Add" : "Assign"
															)}{" "}
															{window.getCTTranslatedText("IP Ranges")}
														</Button>
													</Stack>
												</Stack>
												<Stack sx={{ width: "100%" }}>
													<Stack>
														{ipRangesList &&
															ipRangesList.length > 0 &&
															ipRangesList.map((item, index, values) => {
																return (
																	<AddIPInput
																		item={item}
																		index={index}
																		handleIPRangesChange={handleIPRangesChange}
																		removeIPRange={removeIPRange}
																		isDuplicate={isDuplicate}
																	/>
																);
															})}
													</Stack>
												</Stack>
											</Stack>
										</Box>
									)}

									{mode === "edit" && !network?.programAsInternet && (
										<>
											<FormGroup row sx={{ pl: 1 }}>
												<FormControlLabel
													control={
														<Switch
															checked={isProgrammedAsIntranetChecked}
															onChange={handlerOnSwitchChange}
															inputProps={{ "aria-label": "controlled" }}
														/>
													}
													label={window.getCTTranslatedText(
														"Program as Intranet"
													)}
												/>
											</FormGroup>
											{isProgrammedAsIntranetChecked && (
												<Alert severity="warning">
													{window.getCTTranslatedText(
														"This will change your definition of Intranet and Internet"
													)}
												</Alert>
											)}
										</>
									)}
								</Stack>
							</FormControl>
						</Stack>
					</Stack>
					{/* <Annotation setComment={setComment} comment={comment} /> */}
				</Stack>
			</DialogContent>
			<DialogActions sx={{ width: "100%", p: 0, m: 0 }}>
				<ToolbarAction
					loading={
						createNetworksAPI.isLoading ||
						editNetworksAPI.isLoading ||
						addNetworksAPI.isLoading ||
						loading
					}
					isValid={isValidNetwork()}
					actionBtnText={btnTitle ? btnTitle : "create"}
					save={updateNetworkDetails}
					cancel={cancel}
				/>
			</DialogActions>
		</>
	);
}
