import { Grid, Stack } from "@mui/material";
import { TextFieldUpdate } from "common/atoms/ct-input-field/CTInputField";
import { CTIPInput } from "common/atoms/ct-ip-input";
import { IP_FIELD_TYPES } from "common/atoms/ct-ip-input/CTIPInput";
import { CTSelectDropDown } from "pages/templates/components/template-form-drawer/components/ct-select";
import { HAModes, IPRangeInt, MTUOptions } from "../types";
import { IPWithMask_PLACEHOLDER, IP_PLACEHOLDER } from "../utils";
import { DNSServersInput } from "./components";

export interface WANProps {
	HAMode: string;
	wanIP: string;
	setWanIP: (value: string) => void;
	gatewayAddress: string;
	setGatewayAddress: (value: string) => void;
	wanVirtualIP: string;
	setWanVirtualIP: (value: string) => void;
	DNSServers: IPRangeInt[];
	setDNSServers: (ipRanges: IPRangeInt[]) => void;
	wanMTU: string;
	setWanMTU: (value: string) => void;
	keyListener: (event: any) => void;
	isFormDisabled: boolean;
}

export function WAN({
	HAMode,
	wanIP,
	setWanIP,
	gatewayAddress,
	setGatewayAddress,
	wanVirtualIP,
	setWanVirtualIP,
	DNSServers,
	setDNSServers,
	wanMTU,
	setWanMTU,
	keyListener,
	isFormDisabled,
}: WANProps) {
	return (
		<Stack>
			<Stack spacing={2}>
				<Grid
					container
					columnGap={4}
					rowGap={2}
					sx={{ pl: 0 }}
					display="grid"
					gridTemplateColumns="1fr 1fr"
				>
					<Stack>
						<CTIPInput
							field="wanIP"
							displayName={window.getCTTranslatedText("IP")}
							fieldType={IP_FIELD_TYPES.SUBNET}
							placeholder={IPWithMask_PLACEHOLDER}
							value={wanIP}
							handleUpdate={(event: TextFieldUpdate) => setWanIP(event?.value)}
							onKeyUp={keyListener}
							disabled={isFormDisabled}
						/>
					</Stack>
					<Stack>
						<CTIPInput
							field="gatewayAddress"
							displayName={window.getCTTranslatedText("gatewayAddress")}
							placeholder={IP_PLACEHOLDER}
							value={gatewayAddress}
							handleUpdate={(event: TextFieldUpdate) =>
								setGatewayAddress(event?.value)
							}
							onKeyUp={keyListener}
							disabled={isFormDisabled}
						/>
					</Stack>
					<Stack>
						<CTSelectDropDown
							field={"wanMTU"}
							displayName={window.getCTTranslatedText("MTU")}
							selectedValue={wanMTU}
							handleUpdate={(event: TextFieldUpdate) => setWanMTU(event.value)}
							data={MTUOptions}
							showLabel={true}
						/>
					</Stack>
					{HAMode === HAModes.Primary || HAMode === HAModes.Standby ? (
						<Stack>
							<CTIPInput
								field="virtualIP"
								displayName={window.getCTTranslatedText("virtualIP")}
								placeholder={IP_PLACEHOLDER}
								value={wanVirtualIP}
								handleUpdate={(event: TextFieldUpdate) =>
									setWanVirtualIP(event?.value)
								}
								onKeyUp={keyListener}
								disabled={isFormDisabled}
							/>
						</Stack>
					) : null}
					<Stack>
						<DNSServersInput
							IPList={DNSServers}
							listSetter={setDNSServers}
							isFormDisabled={isFormDisabled}
						/>
					</Stack>
				</Grid>
			</Stack>
		</Stack>
	);
}
