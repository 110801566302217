import { AssetStatus } from "pages/assets/types";
import {
	ProgressiveEnforcementStatus,
	ProgressiveOutboundPortEnforcementStatus,
} from "pages/ports/types";
import {
	PolicyChangeType,
	Traffic,
	UnreviewedTraffic,
} from "pages/tags/components/tag-policy-list/components/policy-automation-drawer/types";

export enum ChangeType {
	ENFORCEMENT = "enforcement",
	PROGRESSIVE = "progressive",
}

export interface Guardrail {
	id: PolicyChangeType;
	baseCriteria: Traffic;
}

export interface Warning {
	title?: string;
	description?: string;
	potentialImpacts?: string[];
	recommendations?: string[];
}

export interface InputStatistics {
	channelhashcount?: number;
	lpidcount?: number;
}

export interface DetailedInputData {
	[key: string]: {
		listenportreviewed?: {
			unreviewed?: { statistics: InputStatistics };
			denied?: { statistics: InputStatistics };
			"denied-by-template"?: { statistics: InputStatistics };
		};
		reviewed?: {
			unreviewed?: { statistics: InputStatistics };
			denied?: { statistics: InputStatistics };
			"denied-by-template"?: { statistics: InputStatistics };
		};
		enforced?: {
			"allowed-test-denied-violation"?: { statistics: InputStatistics };
		};
		[key: string]: any;
	};
}

export interface SimpleInputData {
	[key: string]: {
		statistics: InputStatistics;
		[key: string]: any;
	};
}

export interface OutputData {
	[key: string]: UnreviewedTraffic;
}

export interface DetailedTrafficStats {
	assets: number;
	paths: {
		unreviewed: number;
		denied: number;
		deniedByTemplate: number;
		violations: number;
	};
	ports: {
		unreviewed: number;
		denied: number;
		deniedByTemplate: number;
	};
	overlaps: number;
}

export interface SimplifiedTrafficStats {
	assets: number;
	progressivePaths: number;
	progressivePorts: number;
	overlaps: number;
}

export interface GuardrailSection {
	title: string;
	subSections: {
		[key: string]: string;
	};
}

export const GuardrailSections: { [key: string]: GuardrailSection } = {
	AttackSurface: {
		title: "Attack Surface",
		subSections: {
			[PolicyChangeType.AttackSurfaceProgressive]: "Progressive",
			[PolicyChangeType.AttackSurfaceEnforcement]: "Enforcement",
		},
	},
	BlastRadius: {
		title: "Blast Radius",
		subSections: {
			[PolicyChangeType.BlastRadiusProgressive]: "Progressive",
			[PolicyChangeType.BlastRadiusEnforcement]: "Enforcement",
		},
	},
};

export interface SelectedValues {
	[PolicyChangeType.AttackSurfaceProgressive]?: ProgressiveEnforcementStatus;
	[PolicyChangeType.AttackSurfaceEnforcement]?: AssetStatus;
	[PolicyChangeType.BlastRadiusProgressive]?: ProgressiveOutboundPortEnforcementStatus;
	[PolicyChangeType.BlastRadiusEnforcement]?: AssetStatus;
}
